import { useMemo } from 'react';
import useGetVHsWithObjectives from './useGetVHsWithObjectives';

const useGetVHHasObjectives = (vhId) => {
  const { vhIdsWithObjectives, loading, error } = useGetVHsWithObjectives([
    vhId,
  ]);
  const hasObjectives = useMemo(() => {
    return vhIdsWithObjectives && vhIdsWithObjectives.includes(vhId);
  }, [vhIdsWithObjectives, vhId]);
  return { hasObjectives, loading, error };
};

export default useGetVHHasObjectives;
