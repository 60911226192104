import { useMutation } from '@apollo/client';
import { Icon } from '@virtidev/toolbox';
import React, { useCallback, useMemo } from 'react';
import useFlashMessage from '../../../FlashMessage';
import LoadingPlaceholder, {
  MockIcon,
  MockText,
} from '../../../LoadingPlaceholder';
import { DELETE_RESOURCES } from './ResourceItem.query';
import {
  DeleteButton,
  MockResource,
  MockResourceContainer,
  ResourceTag,
  ResourceTitle,
  StyledDownloadTag,
  StyledResource,
} from './ResourceItem.styled';
import { READ_COURSE_RESOURCES } from './UploadResource.query';
import getConfig from '../../../../../../../lib/config';


export default function ResourceItem(props) {
  const { addFlashMessage } = useFlashMessage();

  const [deleteResources, { loading }] = useMutation(DELETE_RESOURCES, {
    onCompleted: () => {
      addFlashMessage('Deleted resource!');
    },
    onError: (err) => {
      addFlashMessage(
        'Something went wrong, could not delete resource.',
        'error'
      );
      console.error(err);
    },
  });

  const handleClick = useCallback(() => {
    const refetchQueries = [
      {
        query: READ_COURSE_RESOURCES,
        variables: {
          ID: props.courseId,
        },
      },
    ];

    deleteResources({
      variables: {
        ids: [props.resource.ID],
      },
      refetchQueries,
    });
  }, [props.resource.ID, deleteResources, props.courseId]);

  const downloadUrl = useMemo(() => {
    return `${getConfig('REACT_APP_REMOTE_URI')}resources/download?url=${
      props?.resource?.Media?.URL
    }&filename=${props?.resource?.Title}`;
  }, [props.resource.Media.URL, props.resource.Title]);

  const downloadProps = useMemo(
    () => ({
      href: downloadUrl,
      alt: 'download-resource',
      title: props?.resource?.Title,
      download: props?.resource?.Title,
    }),
    [props?.resource, downloadUrl]
  );

  return loading ? (
    <MockResourceContainer>
      <LoadingPlaceholder>
        <MockResource>
          <MockText />
          <MockIcon />
        </MockResource>
      </LoadingPlaceholder>
    </MockResourceContainer>
  ) : (
    <StyledResource>
      <ResourceTag {...downloadProps}>
        <ResourceTitle>{props?.resource?.Title || 'No Title'}</ResourceTitle>
      </ResourceTag>

      {props.courseId && (
        <DeleteButton color="transparent-contrast" onClick={handleClick}>
          <Icon icon="bin" size="15px" />
        </DeleteButton>
      )}
      <StyledDownloadTag {...downloadProps}>
        <Icon icon="download" size="15px" />
      </StyledDownloadTag>
    </StyledResource>
  );
}
