import styled, { css } from 'styled-components';
import { TextInput, Image } from '@virtidev/toolbox';

//NAME AND TYPES STEP
export const NameEntryWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 1rem;
  @media screen and (max-height: 820px) {
    gap: 0;
  }
`;

export const TextInputField = styled(TextInput)`
  border-radius: 25px;
  border-color: #757575;
  input {
    border-color: #757575;
    border-radius: 25px;
  }
`;

export const NameFieldWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;

  > * {
    flex-grow: 1;
    flex-shrink: 0;
  }
`;

export const FieldWrapper = styled.div`
  display: flex;
  gap: 0.5rem;
`;

export const TypeIconsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  width: 100%;
  align-items: flex-start;
  @media screen and (min-width: 768px) {
    flex-direction: row;
  }
  @media screen and (min-width: 1200px) {
    gap: 4rem;
  }
  @media screen and (max-height: 820px) {
    gap: 1rem;
  }
`;

export const TypeIconButton = styled.button`
  background-color: transparent;
  position: relative;
  border: none;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  padding: 1rem;
  border: 1px solid white;
  border-radius: 16px;
  > div {
    height: initial;
  }
  @media screen and (max-height: 820px) {
    > div {
      width: 2rem;
      height: 2rem;
    }
    span {
      font-size: 4rem;
    }
  }
  &:focus-visible {
    border: 1px solid var(--secondary-color, #46beaf);
  }
`;

export const TypeIconButtonLabel = styled.div`
  font-size: 1.4rem;
  font-weight: 500;
  text-align: center;
`;

//TEMPLATES SELECTION STEP
export const TemplateList = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 1rem;
  width: 100%;
  max-height: 400px;
  overflow-y: auto;
`;

export const TemplateListItemTitle = styled.p`
  font-weight: 500;
  font-size: 1rem;
  text-align: left;
  grid-column: 2;
`;

export const TemplateListItemDescriptionWrapper = styled.div`
  padding: 1rem;
  border: 1px solid #757575;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  border-radius: 16px;
  text-align: left;
  height: 100%;
  max-width: 500px;
`;

export const TemplateListItemDescription = styled.p`
  color: #757575;
  font-size: 1rem;
  text-align: left;
  max-width: 100%;
`;

export const TemplateListItem = styled.button`
  ${({ theme, selected }) => css`
    border: 1px solid #757575;
    width: 100%;
    display: grid;
    grid-template-columns: 200px 1fr;
    grid-template-rows: auto 1fr;
    border-radius: 25px;
    padding: 1rem;
    gap: 1rem;
    &:hover,
    &:focus,
    &:focus-visible {
      border: 1px solid ${theme.color.secondary};
    }
    background-color: #fff;
    cursor: pointer;
    ${selected &&
    css`
      border: 1px solid ${theme.color.secondary};
      ${TemplateListItemTitle} {
        color: var(--secondary-color, #46beaf);
      }
    `}
  `}
`;

export const TemplateListItemThumbnailWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 200px;
  height: 200px;
  background-color: #757575;
  border-radius: 16px;
  grid-column: 1;
  grid-row: 1 / span 2;
  overflow: hidden;
`;

export const TemplateListItemThumbnail = styled(Image)`
  object-fit: cover;
  width: 100%;
  height: 100%;
  ${(props) =>
    props.src &&
    css`
      object-position: -68px;
    `}
`;

export const TableHeader = styled.th`
  && {
    color: #46beaf;
  }
`;

export const TableRowData = styled.td`
  text-transform: capitalize;
`;

export const DropdownWrapper = styled.div`
  width: 100%;
`;

export const VoiceSelectorWrapper = styled.div`
  overflow-y: auto;
  max-height: 26svh;
`;

export const BetaIconWrapper = styled.div`
  position: absolute;
  bottom: -2.2rem;
  left: 0;
  right: 0;
`;