import { useMutation } from '@apollo/client';
import { Icon, TextInput } from '@virtidev/toolbox';
import { produce } from 'immer';
import React, { useCallback, useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useAutosave } from '../../../../helpers/useAutosave';
import {
  DELETE_FEEDBACK_OPTIONS,
  UPDATE_FEEDBACK_OPTION,
} from './Option.query';
import { OptionWrapper } from './Option.styled';

export default function Option({ option, setLoading, handleNewData }) {
  const getOptionValues = useCallback((values) => {
    return produce(values, (state) => {
      delete state.__typename;
    });
  }, []);
  const formMethods = useForm({
    defaultValues: {
      ...getOptionValues(option),
    },
  });

  const { register } = formMethods;

  const [updateFeedbackOption] = useMutation(UPDATE_FEEDBACK_OPTION);

  const handleSubmit = useMemo(() => {
    return async (input) => {
      updateFeedbackOption({
        variables: {
          input,
        },
      });
    };
  }, [updateFeedbackOption]);

  const [deleteFeedbackOptions] = useMutation(DELETE_FEEDBACK_OPTIONS);

  const handleDeleteOption = () => {
    setLoading(true);
    deleteFeedbackOptions({
      variables: {
        ids: [option.ID],
      },
      optimisticResponse: {
        // this won't work until the update does
        deleteFeedbackOptions: [],
      },
      // this needs to change to a refetch when the question connection works
      onCompleted: () => {
        setLoading(false);
        handleNewData();
      },
      onError: () => {
        setLoading(false);
      },
    });
  };

  useAutosave(formMethods, handleSubmit, setLoading);

  return (
    <FormProvider {...formMethods}>
      <OptionWrapper>
        <TextInput
          placeholder="Option text..."
          {...register('Name')}
          draggable={true}
          onDragStart={(event) => event.preventDefault()}
        />
        <Icon
          icon="bin"
          color="#ccc"
          onClick={handleDeleteOption}
          style={{ cursor: 'pointer' }}
        />
      </OptionWrapper>
    </FormProvider>
  );
}
