import { Icon } from '@virtidev/toolbox';
import styled, { css } from 'styled-components';

export const VideoTypeIcon = styled(Icon).attrs({
  size: '24px',
})`
  position: absolute;
  top: calc(var(--card-border-radius) / 2);
  left: calc(var(--card-border-radius) / 2);
`;

export const ImageContainer = styled.div`
  position: relative;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 131px;
  max-height: 131px;
  border-radius: var(--card-border-radius) var(--card-border-radius) 0 0;
`;

export const VideoCardContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  border: 1px solid #ccc;
  border-radius: var(--card-border-radius);
  cursor: pointer;

  &:hover {
    border-color: var(--secondary-color, #46beaf);

    ${ImageContainer} {
      opacity: var(--image-link-opacity-hover);
    }
  }

  ${({ disabled }) =>
    disabled &&
    css`
      pointer-events: none;
      filter: grayscale(1);
    `}

  ${({ $selected }) =>
    $selected
      ? css`
          border-color: var(--primary-color, #528fbf);
          box-shadow: 0 0 0 2px var(--primary-color, #528fbf);

          ${VideoTypeIcon} {
            color: var(--primary-color, #528fbf);
            text-shadow: 0 0 4px #fff;
          }
        `
      : css`
          ${VideoTypeIcon} {
            color: #fff;
            text-shadow: 0 0 4px #888;
          }
        `}
`;

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  padding: 8px;
`;

export const TitleLabel = styled.div`
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  height: 3rem;
  width: 100%;
`;

export const TimeLabel = styled.div`
  color: #999;
`;
