import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import * as StyledModal from '../VHWizardModalStyles.styled';
import {
  TemplateList,
  TemplateListItem,
  TemplateListItemTitle,
  TemplateListItemThumbnailWrapper,
  TemplateListItemDescriptionWrapper,
  TemplateListItemDescription,
  TemplateListItemThumbnail,
} from './VHCreationStepsStyles.styled';
import {
  VHTemplateEdgePropType,
  VHTemplateNodePropType,
} from '../VHCreationWizard.propTypes';

const VHTemplateStep = (props) => {
  const typeTemplateEdges = props.templateEdges.filter(
    (edge) => edge.node.VirtualHuman.Type === props.creationDetails.Type
  );
  const [selectedTemplate, setSelectedTemplate] = useState(
    props.creationDetails.Template ?? null
  );

  const handleSelectTemplate = (template) => {
    if (template === null || template !== selectedTemplate) {
      setSelectedTemplate(template);
    }
  };

  const saveDetails = () => {
    props.confirmAction({ Template: selectedTemplate });
  };

  return (
    <StyledModal.ContentWrapper>
      <StyledModal.TitleWrapper>
        <StyledModal.Title>Create a Virtual Human</StyledModal.Title>
      </StyledModal.TitleWrapper>
      <StyledModal.ContentSectionWrapper>
        <StyledModal.TextPassage>
          Choose a template to get up-and-running quickly
        </StyledModal.TextPassage>
        <TemplateList>
          {typeTemplateEdges.map(({ node: templateNode }) => {
            return (
              <TemplateListItem
                key={templateNode.VirtualHuman.ID}
                onClick={() => handleSelectTemplate(templateNode)}
                selected={selectedTemplate?.ID === templateNode.VirtualHuman.ID}
              >
                <TemplateListItemTitle>
                  {templateNode.VirtualHuman.Title}
                </TemplateListItemTitle>
                <TemplateListItemThumbnailWrapper>
                  <TemplateListItemThumbnail
                    src={templateNode.VirtualHuman.ThumbnailURL}
                    cover
                  />
                </TemplateListItemThumbnailWrapper>
                <TemplateListItemDescriptionWrapper>
                  <TemplateListItemDescription>
                    {templateNode.VirtualHuman.Description}
                  </TemplateListItemDescription>
                </TemplateListItemDescriptionWrapper>
              </TemplateListItem>
            );
          })}
        </TemplateList>
      </StyledModal.ContentSectionWrapper>
      <StyledModal.ActionButtonsWrapper>
        <StyledModal.StyledLinkButton
          type="button"
          color="secondary"
          onClick={props.cancelAction}
        >
          Back
        </StyledModal.StyledLinkButton>
        <StyledModal.StyledButton
          type="button"
          color="primary"
          onClick={saveDetails}
          disabled={selectedTemplate === null}
        >
          Next
        </StyledModal.StyledButton>
      </StyledModal.ActionButtonsWrapper>
    </StyledModal.ContentWrapper>
  );
};

export default VHTemplateStep;

VHTemplateStep.propTypes = {
  confirmAction: PropTypes.func,
  cancelAction: PropTypes.func,
  selectedVHTemplate: VHTemplateNodePropType,
  templateEdges: PropTypes.arrayOf(VHTemplateEdgePropType),
};
