import { useMemo } from 'react';
import useGetVHData from '../useGetVHData';

const useGetVHsWithObjectives = (vhIds) => {
  const { data, loading, error } = useGetVHData(
    `virtual-humans?idOnly=true&hasObjectives=true&ids=${vhIds.join(',')}`
  );

  const vhIdsWithObjectives = useMemo(() => {
    if (typeof data?.data?.map !== 'function') return [];
    return data?.data?.map((vh) => vh.id) ?? [];
  }, [data]);

  return { vhIdsWithObjectives, loading, error };
};

export default useGetVHsWithObjectives;
