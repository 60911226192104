import './App.css';
import { BrowserRouter, Route, Switch, Redirect, useLocation, matchPath } from "react-router-dom";
import { HTML5Backend } from 'react-dnd-html5-backend';
import { DndProvider } from 'react-dnd';
import { ComponentType, lazy, Suspense, useEffect, useLayoutEffect, useMemo } from 'react';

import DashboardPage from './pages/DashboardPage';
import LoginPage from './pages/LoginPage';
import ChangePasswordPage from './pages/ChangePasswordPage';
import ForgotPasswordPage from './pages/ForgotPasswordPage';
import ForgotCodePage from './pages/ForgotCodePage';
import LogoutPage from './pages/LogoutPage';
import SetOrganisationPage from './pages/SetOrganisationPage';
import SimulationPage from './pages/SimulationPage';
import MediaPage from './pages/MediaPage';
import UserVirtualHumanAnalyticsPage from './pages/UserVirtualHumanAnalyticsPage';
import EditCoursePage from './apps/core/src/pages/admin/course/EditCoursePage';
import CourseListPage from './apps/core/src/pages/admin/course/CourseListPage';

import NewSimulationPage from './pages/SimulationNewPage';
import UserPage from './pages/UserPage';
import UsersPage from './pages/UsersPage';
import NewUserPage from './pages/UserNewPage';
import GroupsPage from './pages/GroupsPage';
import GroupPage from './pages/GroupPage';
import OrganisationPage from './pages/OrganisationPage';
import UserProfilePage from './pages/UserProfilePage';
import UserAnalyticsPage from './pages/UserAnalyticsPage';
import VirtualHumanPage from './pages/VirtualHumanPage';
import FeedbackFormsPage from './apps/core/src/pages/admin/Feedback/FeedbackFormsPage';
import LegacyCXAnalyticsPage from './pages/CXAnalyticsPage';
import CXOrganisationAnalyticsPage from './apps/core/src/pages/admin/cx/CXOrganisationAnalyticsPage/CXOrganisationAnalyticsPage';
import GroupSimulationsPage from './pages/GroupSimulationsPage';
import GroupAnalyticsPage from './pages/GroupAnalyticsPage';
import AnalyticsService from './apps/core/src/components/AnalyticsService/AnalyticsService';
import AccountActivationPage from './pages/AccountActivationPage';
import CXSimulationCopyPage from './pages/CXSimulationCopyPage';
import MySimulationsPage from './pages/MySimulationsPage';
import MyCoursesPage from './pages/MyCoursesPage';
import MyCoursePage from './pages/MyCoursePage';
import MyCourseUnitSimulationPage from './pages/MyCourseUnitSimulationPage';
import MyHomePage from './pages/MyHomePage';
import MySimulationPage from './pages/MySimulationPage';
import MyVirtualHumansPage from './pages/MyVirtualHumansPage';
import VirtualHumanConversationTextPage from './pages/VirtualHumanConversationTextPage';
import PDWebPlayerPage from './pages/PDWebPlayerPage';

import GroupCoursesPage from './pages/GroupCoursesPage';
import NotFoundPage from './pages/NotFoundPage';
import OnboardingDashboardPage from './pages/OnboardingDashboardPage';
import useUser, { UserProvider } from './apps/core/src/helpers/useUser';
import _ from 'lodash';
import CXUserCopyPage from './pages/CXUserCopyPage';
import { EasterEgg } from './apps/core/src/components/EasterEgg/EasterEgg';
import CXProductDashboardPage from './pages/CXProductDashboardPage';
import SimulationsListPage from './apps/core/src/pages/admin/simulations/SimulationsListPage';
import VirtualHumansListPage from './apps/core/src/pages/admin/virtual-humans/VirtualHumansListPage';
import NewMemberSignupPage from './apps/core/src/pages/signup/NewMemberSignUpPage/NewMemberSignupPage';
import VirtiApolloProvider from './apps/core/src/components/VirtiApolloProvider/VirtiApolloProvider';
import MyCourseUnitVirtualHumanPage from './pages/MyCourseUnitVirtualHumanPage';
import VRLoginPage from './apps/core/src/pages/user/account/VRLoginPage';
import TotaraRegisterPage from './apps/core/src/pages/signup/totara/TotaraRegister';
import VirtualHumanPreviewPage from './pages/VirtualHumanPreviewPage';
import CXVHEditPage from './pages/CXVHEditPage';
import { DeepLinkProvider } from './apps/core/src/components/DeepLink';
import CXCourseCopyPage from './apps/core/src/pages/admin/cx/CXCourseCopyPage/CXCourseCopyPage';
import { LaunchDarklyProvider } from './apps/core/src/components/LaunchDarkly/LaunchDarkly';
import useFeature from './apps/core/src/components/LaunchDarkly/hooks/useFeature';
import GroupVirtualHumansPage from './apps/core/src/pages/admin/groups/GroupVirtualHumansPage/GroupVirtualHumansPage';
import VirtualHumanUserAnalyticsPage from './apps/core/src/pages/admin/virtual-humans/VirtualHumanUserAnalyticsPage/VirtualHumanUserAnalyticsPage';
import useSentryUserData from './apps/core/src/components/Sentry/useSentryUserData';
import getConfig from './lib/config';
import VideoSubtitlesPage from './apps/core/src/pages/admin/videos/VideoSubtitlesPage/VideoSubtitlesPage';
import ScreenCapturePage from './apps/core/src/pages/ScreenCapture/ScreenCapturePage';
import FeedbackFormPage from './apps/core/src/pages/admin/Feedback/FeedbackFormPage';
import CXUsersPage from './apps/core/src/pages/admin/cx/CXUsersPage/CXUsersPage';
import useDateLocale from './apps/core/src/components/Locale/useDateLocale';
import MySimulationSessionDetailPage from './pages/MySimulationSessionDetailPage';
import MySimulationSessionsPage from './pages/MySimulationSessionsPage';
import MyVirtualHumanSessionsPage from './pages/MyVirtualHumanSessionsPage';
import MyBadgesPage from './pages/MyBadgesPage';
import CXCopyFeedbackFormPage from './apps/core/src/pages/admin/cx/CXCopyFeedbackFormPage/CXCopyFeedbackFormPage';
import withEs6Support from './apps/core/src/helpers/withEs6Support';
import { OfflineUpdate } from './apps/core/src/components/OfflineUpdate/OfflineUpdate';
import RegisterPage from './apps/core/src/pages/signup/register/RegisterPage';
import { publicRoutes } from '@core/helpers/publicRoutes';
import VirtiTheme from './apps/core/src/templates/VirtiTheme';
import withUserPilot from '@core/components/UserPilot/UserPilotProvider';
import VHWebPlayerPagePublicRedirect from '@core/pages/admin/virtual-humans/VHWebPlayerPagePublic/VHWebPlayerPagePublicRedirect';

import "@virtidev/toolbox/build/styles.css";

const EmbedSection = lazy(() => import( './apps/core/src/pages/admin/embed/Embed.section'));

const HelpPage = lazy(() => import( './apps/core/src/pages/admin/help/HelpPage'));

const AnalyticsDataSection = lazy(() => import('./apps/core/src/pages/admin/Analytics/AnalyticsData.section'));

const VirtualHumanApp = lazy(() => import('./pages/VirtualHumanApp'));

const SimulationOverviewPage = lazy(() => import('./pages/SimulationOverviewPage'));

const SimulationInteractionsEditorPage = lazy(() => import('./apps/core/src/pages/admin/simulations/SimulationInteractionsEditorPage'));

const App = () => {
  const { ID } = useUser();
  const { pathname } = useLocation();

  const [
    screenCaptureFeature,
  ] = useFeature([
    'screen-capture-tool',
  ]);

  useSentryUserData();

  useDateLocale();

  const publicPage = useMemo(
    () => publicRoutes.find((route) => matchPath(pathname, route)),
    [pathname]
  );

  useEffect(() => {
    window.graphQLVersion = 4; // so vh client knows correct formats
  }, []);

  useLayoutEffect(() => {
    const env = getConfig('REACT_APP_ENV');
    if (env !== 'production') {
      document.title = `Virti Admin - ${window.location.host}`;
    }
  }, []);

  return (
    <Switch>
      <Route exact path="/" component={DashboardPage} />
      <Route path="/login" component={LoginPage} />
      <Route path="/signup-link/:userType/:orgHash" component={NewMemberSignupPage}/>
      <Route path="/register/maudsley" component={TotaraRegisterPage} />
      <Route path="/register" component={RegisterPage} />
      <Route path="/change-password" component={ChangePasswordPage} />
      <Route path="/forgot-password" component={ForgotPasswordPage} />
      <Route path="/forgot-code" component={ForgotCodePage} />
      <Route path="/activate-account" component={AccountActivationPage} />
      <Route path="/logout" component={LogoutPage} />
      <Route path="/set-organisation/:id" component={SetOrganisationPage} />
      <Route path="/organisation" component={OrganisationPage} />
      <Route path="/profile" component={UserProfilePage} />
      <Route path="/help" component={HelpPage} />
      <Route path="/home" component={MyHomePage} />

      <Route path="/embed" component={EmbedSection} />
      <Route path="/analytics-data" component={AnalyticsDataSection} />

      <Route path="/simulations/new" component={NewSimulationPage} />
      <Redirect path="/simulations/:id/videos/:videoID/videos" to="/simulations/:id/videos/:videoID/interactions" />
      <Route path="/simulations/:simulationId/videos/:videoID/interactions" component={SimulationInteractionsEditorPage} />
      <Route path="/simulations/:id/videos/:videoID/overview-pages" component={SimulationOverviewPage} />
      <Redirect path="/simulations/:id/videos/:videoID/analytics/logs/:logID" to="/analytics-data/simulations/:id??tab=logs&log=:logID" />
      <Redirect path="/simulations/:id/videos/:videoID/analytics" to="/analytics-data/simulations/:id" />
      <Redirect path="/simulations/:id/videos/:videoID/sessions" to="/analytics-data/simulations/:id??tab=logs" />
      <Redirect path="/simulations/:id/videos/:videoID/users" to="/analytics-data/simulations/:id??tab=users" />
      <Route path="/simulations/:id/videos/:videoID" component={SimulationPage} />
      <Route path="/simulations/:id" component={SimulationPage} />
      <Route path="/simulations" component={SimulationsListPage} />

      {screenCaptureFeature && (
        <Route path="/media/screen-capture" component={ScreenCapturePage} />
      )}
      <Route path="/media/videos/:videoID/subtitles/:subtitleID" component={VideoSubtitlesPage} />
      <Route path="/media" component={MediaPage} />

      <Route path="/users/new" component={NewUserPage} />
      <Route path="/users/:userID/analytics/virtual-humans/:ssVirtualHumanID" component={VirtualHumanUserAnalyticsPage} />
      <Route path="/users/:id/analytics/virtual-humans" component={UserVirtualHumanAnalyticsPage} />
      <Route path="/users/:id/analytics" component={UserAnalyticsPage} />
      <Route path="/users/:id" component={UserPage} />
      <Route path="/users" component={UsersPage} />

      <Route path="/groups/:id/virtual-humans" component={GroupVirtualHumansPage} />
      <Route path="/groups/:id/courses" component={GroupCoursesPage} />
      <Route path="/groups/:id/analytics" component={GroupAnalyticsPage} />
      <Route path="/groups/:id/simulations" component={GroupSimulationsPage} />
      <Route path="/groups/:id" component={GroupPage} />
      <Route path="/groups" component={GroupsPage} />

      <Redirect path="/courses/:id/analytics" to="/analytics-data/courses/:id" />
      <Redirect path="/courses/:id/users" to="/analytics-data/courses/:id??tab=users" />
      <Route path="/courses/new" component={CourseListPage} />
      <Route path="/courses/:courseId/" component={EditCoursePage} />
      <Route path="/courses" component={CourseListPage} />

      <Route path="/cx-dashboard/simulation-copy" component={CXSimulationCopyPage} />
      <Route path="/cx-dashboard/course-copy" component={CXCourseCopyPage}/>
      <Route path="/cx-dashboard/user-copy" component={CXUserCopyPage} />
      <Route path="/cx-dashboard/vh-copy" component={CXVHEditPage} />
      <Route path="/cx-dashboard/feedback-form-copy" component={CXCopyFeedbackFormPage} />
      <Route path="/cx-dashboard/product-dashboard" component={CXProductDashboardPage} />
      <Route path="/cx-dashboard/organisations" component={CXOrganisationAnalyticsPage} />
      <Route path="/cx-dashboard/users" component={CXUsersPage}/>
      <Route path="/cx-dashboard" component={LegacyCXAnalyticsPage} />

      <Route path="/virtual-humans/:patientId/conversation/export" component={VirtualHumanConversationTextPage} />
      <Route path="/virtual-humans/:ssVirtualHumanID/analytics/user/:userID" component={VirtualHumanUserAnalyticsPage} />
      <Route path="/virtual-humans/:ssVirtualHumanID/:patientId/conversation/export" component={VirtualHumanConversationTextPage} />
      <Redirect path="/virtual-humans/:ssVirtualHumanID/analytics" to="/analytics-data/virtual-human/:ssVirtualHumanID" />
      <Redirect path="/virtual-humans/:ssVirtualHumanID/completions" to="/analytics-data/virtual-human/:ssVirtualHumanID??tab=completions" />
      <Redirect path="/virtual-humans/:ssVirtualHumanID/sessions" to="/analytics-data/virtual-human/:ssVirtualHumanID??tab=sessions" />
      <Redirect path="/virtual-humans/:ssVirtualHumanID/objective-analytics" to="/analytics-data/virtual-human/:ssVirtualHumanID??tab=objectives" />
      <Route path="/virtual-humans/:ssVirtualHumanID/preview" component={VirtualHumanPreviewPage} />
      <Route path="/virtual-humans/:ssVirtualHumanID/builder" component={VirtualHumanApp} />
      <Route path="/virtual-humans/:ssVirtualHumanID" component={VirtualHumanPage} />
      <Route path="/virtual-humans" component={VirtualHumansListPage} />

      <Redirect path="/feedback-forms/:id/analytics" to="/analytics-data/forms/:id" />
      <Redirect path="/feedback-forms/:id/submissions/:submissionID" to="/analytics-data/forms/:id??tab=submissions&submission=:submissionID" />
      <Redirect path="/feedback-forms/:id/submissions" to="/analytics-data/forms/:id??tab=submissions" />
      <Route path="/feedback-forms/:id/" component={FeedbackFormPage} />
      <Route path="/feedback-forms" component={FeedbackFormsPage} />

      <Route path="/my-simulations/:simulationID/:sharetoken" component={MySimulationPage} />
      <Route path="/my-simulations/:simulationID" component={MySimulationPage} />
      <Route path="/my-simulations" component={MySimulationsPage} />

      <Route path="/my-courses/:courseID/simulations/:simID" component={MyCourseUnitSimulationPage} />
      <Route path="/my-courses/:courseID/virtual-humans/:virtualHumanID" component={MyCourseUnitVirtualHumanPage} />
      <Route path="/my-courses/:courseID" component={MyCoursePage} />
      <Route path="/my-courses" component={MyCoursesPage} />

      {/* remove this embed redirect later */}
      <Route path="/my-virtual-humans/embed/:ssVirtualHumanID" component={VHWebPlayerPagePublicRedirect} />
      <Route path="/my-virtual-humans/:ssVirtualHumanID/play/:sharetoken" component={PDWebPlayerPage} />
      <Route path="/my-virtual-humans/:ssVirtualHumanID/play" component={PDWebPlayerPage} />
      <Route path="/my-virtual-humans/:ssVirtualHumanID" component={NotFoundPage} />
      <Route path="/my-virtual-humans" component={MyVirtualHumansPage} />

      <Route path="/my-profile/simulations/:sessionID" component={MySimulationSessionDetailPage} />
      <Route path="/my-profile/simulations" component={MySimulationSessionsPage} />
      <Route path="/my-profile/virtual-humans" component={MyVirtualHumanSessionsPage} />
      <Route path="/my-profile/badges" component={MyBadgesPage} />

      <Route path="/onboarding-dashboard" component={OnboardingDashboardPage}/>
      <Route path="/privacy-policy" render={() => {
        window.location.href = 'https://www.virti.com/virti-privacy-policy-app-and-product-browser/';
        return null;
      }} />
      <Route path="/terms-conditions" render={() => {
        window.location.href = "https://www.virti.com/terms-conditions";
        return null;
      }} />
      <Route path="/account/vr" component={VRLoginPage} />
      <Route component={NotFoundPage}/>
    </Switch>
  );
};

/**
 *
 * @param {ComponentType} Component
 * @returns {ComponentType}
 */
const provideAppContext = (Component) => {
  const AppProviders = () => (
    <BrowserRouter>
      <UserProvider>
        <VirtiTheme>
          <VirtiApolloProvider>
            <DndProvider backend={HTML5Backend}>
              <DeepLinkProvider>
                <LaunchDarklyProvider>
                  <Suspense fallback="">
                    <AnalyticsService />
                    <Component />
                    <OfflineUpdate />
                  </Suspense>
                  <EasterEgg />
                </LaunchDarklyProvider>
              </DeepLinkProvider>
            </DndProvider>
          </VirtiApolloProvider>
        </VirtiTheme>
      </UserProvider>
    </BrowserRouter>
  );

  return AppProviders;
};

// export default App;
export default _.flowRight(
  withEs6Support,
  provideAppContext,
  withUserPilot,
)(App);
