import React, { useRef, useEffect, useCallback, FC } from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';
import withFlashMessaging from '../../HOCs/WithFlashMessaging';
import WithFormHandler from '../../HOCs/WithFormHandler';
import { EDIT_VIDEO_MEDIA } from '../../queries/SimulationQueries';
import Modal from '../Modal';
import StyledTextInput from '../../styled-components/StyledTextInput';
import { MinLength } from '../../utility/FormValidations';
import { useEffectOnlyOnce, usePrevious } from '../../utility/CustomHooks';
import { StyledModalSubmitBtn } from '../../styled-components/StyledModalFormComponents';
import ValidationMessages from '../ValidationMessages';
import useFlashMessage from '../../apps/core/src/components/FlashMessage';

/**
 * @typedef {import('../../apps/core/src/models/video-media.types').VideoMedia} VideoMedia
 */

const formConfig = {
  inputs: [
    {
      dbName: 'Title',
    },
  ],
  validations: [MinLength('Title', 'Title', true, 1)],
};

/**
 * @type {FC<{
 *    hideModal: () => void,
 *    modalVisible: boolean,
 *    video: VideoMedia,
 *    isDirtyForm: boolean,
 *    invalidFields: string[],
 *    resetPrevFormData: () => void,
 *    currentFormData: {
 *      Title: string,
 *    },
 *    setInitialData: (form: object) => void,
 *    setInputState: (field: string, value: string) => void,
 *    validationErrors: string[],
 * }>}
 */
const EditVideoTitleModalForm = (props) => {
  const titleRef = useRef(/** @type {HTMLInputElement | null} */ (null));
  const prevVisible = usePrevious(props.modalVisible);
  const formDisabled = !props.isDirtyForm || props.invalidFields.length > 0;
  const { addFlashMessage } = useFlashMessage();

  const [editVideo, { loading: mutating }] = useMutation(EDIT_VIDEO_MEDIA, {
    onError: (err) => {
      console.error(err);
      addFlashMessage('There was an error editing the video', 'error');
    },
    onCompleted: (data) => {
      addFlashMessage('Video title updated', 'success');
      props.resetPrevFormData();
      props.hideModal();
    },
  });

  const handleSubmit = useCallback(
    (e) => {
      if (formDisabled) {
        return;
      }
      e.preventDefault();
      e.stopPropagation();

      editVideo({
        variables: {
          Input: {
            ID: props.video.ID,
            Title: props.currentFormData.Title,
          },
        },
        optimisticResponse: {
          updateVideoMedia: {
            ID: props.video.ID,
            Title: props.currentFormData.Title,
            __typename: 'VideoMedia',
          },
        },
      });
    },
    [editVideo, props.video, props.currentFormData, formDisabled]
  );

  useEffectOnlyOnce(() => {
    props.setInitialData({ Title: props.video.Title });
  });

  useEffect(() => {
    if (!prevVisible && props.modalVisible) {
      titleRef.current?.focus?.();
    }
  });

  return (
    <Modal
      title="Edit Video Title"
      visible={props.modalVisible}
      hideModal={props.hideModal}
    >
      <form onSubmit={handleSubmit}>
        <StyledTextInput
          label="Video Title"
          name="video-title"
          value={props.currentFormData.Title}
          ref={titleRef}
          id={'video-title-' + props.video.ID}
          onChange={(e) => props.setInputState('Title', e.target.value)}
          invalid={props.invalidFields.includes('Title')}
        />
        <ValidationMessages errors={props.validationErrors} />
        <StyledModalSubmitBtn
          type="submit"
          disabled={formDisabled}
          loading={mutating}
        >
          Save
        </StyledModalSubmitBtn>
      </form>
    </Modal>
  );
};

EditVideoTitleModalForm.propTypes = {
  modalVisible: PropTypes.bool.isRequired,
  hideModal: PropTypes.func.isRequired,
};

export default WithFormHandler(
  withFlashMessaging(EditVideoTitleModalForm),
  formConfig
);
