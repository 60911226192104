import { useReactiveVar } from '@apollo/client';
import { FC, HTMLAttributes, useCallback, useMemo } from 'react';
import {
  previewFeaturesVar,
  setPreviewFeaturesVar,
} from '../../../../../../ApolloReactiveVars';
import Checkbox from '../../form/Checkbox';
import usePreviewableFeature from '../hooks/usePreviewableFeature';
import { PreviewContainer } from './PreviewSwitch.styled';

/**
 * @type {FC<{
 *    feature: string,
 * } & HTMLAttributes<HTMLLabelElement>>}
 */
export const PreviewSwitch = ({ children, feature: featureName, ...props }) => {
  const previewable = usePreviewableFeature(featureName);
  // reactive var for prototype, could switch to user profile data later
  const previewFeatures = useReactiveVar(previewFeaturesVar);

  const previewing = useMemo(() => {
    if (!previewable) {
      return false;
    }
    return previewFeatures.includes(featureName);
  }, [previewable, previewFeatures, featureName]);

  const togglePreview = useCallback(() => {
    if (!previewable) {
      return;
    }

    const newPreview = previewFeatures.includes(featureName)
      ? previewFeatures.filter((feature) => feature !== featureName)
      : [...previewFeatures, featureName].filter(Boolean);

    setPreviewFeaturesVar(newPreview);
  }, [previewable, previewFeatures, featureName]);

  // hide this if feature is already set on for LD flag, or feature name not defined
  if (!previewable || featureName == null) {
    return null;
  }
  return (
    <PreviewContainer {...props}>
      <Checkbox checked={previewing} onChange={togglePreview} />
      <span>{children}</span>
    </PreviewContainer>
  );
};

export default PreviewSwitch;
