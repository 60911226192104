import { useFlags } from 'launchdarkly-react-client-sdk';
import { camelCase, isEqual } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import getDevFeature from '../helpers/getDevFeature';
import useFeature from './useFeature';

/**
 * @param {string | string[]} featureNamesInput
 * @returns {boolean | boolean[]}
 */
export const usePreviewableFeature = (featureNamesInput) => {
  const flags = useFlags();
  /** @type {string | null} */
  const previewModeFeature = useFeature('preview-features-mode');
  const [featureNames, setFeatureNames] = useState(featureNamesInput);

  // memoize the array reference by checking value in this effect
  useEffect(() => {
    if (isEqual(featureNames, featureNamesInput)) {
      return;
    }
    setFeatureNames(featureNamesInput);
  }, [featureNamesInput, featureNames]);

  const previewable = useMemo(() => {
    const features = Array.isArray(featureNames)
      ? featureNames
      : [featureNames];

    if (!previewModeFeature) {
      return false;
    }
    const previewFeatures = previewModeFeature.split(',');

    const featurePreview = features.map((featureName) => {
      const featureEnabled = (() => {
        if (window.location.hostname === 'localhost') {
          const feature = getDevFeature(featureName);

          if (feature != null) {
            return feature;
          }
        }

        // Launch darkly transforms flag names to camel case
        const flagName = camelCase(featureName);

        return flags[flagName] || false;
      })();

      return !featureEnabled && previewFeatures.includes(featureName);
    });

    return Array.isArray(featureNames) ? featurePreview : featurePreview[0];
  }, [previewModeFeature, featureNames, flags]);

  return previewable;
};

export default usePreviewableFeature;
