import styled, { css } from 'styled-components';
import LinkWithPrevState from '../../../../../../../components/LinkWithPrevState';

export const StyledTab = styled(LinkWithPrevState)`
  ${({ theme, $isActive, disabled }) => css`
    cursor: pointer;
    text-align: center;
    margin-left: ${theme.spacing.xl};
    color: var(--tab-text-color);
    display: flex;
    flex-direction: column;
    gap: ${theme.spacing.sm};
    font-weight: 500;
    &:active {
      color: ${theme.color.primary};
    }
    ${$isActive &&
    css`
      color: ${theme.color.primary};
    `}
    ${disabled &&
    css`
      color: var(--tab-disabled-text-color);
      pointer-events: none;
    `}
  `}
`;
