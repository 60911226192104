import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import VHNameTypeStep from './VHCreationSteps/VHNameTypeStep';
import VHTemplateStep from './VHCreationSteps/VHTemplateStep';
import VHDetailsSummaryStep from './VHCreationSteps/VHDetailsSummaryStep';
import * as Styled from './VHWizardModalStyles.styled';
import VHAvailabilityCounterPopulated from './VHAvailabilityCounterPopulated/VHAvailabilityCounterPopulated';
import { useQuery, useMutation } from '@apollo/client';
import { CREATE_VH, READ_TEMPLATES } from './VHCreationWizard.query';
import useFlashMessage from '../../apps/core/src/components/FlashMessage';
import useUser from '../../apps/core/src/helpers/useUser';
import { Loading } from '@virtidev/toolbox';
import WithConfirmationBox from '../../HOCs/WithConfirmationBox';
import VHLocaleVoiceStep from './VHCreationSteps/VHLocaleVoiceStep';
import tracker from '../../apps/core/src/helpers/tracker';

const VH_CREATION_STEPS = {
  NAME_AND_TYPE: 1,
  TEMPLATE_SELECTION: 2,
  LOCALE_SELECTION: 3,
  CONFIRM_DETAILS: 4,
};

const VHCreationWizard = ({
  cancelAction,
  canMakeFreeform,
  canMakeBranching,
  onVHCreated,
}) => {
  const [currentStep, setCurrentStep] = useState(
    VH_CREATION_STEPS.NAME_AND_TYPE
  );
  const [creationDetails, setCreationDetails] = useState({});
  const { OrganisationID } = useUser();

  const SaveDetails = useCallback(
    (detailsObj) => {
      setCreationDetails(Object.assign(creationDetails, detailsObj));
    },
    [creationDetails]
  );

  const {
    data: templatesData,
    loading: loadingTemplates,
    error: errorTemplates,
  } = useQuery(READ_TEMPLATES);

  const { addFlashMessage } = useFlashMessage();

  const saveToLocalObjectAndIncrementStep = (obj) => {
    if (currentStep >= 4) return;
    SaveDetails(obj);

    // for now we want to skip the template selection stage since we only have 1 template for each type
    // TODO: remove this first conditional match when we have more templates to select (edit: and when language features are merged)
    if (currentStep === 1) {
      const templateEdge = templatesData.readVirtualHumanTemplates?.edges?.find(
        (edge) => edge.node.VirtualHuman.Type === creationDetails.Type
      );
      if (!templateEdge?.node) {
        console.error('No template found for given type');
      } else {
        SaveDetails({ Template: templateEdge.node });
      }
      setStep(VH_CREATION_STEPS.LOCALE_SELECTION);
    } else {
      setStep(currentStep + 1);
    }
  };
  const [createVH, { loading: creating }] = useMutation(CREATE_VH, {
    update: (cache, { data }) => {
      cache.evict({ fieldName: 'readVirtualHumans' });
    },
    onCompleted: () => {
      addFlashMessage('Successfully created Virtual Human');
      tracker.track('vh_create_without_ai_completed');
      if (onVHCreated) {
        onVHCreated();
      }
    },
    onError: () => {
      addFlashMessage('Error retrieving vh data', 'error');
    },
  });

  const handleCreateVirtualHuman = () => {
    const variables = {
      VirtualHumanID: creationDetails.Template.VirtualHuman.ID,
      OrganisationID,
      Title: creationDetails.Title,
      AdminTitle: creationDetails.AdminTitle,
      CreationMethod: 'wizard_create',
    };
    variables.Locale = creationDetails.Locale.BCP47Code;
    variables.VHVoiceID = creationDetails.Voice.ID;
    createVH({
      variables,
    });
  };

  const decrementStep = () => {
    if (currentStep <= 1) return;
    // we currently skip out template step
    // TODO: remove this conditional
    if (
      currentStep === VH_CREATION_STEPS.TEMPLATE_SELECTION ||
      currentStep === VH_CREATION_STEPS.LOCALE_SELECTION
    ) {
      setStep(1);
    } else {
      setStep(currentStep - 1);
    }
  };

  const setStep = (step) => {
    if (
      step > VH_CREATION_STEPS.length ||
      step < VH_CREATION_STEPS.NAME_AND_TYPE
    ) {
      console.error(
        "Step being set in VH Creation Wizard isn't within the current available steps."
      );
      return;
    }
    setCurrentStep(step);
  };

  const quitWizard = () => {
    setStep(1);
    cancelAction();
  };

  const getModalContent = (step) => {
    const templateEdges = templatesData?.readVirtualHumanTemplates?.edges ?? [];
    switch (step) {
      case VH_CREATION_STEPS.NAME_AND_TYPE:
        return (
          <VHNameTypeStep
            creationDetails={creationDetails}
            confirmAction={saveToLocalObjectAndIncrementStep}
            cancelAction={quitWizard}
            canMakeFreeform={canMakeFreeform}
            canMakeBranching={canMakeBranching}
            templateEdges={templateEdges}
          />
        );

      case VH_CREATION_STEPS.TEMPLATE_SELECTION:
        return (
          <VHTemplateStep
            creationDetails={creationDetails}
            confirmAction={saveToLocalObjectAndIncrementStep}
            cancelAction={decrementStep}
            templateEdges={templateEdges}
          />
        );

      case VH_CREATION_STEPS.LOCALE_SELECTION:
        return (
          <VHLocaleVoiceStep
            creationDetails={creationDetails}
            confirmAction={saveToLocalObjectAndIncrementStep}
            cancelAction={decrementStep}
            loading={creating}
          />
        );

      case VH_CREATION_STEPS.CONFIRM_DETAILS:
        return (
          <VHDetailsSummaryStep
            creationDetails={creationDetails}
            confirmAction={handleCreateVirtualHuman}
            cancelAction={decrementStep}
            loading={creating}
          />
        );

      default:
        return <>default step</>;
    }
  };
  return (
    <Styled.ModalLayout>
      <Styled.ModalTopArea>
        <VHAvailabilityCounterPopulated />
      </Styled.ModalTopArea>
      <Styled.ModalContentArea>
        {loadingTemplates && (
          <Styled.LoadingWrapper>
            <Loading />
          </Styled.LoadingWrapper>
        )}
        {!loadingTemplates &&
          templatesData?.readVirtualHumanTemplates &&
          getModalContent(currentStep)}
        {!loadingTemplates && errorTemplates && (
          <div>Error retrieving templates.</div>
        )}
      </Styled.ModalContentArea>
    </Styled.ModalLayout>
  );
};

VHCreationWizard.propTypes = {
  cancelAction: PropTypes.func.isRequired,
  canMakeFreeform: PropTypes.bool.isRequired,
  canMakeBranching: PropTypes.bool.isRequired,
  onVHCreated: PropTypes.func,
};

export default WithConfirmationBox(VHCreationWizard);
