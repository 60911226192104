import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import MyCourseUnit from './MyCourseUnit';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import _ from 'lodash';
import { Card } from '@virtidev/toolbox';

const StyledModuleSection = styled(Card)`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  overflow: hidden;
  transition: height 0.2s ease-out;
  margin-bottom: 2rem;
`;

const StyledModuleUnitsContainer = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  grid-auto-rows: auto;
  gap: 1rem;
`;

const StyledModuleTitleContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 0px;
  cursor: pointer;
`;

const StyledModuleExpandArrowContainer = styled.div`
  cursor: pointer;
  padding: 0px 1.4rem;
  transform: rotate(-90deg)
    ${(props) => (props.expanded ? 'rotate3d(0, 1, 0, 180deg)' : '')};
  transition: transform 0.3s;
`;

const StyledModuleDescriptionContainer = styled.div`
  width: 100%;
  margin-bottom: 2rem;
  padding-top: 1rem;
  border-top: 1px solid lightgrey;
`;

const StyledModuleDescriptionTitle = styled.div`
  font-size: 1rem;
  margin-bottom: 1rem;
`;

const StyledModuleDescription = styled.p`
  color: #939ca3;
  font-size: 0.8rem;
`;

const StyledModuleTitle = styled.div`
  font-size: 1.4rem;
  cursor: pointer;
`;

const StyledPlaceholderTextContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  padding: 20px 0px;
`;
const StyledPlaceholderText = styled.p`
  font-size: 1rem;
  color: black;
`;

const MyCourseModule = (props) => {
  const [expanded, setExpanded] = useState();

  useEffect(() => {
    if (props.activeModule === props.index) {
      setExpanded(true);
    }
  }, [props]);

  const expandContract = () => {
    setExpanded(!expanded);
  };
  // filter out units that do not have a valid ID
  const units = props.moduleData.Units.edges
    .map(({ node }) => node)
    .filter(
      (unit) => !!Number(unit.Simulation?.ID) || !!Number(unit.VirtualHuman?.ID)
    );

  const getUnitAvailability = (isCompleted, index, isUnlocked) => {
    let lockedState;
    if (props.lockModule) {
      return 'Locked'; // maybe move this up to module UI
    }
    if (isUnlocked === true) {
      if (isCompleted === true) {
        lockedState = 'Completed';
      } else {
        lockedState = 'Available';
      }
    } else {
      if (index - 1 >= 0) {
        //Check if there is a previous item in the first place.
        if (units[index - 1].isCompleted === true || index === 0) {
          //Check if that unit is completed
          //Previous unit exists and is completed, set this unit to 'Available' as opposed to 'Locked'
          lockedState = 'Available';
        } else {
          //Previous unit not completed, set this to 'Locked'
          lockedState = 'Locked';
        }
      } else {
        lockedState = 'Available';
      }
    }

    return lockedState;
  };

  if (units.length === 0) {
    return null;
  }

  return (
    <StyledModuleSection expanded={expanded}>
      <StyledModuleTitleContainer onClick={expandContract}>
        <StyledModuleTitle>{props.moduleData.Title}</StyledModuleTitle>
        <StyledModuleExpandArrowContainer
          expanded={expanded}
          onClick={expandContract}
        >
          <FontAwesomeIcon icon="chevron-left" size="lg" />
        </StyledModuleExpandArrowContainer>
      </StyledModuleTitleContainer>
      {expanded && (
        <>
          <StyledModuleDescriptionContainer>
            <StyledModuleDescriptionTitle>
              Description
            </StyledModuleDescriptionTitle>
            <StyledModuleDescription>
              {props.moduleData.Description}
            </StyledModuleDescription>
          </StyledModuleDescriptionContainer>
          <StyledModuleUnitsContainer>
            {units.map((unit, index) => (
              <MyCourseUnit
                moduleIndex={props.index}
                unitIndex={index}
                key={unit.ID}
                courseId={props.moduleData.CourseID}
                unitData={unit}
                availability={getUnitAvailability(
                  unit.isCompleted,
                  index,
                  unit.isUnlocked
                )}
              />
            ))}
          </StyledModuleUnitsContainer>
        </>
      )}
    </StyledModuleSection>
  );
};

MyCourseModule.propTypes = {
  moduleData: PropTypes.object.isRequired,
  userID: PropTypes.number,
};

export default MyCourseModule;
