import { useCallback, useMemo } from 'react';
import { GetFormattedTime } from '../../../../../../../utility/TimeFormatting';
import { Image } from '@virtidev/toolbox';
import {
  ImageContainer,
  TimeLabel,
  TitleContainer,
  TitleLabel,
  VideoCardContainer,
  VideoTypeIcon,
} from './VideoCard.styled';

export const VideoCard = ({ video, onSelect, disabled, selected }) => {
  const { ID, Title, Length, PosterURL, Content360 } = video;

  const time = useMemo(() => GetFormattedTime(Length * 1000), [Length]);

  const handleSelect = useCallback(() => {
    onSelect(ID);
  }, [onSelect, ID]);

  return (
    <VideoCardContainer
      disabled={disabled}
      $selected={selected}
      onClick={handleSelect}
    >
      <ImageContainer>
        <Image src={PosterURL} cover />
      </ImageContainer>
      <VideoTypeIcon icon={Content360 ? 'video-3d' : 'video-2d'} />
      <TitleContainer title={`${Title} - ${time}`}>
        <TitleLabel>{Title || 'Untitled'}</TitleLabel>
        <TimeLabel>{time}</TimeLabel>
      </TitleContainer>
    </VideoCardContainer>
  );
};

export default VideoCard;
