import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import HelpTooltip from '../components/HelpTooltip';
import { Loading } from '@virtidev/toolbox';

const StyledLabel = styled.label`
  margin-bottom: 0.8rem;
  font-weight: ${({ bold }) => (bold ? 500 : null)};
  font-size: 1rem;
  position: relative;
  display: block;
  color: var(--primary-font-color);
  text-align: ${({ centered }) => centered && 'center'};
`;

const StyledHelpToolTip = styled(HelpTooltip)`
  position: absolute;
  right: 0;
  padding: 0;
  top: -3px;
`;

const StyledLoadingIcon = styled(Loading)`
  position: absolute;
  top: 0;
  right: 0;
`;

const Label = (props) => (
  <StyledLabel
    bold={props.bold}
    centered={props.centered}
    htmlFor={props.htmlFor}
    className={props.className}
  >
    {!props.loading && props.tooltip && (
      <StyledHelpToolTip text={props.tooltip} side={props.tooltipSide} />
    )}
    {!!props.loading && <StyledLoadingIcon />}
    {props.children}
  </StyledLabel>
);

Label.defaultProps = {
  bold: true,
  centered: false,
};

Label.propTypes = {
  tooltip: PropTypes.string,
  bold: PropTypes.bool,
  tooltipSide: PropTypes.string,
  loading: PropTypes.bool,
  className: PropTypes.string,
};

export default Label;
