import { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import {
  BetaIcon,
  CreatedVHCard,
  CreatedVHDetails,
  CreatedVHTitle,
  ErrorText,
  FieldError,
  FieldGroup,
  FieldGroupLegend,
  FieldWrapper,
  FormWrapper,
  ImageLink,
  Label,
  LabelWrapper,
  PersonalitySliders,
  Text,
  Link,
  LoadingWrapper,
  CreateWithAiModal,
  WelcomeModalHeading,
  WelcomeModalSubHeading,
  WelcomeModal,
  WelcomeModalFooter,
  WelcomeModalContentSection,
  WelcomeModalImages,
} from './CreateVirtualHumanWithAiForm.styled';
import { useForm } from 'react-hook-form';
import { formFields } from './CreateVirtualHumanWithAiForm.config';
import {
  Button,
  ConfirmationModal,
  Select,
  TextInput,
} from '@virtidev/toolbox';
import RadioSelect from './components/RadioSelect';
import DeleteButton from './components/DeleteButton';
import useGenerateVHDetails from '../../../../../../utility/VHHooks/useGenerateVHDetails';
import RangeSlider from '../../form/RangeSlider/RangeSlider';
import useGenerateVHConversationItems from '../../../../../../utility/VHHooks/useGenerateVHConversationItems';
import useVirtualHumanDefaults from './hooks/useVirtualHumanDefaults';
import { useMutation } from '@apollo/client';
import { CREATE_VH } from './CreateVirtualHumanWithAiForm.query';
import useUser from '../../../helpers/useUser';
import VHAIFiles from './components/VHAIFiles/VHAIFiles';
import PreviewImage from './components/PreviewImage';
import { captureException } from '@sentry/browser';
import tracker from '../../../helpers/tracker';
import FieldHelp from './components/FieldHelp';
import { useLocaleOptions } from '../../../helpers/useLocaleOptions';
import { usePrevious } from '../../../../../../utility/CustomHooks';
import LoadingFlavourText from './components/LoadingFlavourText/LoadingFlavourText';
import {
  convItemLoadingTexts,
  generalLoadingTexts,
} from './CreateVirtualHumanWithAiForm.data';
import getConfig from '@base/lib/config';

const bearWithUsText =
  'Note: this process can take a while, please bear with us for a minute or two!';

/**
 * @typedef {import('../../../../../../utility/VHHooks/useGenerateVHDetails.types').vhDetails} vhDetails
 * @typedef {import('../../../../../../utility/VHHooks/useGenerateVHDetails.types').AIVHGeneratingData} AIVHGeneratingData
 * @typedef {import('../../../../../../utility/VHHooks/useGenerateVHDetails.types').AIVHGeneratingObjective} AIVHGeneratingObjective
 * @typedef {import('../../../../../../utility/VHHooks/useGenerateVHDetails.types').AIVHAllData} AIVHAllData
 */

const urlParams = new URLSearchParams(window.location.search);
const devMode = urlParams.get('devmode');

const templateOptions = [
  { value: 'sales', label: 'Sales' },
  { value: 'customerService', label: 'Customer Service' },
  { value: 'hr', label: 'Human Resources' },
  { value: 'medicalEmpathy', label: 'Medical Empathy' },
];

const personalityFactors = [
  'virtualHumanChattiness',
  'virtualHumanConfidence',
  'virtualHumanAggressiveness',
  'virtualHumanOpenness',
];

const VH_CREATION_STEPS = {
  NO_FREE_LICENSES: -3,
  ERROR_CONVERSATION_ITEMS: -2,
  ERROR_GENERAL: -1,
  WELCOME: 0,
  INPUT: 1,
  GENERATING_GENERAL: 2,
  EDIT_GENERAL: 3,
  GENERATING_CONVERSATION_ITEMS: 4,
  SAVING: 5,
  DONE: 6,
};

const getMixpanelStepEvent = (step) => {
  switch (step) {
    case VH_CREATION_STEPS.ERROR_GENERAL ||
      VH_CREATION_STEPS.ERROR_CONVERSATION_ITEMS:
      return 'Error';
    case VH_CREATION_STEPS.WELCOME:
      return 'Welcome';
    case VH_CREATION_STEPS.INPUT:
      return 'Editing Initial Data';
    case VH_CREATION_STEPS.EDIT_GENERAL:
      return 'Editing General Response Data';
    case VH_CREATION_STEPS.DONE:
      return 'Complete';
    default:
      return null;
  }
};

const CreateVirtualHumanWithAiForm = ({ show, onHide, canMakeFreeform }) => {
  const {
    register,
    unregister,
    handleSubmit,
    watch,
    formState: { errors, isDirty },
    setValue,
  } = useForm();

  const [
    generateGeneral,
    { isLoading: isGeneratingGeneral, error: errorGeneratingGeneral },
  ] = useGenerateVHDetails();
  const [
    generateConversationItems,
    {
      isLoading: isGeneratingConversationItems,
      error: errorGeneratingConversationItems,
    },
  ] = useGenerateVHConversationItems();

  const [template, setTemplate] = useState('sales');

  const [gender, setGender] = useState('');
  const [locale, setLocale] = useState('en-US');
  const [formStep, setCurrentFormStep] = useState(
    canMakeFreeform || getConfig('REACT_APP_ENV') === 'dev'
      ? VH_CREATION_STEPS.WELCOME
      : VH_CREATION_STEPS.NO_FREE_LICENSES
  );

  const [editedFields, setEditedFields] = useState([]);

  const setFormStep = useCallback(
    (nextStep) => {
      const mixpanelStepEvent = getMixpanelStepEvent(formStep);
      if (mixpanelStepEvent) {
        tracker.track('vh_create_with_ai_generator_step_completed', {
          step: mixpanelStepEvent,
          ...(editedFields.length > 0 ? { editedFields } : {}),
        });
      }
      setEditedFields([]);
      setCurrentFormStep(nextStep);
    },
    [setCurrentFormStep, formStep, editedFields]
  );

  const [showBackConfirmation, setShowBackConfirmation] = useState(false);
  const [showCloseConfirmation, setShowCloseConfirmation] = useState(false);

  let warnBeforeClose = false;
  const neverWarnSteps = [
    VH_CREATION_STEPS.DONE,
    VH_CREATION_STEPS.WELCOME,
    VH_CREATION_STEPS.NO_FREE_LICENSES,
  ];

  if (neverWarnSteps.includes(formStep)) {
    warnBeforeClose = false;
  } else if (isDirty || ![VH_CREATION_STEPS.INPUT].includes(formStep)) {
    warnBeforeClose = true;
  }

  const localeSelectOptions = useLocaleOptions();

  const age = parseInt(watch('virtualHumanAge'), 10) || 35;

  const {
    environmentID,
    avatarID,
    voiceID,
    vhSourceID,
    loading: vhDefaultsLoading,
    error: vhDefaultsError,
  } = useVirtualHumanDefaults(locale, gender, age);

  const { OrganisationID, Organisation } = useUser();

  const [
    createVH,
    { data: createVHData, loading: createVHLoading, error: createVHError },
  ] = useMutation(CREATE_VH, {
    update: (cache) => {
      cache.evict({ fieldName: 'readVirtualHumans' });
    },
  });

  const vhNew = createVHData?.copyVirtualHuman;

  const loading =
    isGeneratingGeneral ||
    isGeneratingConversationItems ||
    createVHLoading ||
    vhDefaultsLoading;

  const prevGeneralError = usePrevious(errorGeneratingGeneral);
  const prevConvItemsError = usePrevious(errorGeneratingConversationItems);
  const prevCreateVHError = usePrevious(createVHError);

  useEffect(() => {
    if (errorGeneratingGeneral && !prevGeneralError) {
      setFormStep(VH_CREATION_STEPS.ERROR_GENERAL);
    }
  }, [errorGeneratingGeneral, prevGeneralError, formStep, setFormStep]);

  useEffect(() => {
    if (errorGeneratingConversationItems && !prevConvItemsError) {
      setFormStep(VH_CREATION_STEPS.ERROR_CONVERSATION_ITEMS);
    }
  }, [
    errorGeneratingConversationItems,
    prevConvItemsError,
    formStep,
    setFormStep,
  ]);

  useEffect(() => {
    if (createVHError && !prevCreateVHError) {
      setFormStep(VH_CREATION_STEPS.ERROR_CONVERSATION_ITEMS);
    }
  }, [createVHError, prevCreateVHError, formStep, setFormStep]);

  const onSubmit = useCallback(
    async (data) => {
      console.log('switch step');
      console.log(formStep);
      /** @type {vhDetails} */
      const vhData = {
        vhName: data.virtualHumanName,
        vhRoleMain: data.virtualHumanRole,
        learnerRole: data.learnerRole,
        scenarioSynopsis: data.scenarioSynopsis,
        vhRoleExtra: '',
        learnerObjective: '',
        characterInfo: data.virtualHumanSynopsis,
        unstructuredData: data.additionalContext,
        BCP47Code: locale,
        fileIds: data.fileIds,
      };

      switch (formStep) {
        case VH_CREATION_STEPS.ERROR_GENERAL:
        case VH_CREATION_STEPS.INPUT:
          setFormStep(VH_CREATION_STEPS.GENERATING_GENERAL);
          /** @type {{data: {data: {generatedVH: AIVHGeneratingData}}}} */
          const generatedVHResponse = await generateGeneral({ vhData }, true);
          const generatedVH = generatedVHResponse?.data?.data?.generatedVH;
          if (generatedVH?.objectives && generatedVH?.objectives.length > 0) {
            const generatedObjectives = generatedVH.objectives;
            const formValues = {
              scenarioName: generatedVH.scenarioName,
              scenarioDescription: generatedVH.scenarioDescription,
              scenarioObjective: generatedVH.scenarioObjective,
              learnerObjectives: generatedObjectives
                .map((objective) => objective.name)
                .join('\n'),
              // virtualHumanName: generatedVH.name, // ToDo
              virtualHumanAge: generatedVH.age || 0,
              virtualHumanInitialGreeting: generatedVH.initialGreeting,
              virtualHumanBiography: generatedVH.biography,
              virtualHumanPersonalityTraits:
                generatedVH.personalityTraits.join('\n'),
              virtualHumanHobbies: generatedVH.hobbies.join('\n'),
              virtualHumanChattiness: generatedVH.personalityLevels.chattiness,
              virtualHumanConfidence: generatedVH.personalityLevels.confidence,
              virtualHumanAggressiveness:
                generatedVH.personalityLevels.aggressiveness,
              virtualHumanOpenness: generatedVH.personalityLevels.openness,
              virtualHumanName: generatedVH.vhName,
              fileIds: data.fileIds,
            };
            Object.entries(formValues).forEach(([key, value]) => {
              setValue(key, value);
            });
            setGender(`${generatedVH.gender || ''}`.toLowerCase());
            setFormStep(VH_CREATION_STEPS.EDIT_GENERAL);
          } else {
            console.log('error');
            setFormStep(VH_CREATION_STEPS.ERROR_GENERAL);
            captureException(
              new Error('AI generation of virtual human failed')
            );
          }
          break;
        case VH_CREATION_STEPS.ERROR_CONVERSATION_ITEMS:
        case VH_CREATION_STEPS.EDIT_GENERAL:
          setFormStep(VH_CREATION_STEPS.GENERATING_CONVERSATION_ITEMS);
          const convRequestBody = {
            vhData: {
              ...vhData,
              initialGreeting: data.virtualHumanInitialGreeting,
              scenarioDescription: data.scenarioDescription,
              scenarioObjective: data.scenarioObjective,
              biography: data.virtualHumanBiography,
              hobbies: data.virtualHumanHobbies.split('\n'),
              personalityTraits: data.virtualHumanPersonalityTraits.split('\n'),
              personalityLevels: {
                chattiness: data.virtualHumanChattiness,
                confidence: data.virtualHumanConfidence,
                aggressiveness: data.virtualHumanAggressiveness,
                openness: data.virtualHumanOpenness,
              },
              objectives: data.learnerObjectives.split('\n').map((name) => ({
                name,
                points: 3,
              })),
              age: data.virtualHumanAge,
              gender,
              scenarioName: data.scenarioName,
              fileIds: data.fileIds,
            },
          };

          /** @type {AIVHGeneratingObjective[]} */
          const conversationItemsResult = (
            await generateConversationItems(convRequestBody)
          )?.data?.data;
          if (conversationItemsResult) {
            setFormStep(VH_CREATION_STEPS.SAVING);
            // Create a new VH
            const newVHData = await createVH({
              variables: {
                VirtualHumanID: vhSourceID,
                OrganisationID,
                VHVoiceID: voiceID,
                EnvironmentID: environmentID,
                AvatarID: avatarID,
                Title: data.scenarioName,
                AdminTitle: data.scenarioName,
                Description: data.scenarioDescription,
                Locale: locale,
                CreationMethod: 'ai_create',
                VHDuplicateDataJSON: JSON.stringify({
                  aiGeneratedVH: {
                    vhName: data.virtualHumanName,
                    vhRoleMain: data.virtualHumanRole,
                    learnerRole: data.learnerRole,
                    scenarioSynopsis: data.scenarioSynopsis,
                    vhRoleExtra: '',
                    learnerObjective: '',
                    characterInfo: data.virtualHumanSynopsis,
                    unstructuredData: data.additionalContext,
                    BCP47Code: locale,
                    initialGreeting: data.virtualHumanInitialGreeting,
                    scenarioDescription: data.scenarioDescription,
                    scenarioObjective: data.scenarioObjective,
                    biography: data.virtualHumanBiography,
                    hobbies: data.virtualHumanHobbies.split('\n'),
                    personalityTraits:
                      data.virtualHumanPersonalityTraits.split('\n'),
                    personalityLevels: {
                      chattiness: data.virtualHumanChattiness,
                      confidence: data.virtualHumanConfidence,
                      aggressiveness: data.virtualHumanAggressiveness,
                      openness: data.virtualHumanOpenness,
                    },
                    objectives: conversationItemsResult,
                    age: data.virtualHumanAge,
                    gender,
                    scenarioName: data.scenarioName,
                    fileIds: data.fileIds,
                    promptVersion: 'default_v2',
                  },
                }),
              },
            });
            tracker.track('vh_create_with_ai_generator_completed');
            if (newVHData?.data?.copyVirtualHuman?.ID) {
              setFormStep(VH_CREATION_STEPS.DONE);
            } else {
              setFormStep(VH_CREATION_STEPS.ERROR_CONVERSATION_ITEMS);
              captureException(
                new Error('Saving AI generated virtual human failed')
              );
            }
          } else {
            setFormStep(VH_CREATION_STEPS.ERROR_CONVERSATION_ITEMS);
            captureException(
              new Error('AI generation of VH conversation items failed')
            );
          }
          break;
        default:
          break;
      }
    },
    [
      locale,
      formStep,
      setFormStep,
      generateGeneral,
      gender,
      generateConversationItems,
      createVH,
      vhSourceID,
      OrganisationID,
      voiceID,
      environmentID,
      avatarID,
      setValue,
    ]
  );

  const resendGeneral = useCallback(() => {
    handleSubmit(onSubmit)();
  }, [onSubmit, handleSubmit]);

  const resendItems = useCallback(() => {
    handleSubmit(onSubmit)();
  }, [onSubmit, handleSubmit]);

  const onBack = useCallback(() => setShowBackConfirmation(true), []);

  const onCancelBack = useCallback(() => {
    setShowBackConfirmation(false);
  }, []);

  const onConfirmBack = useCallback(() => {
    setShowBackConfirmation(false);
    switch (formStep) {
      case VH_CREATION_STEPS.EDIT_GENERAL:
        setFormStep(VH_CREATION_STEPS.INPUT);
        break;
      default:
        break;
    }
  }, [formStep, setFormStep]);

  const onClose = useCallback(
    () => (warnBeforeClose ? setShowCloseConfirmation(true) : onHide()),
    [warnBeforeClose, onHide]
  );

  const onCancelClose = useCallback(() => {
    setShowCloseConfirmation(false);
  }, []);

  const onConfirmClose = useCallback(() => {
    setShowCloseConfirmation(false);
    onHide();
  }, [onHide]);

  function getFieldProps(type, id = '') {
    const { formOptions } = formFields[type];
    const registerId = id || type;
    return {
      ...register(registerId, formOptions),
      placeholder: formFields[type].placeholders?.[template]
        ? `e.g. ${formFields[type].placeholders?.[template]}`
        : undefined,
      id: registerId,
      onChange: () => {
        setEditedFields((prevEditedFields) => [
          ...new Set([...prevEditedFields, formFields[type].label]),
        ]);
      },
    };
  }

  function getErrorMessage(fieldError) {
    if (!fieldError) return null;
    const { type, message } = fieldError;
    if (message) return message;
    switch (type) {
      case 'required':
        return 'This field is required';
      default:
        return null;
    }
  }

  function withWrapper(formField, type = '') {
    const name = formField.props.name;
    const { label, helpText } = formFields[type || name] || formField.props;
    const fieldError = errors[name];
    const errorMessage = getErrorMessage(fieldError);
    return (
      <FieldWrapper key={name}>
        <LabelWrapper>
          <Label htmlFor={name} horizontal>
            {label}
          </Label>
          {errorMessage && (
            <FieldError htmlFor={name}>{errorMessage}</FieldError>
          )}
        </LabelWrapper>
        {helpText && (
          <FieldHelp>
            <p>{helpText}</p>
          </FieldHelp>
        )}
        {formField}
      </FieldWrapper>
    );
  }

  const applyTemplate = useCallback(() => {
    const replaceEG = (str) => str.replace('e.g. ', '');
    setValue(
      'virtualHumanRole',
      replaceEG(formFields['virtualHumanRole'].placeholders?.[template])
    );
    setValue(
      'learnerRole',
      replaceEG(formFields['learnerRole'].placeholders?.[template])
    );
    setValue(
      'scenarioSynopsis',
      replaceEG(formFields['scenarioSynopsis'].placeholders?.[template])
    );
    setValue(
      'virtualHumanSynopsis',
      replaceEG(formFields['virtualHumanSynopsis'].placeholders?.[template])
    );
    setValue(
      'virtualHumanName',
      replaceEG(formFields['virtualHumanName'].placeholders?.[template])
    );
  }, [setValue, template]);

  function getModalTitle() {
    switch (formStep) {
      case VH_CREATION_STEPS.ERROR_GENERAL:
      case VH_CREATION_STEPS.ERROR_CONVERSATION_ITEMS:
        return 'Error';
      case VH_CREATION_STEPS.WELCOME:
        return <></>;
      case VH_CREATION_STEPS.GENERATING_GENERAL:
        return 'Generating scenario details';
      case VH_CREATION_STEPS.EDIT_GENERAL:
        return 'Review result';
      case VH_CREATION_STEPS.GENERATING_CONVERSATION_ITEMS:
        return 'Generating conversation items';
      case VH_CREATION_STEPS.SAVING:
        return 'Saving your virtual human';
      case VH_CREATION_STEPS.DONE:
        return 'Virtual human created';
      default:
        return 'Create a virtual human with AI ✨';
    }
  }

  const goToNextStep = useCallback(() => {
    setFormStep(formStep + 1);
  }, [formStep, setFormStep]);

  function getModalFooter() {
    switch (formStep) {
      case VH_CREATION_STEPS.WELCOME:
        return (
          <WelcomeModalFooter>
            <div>
              This feature does not support branching{' '}
              {Organisation?.Medical ? 'or medical freeform ' : ''}
              virtual humans.
            </div>
            <Button color="turquoise" onClick={goToNextStep}>
              Get started
            </Button>
          </WelcomeModalFooter>
        );
      case VH_CREATION_STEPS.INPUT:
        return <Button onClick={handleSubmit(onSubmit)}>Continue</Button>;
      case VH_CREATION_STEPS.EDIT_GENERAL:
        return (
          <>
            <Button onClick={onBack}>Back</Button>
            <Button onClick={handleSubmit(onSubmit)}>Save</Button>
          </>
        );
      case VH_CREATION_STEPS.GENERATING_CONVERSATION_ITEMS:
        return null;
      case VH_CREATION_STEPS.GENERATING_GENERAL:
        return null;
      case VH_CREATION_STEPS.SAVING:
        return null;
      default:
        return (
          <>
            <Button onClick={onClose}>Close</Button>
          </>
        );
    }
  }

  return (
    <CreateWithAiModal
      $isWelcomeStep={formStep === VH_CREATION_STEPS.WELCOME}
      show={show}
      onHide={onClose}
      title={getModalTitle()}
      footer={getModalFooter()}
    >
      <FormWrapper>
        {formStep === VH_CREATION_STEPS.NO_FREE_LICENSES && (
          <Text>
            Sorry, but this feature can only be used for freeform virtual
            humans, and all of your freeform licenses are in use. If you require
            more licenses, please contact{' '}
            <Link href="mailto:support@virti.com">support@virti.com</Link>.
          </Text>
        )}
        {formStep === VH_CREATION_STEPS.ERROR_GENERAL && (
          <ErrorText>
            <div>Sorry, something went wrong.</div>
            <Button onClick={resendGeneral}>Try again</Button>
          </ErrorText>
        )}
        {formStep === VH_CREATION_STEPS.ERROR_CONVERSATION_ITEMS && (
          <ErrorText>
            <div>Sorry, something went wrong.</div>
            <Button onClick={resendItems}>Try again</Button>
          </ErrorText>
        )}
        {formStep === VH_CREATION_STEPS.WELCOME && (
          <WelcomeModal>
            <WelcomeModalContentSection>
              <div>
                <WelcomeModalHeading>Create with AI ✨</WelcomeModalHeading>
                {getConfig('REACT_APP_ENV') === 'dev' && (
                  <div style={{ color: 'red' }}>
                    WARNING: COMPLETING CREATION MAY CREATE A PRODUCTION VH
                  </div>
                )}
                <Text>
                  Get started with Virtual human the fast way, using our new AI
                  tool. Give us the context and goals and we'll create a
                  character to get you up and running quickly.
                </Text>
              </div>
              <div>
                <WelcomeModalSubHeading>Get inspired</WelcomeModalSubHeading>
                <Text>
                  Get helpful content suggestions based on common workplace
                  scenarios. Speeding up your workflow and improving training
                  effectiveness.
                </Text>
              </div>
              <div>
                <WelcomeModalSubHeading>Set your goals</WelcomeModalSubHeading>
                <Text>
                  Give a summary of the context and goals of your scenario, and
                  we'll suggest the learning objectives and conversation items
                  to help you reach it.
                </Text>
              </div>
              <div>
                <WelcomeModalSubHeading>Customize</WelcomeModalSubHeading>
                <Text>
                  Describe your virtual human’s personality and interests to
                  give them natural and authentic responses.{' '}
                </Text>
              </div>
            </WelcomeModalContentSection>
            <WelcomeModalImages>
              <img src="/images/vh/create_with_ai.png" alt="" />
            </WelcomeModalImages>
          </WelcomeModal>
        )}
        {formStep === VH_CREATION_STEPS.INPUT && (
          <>
            {withWrapper(
              <RadioSelect
                label="Example content"
                legend="Example content"
                name="placeholderTemplate"
                id="placeholderTemplate"
                helpText="We'll display some placeholder content below to use as inspiration. The choice you make here will not affect the final content."
                options={templateOptions}
                value={template}
                onChange={(e) => {
                  setTemplate(e.target.value);
                }}
              />
            )}
            {devMode && <button onClick={applyTemplate}>Apply Template</button>}
            <form onSubmit={handleSubmit(onSubmit)}>
              {withWrapper(
                <Select
                  label="Language"
                  options={localeSelectOptions}
                  value={locale}
                  onChange={(option) => {
                    setLocale(option.value);
                  }}
                />
              )}
              <FieldGroup>
                <FieldGroupLegend>Scenario</FieldGroupLegend>
                {withWrapper(
                  <TextInput {...getFieldProps('virtualHumanRole')} />
                )}
                {withWrapper(<TextInput {...getFieldProps('learnerRole')} />)}
                {withWrapper(
                  <TextInput multiline {...getFieldProps('scenarioSynopsis')} />
                )}
              </FieldGroup>
              <FieldGroup>
                <FieldGroupLegend>Virtual human (optional)</FieldGroupLegend>
                {withWrapper(
                  <TextInput {...getFieldProps('virtualHumanName')} />
                )}
                {withWrapper(
                  <TextInput
                    multiline
                    {...getFieldProps('virtualHumanSynopsis')}
                  />
                )}
              </FieldGroup>
              <FieldGroup>
                <FieldGroupLegend>
                  Additional context (optional)
                </FieldGroupLegend>
                {withWrapper(
                  <VHAIFiles
                    onChange={getFieldProps('fileIds').onChange}
                    name="fileIds"
                    setValue={setValue}
                  />
                )}
              </FieldGroup>
            </form>
          </>
        )}
        {formStep === VH_CREATION_STEPS.GENERATING_GENERAL && loading && (
          <LoadingWrapper>
            <Text>{bearWithUsText}</Text>
            <LoadingFlavourText flavourTexts={generalLoadingTexts} />
          </LoadingWrapper>
        )}
        {formStep === VH_CREATION_STEPS.EDIT_GENERAL && (
          <form onSubmit={handleSubmit(onSubmit)}>
            <FieldGroup>
              <FieldGroupLegend>Scenario</FieldGroupLegend>
              {withWrapper(<TextInput {...getFieldProps('scenarioName')} />)}
              {withWrapper(
                <TextInput
                  multiline
                  rows="3"
                  {...getFieldProps('scenarioDescription')}
                />
              )}
              {withWrapper(
                <TextInput
                  multiline
                  rows="3"
                  {...getFieldProps('scenarioObjective')}
                />
              )}
              {withWrapper(
                <TextInput multiline {...getFieldProps('learnerObjectives')} />
              )}
            </FieldGroup>
            <FieldGroup>
              <FieldGroupLegend>Virtual human</FieldGroupLegend>
              {withWrapper(
                <TextInput {...getFieldProps('virtualHumanName')} />
              )}
              {/* withWrapper(
                <TextInput
                  type="number"
                  {...getFieldProps('virtualHumanAge')}
                />
              )} */}
              {withWrapper(
                <TextInput
                  multiline
                  rows="2"
                  {...getFieldProps('virtualHumanInitialGreeting')}
                />
              )}
              {withWrapper(
                <TextInput
                  multiline
                  rows="3"
                  {...getFieldProps('virtualHumanBiography')}
                />
              )}
              {withWrapper(
                <TextInput
                  multiline
                  {...getFieldProps('virtualHumanPersonalityTraits')}
                />
              )}
              {withWrapper(
                <TextInput
                  multiline
                  {...getFieldProps('virtualHumanHobbies')}
                />
              )}
              <PersonalitySliders>
                {personalityFactors.map((type) =>
                  withWrapper(
                    <RangeSlider
                      step="50"
                      min="-100"
                      max="100"
                      {...getFieldProps(type)}
                    />
                  )
                )}
              </PersonalitySliders>
            </FieldGroup>
          </form>
        )}
        {formStep === VH_CREATION_STEPS.GENERATING_CONVERSATION_ITEMS &&
          loading && (
            <LoadingWrapper>
              <Text>{bearWithUsText}</Text>
              <LoadingFlavourText flavourTexts={convItemLoadingTexts} />
            </LoadingWrapper>
          )}
        {formStep === VH_CREATION_STEPS.SAVING && loading && (
          <LoadingWrapper>
            <Text>{bearWithUsText}</Text>
            <LoadingFlavourText
              flavourTexts={[{ min: 0, max: 99999, text: 'Saving result' }]}
            />
          </LoadingWrapper>
        )}
        {formStep === VH_CREATION_STEPS.DONE && (
          <div>
            {vhNew && (
              <CreatedVHCard>
                <ImageLink href={`/virtual-humans/${vhNew.ID}`}>
                  <PreviewImage
                    ThumbnailURL={vhNew.ThumbnailURL}
                    ImageMedia={vhNew.ImageMedia}
                  />
                </ImageLink>
                <CreatedVHDetails>
                  <CreatedVHTitle>{vhNew.Title}</CreatedVHTitle>
                  <Button as="a" href={`/virtual-humans/${vhNew.ID}`}>
                    View
                  </Button>
                </CreatedVHDetails>
              </CreatedVHCard>
            )}
          </div>
        )}
      </FormWrapper>
      <ConfirmationModal
        customTitle="Please confirm"
        onHide={onCancelBack}
        onConfirm={onConfirmBack}
        show={showBackConfirmation}
      >
        If you go back, all generated content and any edits you made to it at
        this step will be discarded. You'll be able to pick up where you left
        off at the previous step.
      </ConfirmationModal>
      <ConfirmationModal
        customTitle="Please confirm"
        onHide={onCancelClose}
        onConfirm={onConfirmClose}
        show={showCloseConfirmation}
      >
        Your edits will be discarded and your virtual human will not be saved.
      </ConfirmationModal>
    </CreateWithAiModal>
  );
};

CreateVirtualHumanWithAiForm.propTypes = {
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  onVHCreated: PropTypes.func,
};

export default CreateVirtualHumanWithAiForm;
