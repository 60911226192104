import StyledLink from '../../../../../../../styled-components/StyledLink';
import { GetFormattedTimeLong } from '../../../../../../../utility/TimeFormatting';
import StyledScorePercentage from '../../../../../../../styled-components/StyledScorePercentage';
import styled, { useTheme } from 'styled-components';
import { HoveringTooltip } from '../../../../../../../components/HelpTooltip';
import {
  Button,
  Datetime,
  Icon,
  Td,
  TdLink,
  Tooltip,
  Tr,
} from '@virtidev/toolbox';
import { Actions } from '../../Analytics.styled';
import ActionMenuButton from '../../../Users/Actions/ActionMenuButton';
import { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useFeature } from '@core/components/LaunchDarkly';
import { Link } from 'react-router-dom';

const StyledSuccessWrapper = styled.div`
  position: relative;
  margin: auto;
  display: inline-block;
  &:hover .hovering-tooltip {
    display: block;
    width: auto;
    top: -0.5rem;
    transform: translateX(-44%) translateY(-100%);
  }
`;

const PlaceholderIcon = styled.div`
  width: 1rem;
  height: 1rem;
`;

export const VirtualHumanSubmissionItem = ({
  showPosition,
  position,
  score,
  nameColumn,
  link,
  name,
  medicalScores,
  vhID,
  ExternalID,
  onDelete,
  showScore,
  onScoreOverviewOpen,
  canDelete,
  isOwnLog,
}) => {
  const handleDelete = useCallback(
    (e) => {
      e.stopPropagation();
      onDelete(score.ID);
    },
    [onDelete, score.ID]
  );

  const completionEvent =
    score.AnalyticsEvents.edges.length > 0
      ? score.AnalyticsEvents.edges[0].node
      : null;
  const PDDiagnosisScore = completionEvent?.PDDiagnosisScore ?? null;
  const PDConversationScore = completionEvent?.PDConversationScore ?? null;
  const Diagnosis = completionEvent?.Diagnosis ?? null;
  const PDAssessmentID =
    score?.PDAssessmentID ?? completionEvent?.PDAssessmentID ?? null;
  const TimeSpent = completionEvent?.TimeSpent ?? null;

  const handleOpenScoreOverview = useCallback(
    (e) => {
      e.stopPropagation();
      e.preventDefault();
      onScoreOverviewOpen(score.ID);
    },
    [score.ID, onScoreOverviewOpen]
  );

  const { color } = useTheme();
  const linkTitle = 'View profile';

  const TdOrTdLink = useCallback(
    ({ children, ...props }) => {
      if (link) {
        return (
          <TdLink title={linkTitle} to={link} {...props}>
            {children}
          </TdLink>
        );
      }
      return <Td {...props}>{children}</Td>;
    },
    [link]
  );

  return (
    <Tr key={score.ID}>
      {showPosition && <td>{position}</td>}
      {nameColumn && <TdOrTdLink>{name}</TdOrTdLink>}
      <TdOrTdLink>
        <Datetime
          datetime={score.Created}
          noTimeDisplay
          long
          emptyDisplay="-"
        />
      </TdOrTdLink>
      {/* workaround for old data that was in seconds */}
      <TdOrTdLink $center={true}>
        {!TimeSpent
          ? '-'
          : GetFormattedTimeLong(
              TimeSpent < 1000 ? TimeSpent * 1000 : TimeSpent
            )}
      </TdOrTdLink>
      {showScore && (
        <TdOrTdLink $center={true}>
          <StyledScorePercentage
            align="center"
            score={PDConversationScore}
            showNA={PDConversationScore === -1 || !score.Finished}
            NA={PDConversationScore === -1 ? 'N/A' : '-'}
          >
            {PDConversationScore}
          </StyledScorePercentage>
        </TdOrTdLink>
      )}
      {medicalScores && (
        <TdOrTdLink $center={true}>
          <StyledSuccessWrapper>
            {Diagnosis && (
              <HoveringTooltip className="hovering-tooltip">
                {Diagnosis}
              </HoveringTooltip>
            )}
            {PDDiagnosisScore === -1 && 'N/A'}
            {PDDiagnosisScore !== -1 && !score.Finished && '-'}
            {score.Finished && PDDiagnosisScore === 100 && (
              <Icon
                icon="success"
                size={24}
                type="solid"
                color={color.turquoise}
              />
            )}
            {score.Finished && PDDiagnosisScore === 0 && (
              <Icon
                size={24}
                icon="fail"
                type="solid"
                style={{
                  opacity: Diagnosis ? '1' : '0.4',
                }}
                color={color.error}
              />
            )}
          </StyledSuccessWrapper>
        </TdOrTdLink>
      )}
      <Td $center={true}>
        {score.PDAssessmentID && (
          <Button
            onClick={handleOpenScoreOverview}
            icon="analytics"
            iconSize="20px"
            color="pink"
            design="transparent"
            iconType="outline"
            round={true}
            title={'View performance'}
          ></Button>
        )}
      </Td>
      <Td $center={true}>
        <Actions>
          <Tooltip
            title="More actions"
            type="menu"
            icon="dots"
            placement="bottom-end"
            autoClose
          >
            {!!PDAssessmentID && (
              <ActionMenuButton
                to={`/virtual-humans/${vhID}/builder/patient/${ExternalID}/${
                  isOwnLog ? 'my-logs' : 'chat-logs'
                }/${PDAssessmentID}`}
                as={Link}
                icon="document"
              >
                View chat log
              </ActionMenuButton>
            )}
            {canDelete && (
              <ActionMenuButton icon="bin" onClick={handleDelete}>
                Delete
              </ActionMenuButton>
            )}
          </Tooltip>
        </Actions>
      </Td>
    </Tr>
  );
};

VirtualHumanSubmissionItem.defaultProps = {
  isOwnLog: false,
};

VirtualHumanSubmissionItem.propTypes = {
  isOwnLog: PropTypes.bool,
  score: PropTypes.shape({
    ID: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    Score: PropTypes.number,
    Finished: PropTypes.bool,
    FirstScore: PropTypes.number,
    LatestScore: PropTypes.number,
    canDelete: PropTypes.bool,
    // should be an array but should only have one result (END_SIM)
    AnalyticsEvents: PropTypes.shape({
      edges: PropTypes.arrayOf(
        PropTypes.shape({
          node: PropTypes.shape({
            ID: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
            Type: PropTypes.string,
            TimeSpent: PropTypes.number,
            PDAssessmentID: PropTypes.string,
            PDDiagnosisScore: PropTypes.number,
            PDConversationScore: PropTypes.number,
            Diagnosis: PropTypes.string,
            Created: PropTypes.string,
            Member: PropTypes.shape({
              ID: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
              Name: PropTypes.string,
            }),
            VirtualHuman: PropTypes.shape({
              ID: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
              Name: PropTypes.string,
            }),
          }),
        })
      ),
    }),
  }),
  showScore: PropTypes.bool,
};

export default VirtualHumanSubmissionItem;
