import { useMutation } from '@apollo/client';
import { produce } from 'immer';
import _ from 'lodash';
import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { OptionValue } from '@virtidev/toolbox';
import useMounted from '../../helpers/useMounted';
import {
  COURSE_TAG_FRAGMENT,
  SET_TAGS,
  SIM_TAG_FRAGMENT,
  VH_TAG_FRAGMENT,
  VIDEO_TAG_FRAGMENT,
} from '../DataListCard/components/CardTooltipTags/CardTooltipTags.query';
import SelectTag from '../form/Select/components/SelectTag/SelectTag';
import { Course } from '../../models/course.types';
import { Simulation } from '../../models/simulation.types';
import { VirtualHuman } from '../../models/virtualhuman.types';
import { VideoMedia } from '../../models/video-media.types';

/**
 * @type {FC<{
 *    target: Course | Simulation | VirtualHuman | VideoMedia
 *    type: 'simulation' | 'course' | 'virtual human' | 'video'
 * }>}
 */
export const SelectTagFieldForm = ({ target, type }) => {
  const [selectedTags, setSelectedTags] = useState(
    /** @type {OptionValue[]} */ ([])
  );
  const mounted = useMounted();
  const selected = useRef(selectedTags);
  selected.current = selectedTags;

  useEffect(() => {
    if (!target) {
      return;
    }
    setSelectedTags(
      target.Tags.nodes.map(({ ID, Name }) => ({ value: ID, label: Name }))
    );
  }, [target]);

  const [setTags, { loading: setTagLoading }] = useMutation(SET_TAGS, {
    update: (cache, { data }) => {
      const prefix =
        (type === 'simulation' && 'Simulation') ||
        (type === 'course' && 'Course') ||
        (type === 'virtual human' && 'VirtualHuman') ||
        (type === 'video' && 'VideoMedia');
      const fragment =
        (type === 'simulation' && SIM_TAG_FRAGMENT) ||
        (type === 'course' && COURSE_TAG_FRAGMENT) ||
        (type === 'virtual human' && VH_TAG_FRAGMENT) ||
        (type === 'video' && VIDEO_TAG_FRAGMENT);

      if (!prefix || !fragment) {
        return;
      }

      const id = `${prefix}:${target.ID}`;
      const part = cache.readFragment({
        id,
        fragment,
      });
      if (!part?.Tags?.nodes) {
        return;
      }
      const newData = produce(part, (draft) => {
        draft.Tags.nodes = data.setTags;
      });
      cache.writeFragment({
        id,
        fragment,
        data: newData,
      });
    },
  });
  const loadingRef = useRef(setTagLoading);
  loadingRef.current = setTagLoading;

  const queueSave = useMemo(
    () =>
      _.debounce((newTags) => {
        if (loadingRef.current && mounted.current) {
          queueSave(newTags);
          return;
        }
        setTags({
          variables: {
            tagIds: newTags.map(({ value }) => value),
            simId: type === 'simulation' ? target?.ID : null,
            courseId: type === 'course' ? target?.ID : null,
            vhId: type === 'virtual human' ? target?.ID : null,
            videoId: type === 'video' ? target?.ID : null,
          },
          optimisticResponse: {
            setTags: newTags.map(
              ({ label, value }) =>
                selected.current.find((ID) => ID === value) || {
                  ID: value,
                  Name: label,
                  __typename: 'ContentTag',
                }
            ),
          },
        });
      }, 1500),
    [setTags, target?.ID, mounted, type]
  );

  const updateTags = useCallback(
    (inputTags) => {
      setSelectedTags(inputTags);

      queueSave(inputTags);
    },
    [queueSave]
  );

  // make sure pending changes are saved if user tries to leave the page
  useEffect(() => {
    return () => queueSave.flush();
  }, [queueSave]);

  if (!target || !type) {
    return null;
  }

  return <SelectTag multi value={selectedTags} onChange={updateTags} />;
};

export default SelectTagFieldForm;
