import styled from 'styled-components';

export const StyledError = styled.div`
  color: var(--negative-color);
  /* margin: 20px 0px; */
`;

export const LoadingWrapper = styled.div`
  display: flex;
  gap: 16px;
`;

export const InviteCodeSection = styled.div`
  flex: 1;
`;

export const FieldsWrapper = styled.div`
  display: flex;
  gap: 16px;
  flex-direction: column;
`;
export const ButtonWrapper = styled.div`
  display: flex;
  place-items: end;
`;

export const WelcomeEmailSection = styled.div`
  display: flex;
  gap: 16px;
  font-weight: 600;
`;
