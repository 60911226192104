import { gql } from '@apollo/client';

const pseudoVirtualHumanFragmentBase = `
  ID
  Created
  LastEdited
  AuthorID
  Author {
    ID
    Name
  }
  Title
  AdminTitle
  Description
  OrganisationID
  Status
  ThumbnailURL
  Thumbnail {
    ID
    URL
    TusID
  }
  Avatar {
    ID
    Name
    ImageMedia {
      ID
      URL
      TusID
    }
  }
  Voice
  VHVoiceID
  Views
  Likes
  Featured
  CoursesOnly
  DownloadVersion
  ExternalID
  APIEndpoint
  TotalUsers
  TotalCompletions
  Views
  AverageScore
  AverageDiagnosisScore
  Diagnosis
  TimeLimit
  DisableHelp
  Type
  EasyMode
  ImageMedia {
    ID
    URL
    TusID
  }
  ImageMediaID
  HideScoresFromUsers
  PublicAccess
  ExamMode
  CreationMethod
  ShareToken
  ShowRegister
`;

export const READ_VIRTUAL_HUMAN = gql`
  query readOneVirtualHuman($ID: ID!, $organisationID: ID!) {
    readOneVirtualHuman(filter: { ID: { eq: $ID } }) {
      ${pseudoVirtualHumanFragmentBase}
      Environment {
        ID
        Name
        Description
      }
      VHVoice {
        ID
        Locale
        Code
        Name
        Service
      }
      Editors {
        edges {
          node {
            ID
            Name
          }
        }
      }
      OrganisationGroups(filter: { Organisation: { ID: { eq: $organisationID}}	}	) {
        edges {
          node {
            ID
            Name
          }
        }
      }
      FeedbackFormBefore {
        ID
        Title
        Hash
      }
      FeedbackFormAfter {
        ID
        Title
        Hash
      }
      Tags {
        nodes {
          ID
          Name
        }
      }
    }
  }
`;
export const READ_VIRTUAL_HUMAN_WITH_USERS = gql`
  query readVirtualHumanUsers($ID: ID!, $limit: Int, $offset: Int) {
    readMembers(
      filter: { AnalyticsSubmissions: { VirtualHumanID: { eq: $ID } } }
      limit: $limit
      offset: $offset
    ) {
      pageInfo {
        totalCount
        hasNextPage
        hasPreviousPage
      }
      nodes {
        ID
        Name
        LastSeen
      }
    }
  }
`;

export const READ_VIRTUAL_HUMANS_PAGINATED = gql`

query readVirtualHumansPaginated(
  $limit: Int!,
  $offset: Int!,
  $filterBy: VirtualHumanFilterFields,
  $sortBy: VirtualHumanSortFields,
) {
  readVirtualHumans(
    offset: $offset
    limit: $limit
    filter: $filterBy
    sort: $sortBy
  ) {
    pageInfo {
      totalCount
      hasNextPage
      hasPreviousPage
    }
    edges {
      node {
        ${pseudoVirtualHumanFragmentBase}
      }
    }
  }
}

`;

export const READ_VIRTUAL_HUMAN_AVATARS = gql`
  {
    readAvatars {
      edges {
        node {
          ID
          Created
          LastEdited
          Name
          Age
          Gender
          ImageMedia {
            ID
            URL
            TusID
            Filename
          }
        }
      }
    }
  }
`;

export const EDIT_VIRTUAL_HUMAN = gql`
mutation updateVirtualHuman($Input: UpdateVirtualHumanInput!) {
  updateVirtualHuman(input: $Input) {
    ${pseudoVirtualHumanFragmentBase}
  }
}
`;

export const EDIT_VIRTUAL_HUMAN_NAME = gql`
  mutation updateVirtualHuman($Input: UpdateVirtualHumanInput!) {
    updateVirtualHuman(input: $Input) {
      ID
      Title
    }
  }
`;

export const EDIT_VIRTUAL_HUMAN_WITH_ENVIRONMENT_RESPONSE = gql`
  mutation updateVirtualHumanWithNestedResponse($Input: UpdateVirtualHumanInput!) {
    updateVirtualHuman(input: $Input) {
      ${pseudoVirtualHumanFragmentBase}
      Environment {
        ID
        Name
        Description
      }
    }
  }
`;

export const EDIT_VIRTUAL_HUMAN_WITH_GROUPS = gql`
mutation updateVirtualHumanGroups($GroupIDs: [ID!]!, $ID: ID!, $organisationID: ID!) {
  updateVirtualHumanGroups(GroupIDs: $GroupIDs, VirtualHumanID: $ID) {
    ${pseudoVirtualHumanFragmentBase}
    OrganisationGroups(filter: { Organisation: { ID: { eq: $organisationID}}	}	) {
      edges {
        node {
          ID
          Name
        }
      }
    }
  }
}`;

export const VIRTUAL_HUMAN_ANALYTICS = gql`
  query virtualHumanAnalytics($VirtualHumanID: ID!) {
    virtualHumanAnalytics(VirtualHumanID: $VirtualHumanID) {
      TotalViews
      TotalCompletions
      TotalTimeSpent
      AverageScore
      AverageTimeSpent
      DailyTotalViewsDays
      DailyTotalViewsViews
      DailyTotalViewsScores
      AverageConversationScore
    }
  }
`;

export const USER_VIRTUAL_HUMANS_ANALYTICS = gql`
  query virtualHumanUserAnalytics($UserID: ID!) {
    virtualHumanUserAnalytics(UserID: $UserID) {
      TotalViews
      TotalCompletions
      TotalTimeSpent
      AverageScore
      AverageTimeSpent
      DailyTotalViewsDays
      DailyTotalViewsViews
      DailyTotalViewsScores
      MostWatchedTitles
      MostWatchedViews
      SimulationScores {
        ID
        Type
        TimeTaken
        Time
        TimeSpent
        Finished
        MemberID
        PDAssessmentID
        PDDiagnosisScore
        PDConversationScore
        Diagnosis
        Created
        VirtualHuman {
          ID
          ExternalID
          Title
          Type
        }
        Member {
          ID
          Name
        }
      }
    }
  }
`;

export const VIRTUAL_HUMAN_ENVIRONMENTS = gql`
  query {
    readEnvironments {
      edges {
        node {
          ID
          Name
          Description
        }
      }
    }
  }
`;

export const SET_VH_PUBLIC = gql`
  mutation setPublic($virtualHumanID: ID!) {
    updateVirtualHuman(input: { ID: $virtualHumanID, PublicAccess: true }) {
      ID
    }
  }
`;
