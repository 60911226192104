import { useMutation } from '@apollo/client';
import { Icon } from '@virtidev/toolbox';
import { produce } from 'immer';
import React, { useCallback, useMemo, useRef, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import CustomisedInputCheckbox from '../../../../../../forms/CustomisedInputs/CustomisedInputCheckbox';
import CustomisedInputLikert from '../../../../../../forms/CustomisedInputs/CustomisedInputLikert';
import CustomisedInputRadio from '../../../../../../forms/CustomisedInputs/CustomisedInputRadio';
import CustomisedInputText from '../../../../../../forms/CustomisedInputs/CustomisedInputText';
import CustomisedInputTextarea from '../../../../../../forms/CustomisedInputs/CustomisedInputTextarea';
import LikertScaleV2 from '../../../../../../forms/CustomisedInputs/LikertScaleV2/LikertScaleV2';
import { READ_FEEDBACK_FORM } from '../../../../../../queries/FeedbackFormQueries';
import { useClickOutsideCallback } from '../../../../../../utility/EventHooks';
import { useAutosave } from '../../../helpers/useAutosave';
import QuestionActions from '../QuestionActions/QuestionActions';
import { DELETE_QUESTIONS, UPDATE_FEEDBACK_QUESTION } from './Question.query';
import {
  StyledDragHandle,
  StyledDragHandleWrapper,
  StyledDragLine,
  StyledQuestionWrapper,
} from './Question.styled';
import { QuestionDragBox } from './QuestionDragBox';

export default function QuestionV2({
  type,
  activePage,
  setLoading,
  formID,
  ...props
}) {
  const [editing, setEditing] = useState(props.placeholder);
  const [dragging, setDragging] = useState(false);
  const questionRef = useRef();

  useClickOutsideCallback(questionRef, () => {
    setTimeout(() => {
      setEditing(false);
    }, 100);
  });

  const actionButtonsRef = useRef();

  const handleClick = useCallback((e) => {
    setEditing(true);
  }, []);

  const questionValues = useMemo(() => {
    const newObject = produce(props.question, (state) => {
      delete state.Options;
      delete state.__typename;
    });

    return newObject;
  }, [props.question]);

  const formMethods = useForm({
    defaultValues: { ...questionValues },
  });
  const {
    register,
    getValues,
    setValue,
  } = formMethods;

  const [updateQuestion] = useMutation(UPDATE_FEEDBACK_QUESTION);

  const handleUpdate = useMemo(() => {
    return async (values) => {
      const parsedValues = produce(values, (state) => {
        state.CharacterLimit = parseInt(state.CharacterLimit);
        delete state.scaleType;
      });
      updateQuestion({
        variables: {
          input: { ...parsedValues, PageID: activePage?.ID },
        },
      });
    };
  }, [activePage?.ID, updateQuestion]);

  useAutosave(formMethods, handleUpdate, setLoading);

  const [deleteQuestions] = useMutation(DELETE_QUESTIONS, {
    onCompleted: () => setLoading(false),
  });
  const handleDelete = useCallback(() => {
    setLoading(true);
    deleteQuestions({
      variables: { ids: [props.question.ID] },
      optimisticResponse: {
        deleteQuestions: [],
      },
      update: (cache, { data }) => {
        const cacheData = cache.readQuery({
          query: READ_FEEDBACK_FORM,
          variables: {
            ID: formID,
          },
        });

        const newData = produce(cacheData, (state) => {
          if (state?.readOneFeedbackForm) {
            state.readOneFeedbackForm.Pages.nodes[
              activePage.SortOrder
            ].Questions.nodes = state.readOneFeedbackForm.Pages.nodes[
              activePage.SortOrder
            ].Questions.nodes.filter((question) => {
              return question.ID !== props.question.ID;
            });
          }
        });

        cache.writeQuery({
          query: READ_FEEDBACK_FORM,
          variables: {
            ID: formID,
          },
          data: newData,
        });
      },
    });
  }, [
    activePage?.SortOrder,
    deleteQuestions,
    formID,
    props?.question?.ID,
    setLoading,
  ]);

  const questionEditProps = {
    register,
    editing,
    setEditing,
    setLoading,
    setValue,
    getValues,
    ...props,
  };

  return (
    <div>
      <FormProvider {...formMethods}>
        {dragging && <StyledDragLine dragging={dragging}></StyledDragLine>}
        <QuestionDragBox
          {...props}
          setDragging={setDragging}
          setEditing={setEditing}
        >
          <StyledQuestionWrapper
            ref={questionRef}
            editing={editing}
            dragging={dragging}
            onClick={handleClick}
          >
            <StyledDragHandleWrapper>
              <StyledDragHandle id={`drag-handle-${props.question.ID}`}>
                <Icon icon="menu" color="var(--neutral-color-light)" />
              </StyledDragHandle>
            </StyledDragHandleWrapper>

            <div ref={actionButtonsRef} id="action-buttons-div">
              <QuestionActions
                question={props.question}
                setEditing={setEditing}
                onDelete={handleDelete}
                formID={formID}
                activePage={activePage}
                setLoading={setLoading}
              />
            </div>
            {type === 'text' && <CustomisedInputText {...questionEditProps} />}
            {type === 'textarea' && (
              <CustomisedInputTextarea {...questionEditProps} />
            )}
            {type === 'radiolist' && (
              <CustomisedInputRadio {...questionEditProps} />
            )}
            {type === 'likertscale' && (
              <CustomisedInputLikert {...questionEditProps} />
            )}

            {type === 'checkbox' && (
              <CustomisedInputCheckbox {...questionEditProps} />
            )}
            {type === 'likertscalev2' && (
              <LikertScaleV2 {...questionEditProps} />
            )}
          </StyledQuestionWrapper>
        </QuestionDragBox>
      </FormProvider>
    </div>
  );
}
