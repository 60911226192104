import { Button, Icon } from '@virtidev/toolbox';
import React, { useCallback, useState } from 'react';
import { CopyCodeButtons } from './SimEmbedCode.styled';
import tracker from '@core/helpers/tracker';
import useUser from '@core/helpers/useUser';

export default function SimEmbedCode({ token }) {
  const [copiedIframe, setCopiedIframe] = useState(false);
  const [copiedURL, setCopiedURL] = useState(false);
  const { Organisation } = useUser();
  const baseURL = window.location.origin;

  const handleClickCopyIframe = useCallback(() => {
    setCopiedIframe(true);
    tracker.track('share_iframe', {
      type: 'simulation',
      org_id: Organisation?.ID,
      org_name: Organisation?.Name,
      customer_stage: Organisation?.CustomerStage,
      token,
    });
    navigator.clipboard.writeText(
      `<iframe
      title="Virti-Player"
      src="${baseURL}/embed/my-simulations/${token}"
      id="VirtiPlayer"
      width="1140"
      height="642"
      ></iframe>`
    );
    setTimeout(() => {
      setCopiedIframe(false);
    }, 2000);
  }, [Organisation, token, baseURL]);

  const handleClickCopyURL = useCallback(() => {
    setCopiedURL(true);
    tracker.track('share_url', {
      type: 'simulation',
      org_id: Organisation?.ID,
      org_name: Organisation?.Name,
      customer_stage: Organisation?.CustomerStage,
      token,
    });
    navigator.clipboard.writeText(`${baseURL}/embed/my-simulations/${token}`);
    setTimeout(() => {
      setCopiedURL(false);
    }, 2000);
  }, [Organisation, token, baseURL]);

  return (
    <CopyCodeButtons>
      <Button
        color="secondary"
        icon={!copiedIframe ? 'link' : null}
        onClick={handleClickCopyIframe}
      >
        {!copiedIframe ? 'Copy Embed Code' : 'Copied!'}
      </Button>
      <Button
        color="primary"
        icon={!copiedURL ? 'link' : null}
        onClick={handleClickCopyURL}
      >
        {!copiedURL ? 'Copy URL' : 'Copied!'}
      </Button>
    </CopyCodeButtons>
  );
}
