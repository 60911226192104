import styled, { css } from 'styled-components';
import StyledLink from '@base/styled-components/StyledLink';
import LoadingPlaceholder from '@core/components/LoadingPlaceholder/LoadingPlaceholder';
import {
  MockButton,
  MockText,
} from '@core/components/LoadingPlaceholder/LoadingPlaceholder.styled';
import { Card, Button } from '@virtidev/toolbox';

export const StyledFormItem = styled(Card)`
  ${({ theme: { spacing } }) => css`
    margin: ${spacing.md} 0;
    padding: ${spacing.md} ${spacing.lg};
    position: relative;
    gap: ${spacing.md};
  `}
`;

export const StyledFormTitleLink = styled(StyledLink)`
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
`;

export const Titlebar = styled.div`
  ${({ theme: { spacing } }) => css`
    display: flex;
    flex-direction: row;
    align-items: stretch;
    gap: ${spacing.md};
  `}
`;

export const StyledAdminTitle = styled.span`
  font-size: 1rem;
  font-weight: 400;
  &:before {
    content: ' ';
  }
`;
export const StyledFormItemDetails = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 0.8rem;
  place-items: center start;
`;

export const StyledNoFormsMessage = styled.div`
  text-align: center;
  margin: auto;
  margin-top: 7rem;
  display: flex;
  gap: 2rem;
  flex-direction: column;
  align-items: center;
  font-size: 1.2rem;

  button {
    font-size: 1.2rem !important;
  }
`;

export const PlaceholderFeedbackFormListItem = () => (
  <LoadingPlaceholder>
    <div style={{ display: 'flex', width: '50%', flexDirection: 'column' }}>
      <MockText />
      <MockText style={{ maxWidth: '8rem' }} />
    </div>
    <div
      style={{
        display: 'flex',
        width: '50%',
        flexDirection: 'column',
      }}
    >
      <div
        style={{
          display: 'flex',
          width: '100%',
          flexDirection: 'row-reverse',
          gap: '5px',
        }}
      >
        <MockButton style={{ width: '45px' }} />
        <MockButton style={{ width: '45px' }} />
      </div>
      <div style={{ display: 'flex', width: '100%', gap: '2rem' }}>
        <MockText />
        <MockText />
      </div>
    </div>
  </LoadingPlaceholder>
);
