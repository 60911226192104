import { TextInput } from '@virtidev/toolbox';
import styled from 'styled-components';

export const Searchbox = styled(TextInput)`
  && {
    min-width: 220px;
  }
`;

// z index of the menu went under the corner controls of the cards
// is there a better way to do this?
export const StyledSelectWrapper = styled.div`
  width: 280px;
  z-index: 1002;
`;
