import { Datetime, Tooltip } from '@virtidev/toolbox';
import { Chevron, Pill } from './HeaderPill.styled';

export const HeaderPill = ({ lastEdited, author }) => {
  return (
    <Tooltip
      type="menu"
      icon={false}
      placement="bottom-end"
      iconContent={
        <Pill>
          <div>
            Last Edited: <Datetime datetime={lastEdited} long noTimeDisplay />
          </div>
          <Chevron />
        </Pill>
      }
    >
      <div>
        Last Edited:{' '}
        <Datetime datetime={lastEdited} long noTimeDisplay emptyDisplay="-" />
      </div>
      <div>Created By: {author}</div>
    </Tooltip>
  );
};

export default HeaderPill;
