import { Datetime } from '@virtidev/toolbox';
import { Container } from '@core/components/Simulation/LastEditedIndicator/LastEditedIndicator.styled';

export const LastEditedIndicator = ({ lastEdited, author }) => {
  return (
    <Container>
      <div>
        Last Edited: <Datetime datetime={lastEdited} long />
      </div>
      <div>Created By: {author}</div>
    </Container>
  );
};

export default LastEditedIndicator;
