import { gql, useMutation } from '@apollo/client';
import { Loading, Icon, ToggleSwitch } from '@virtidev/toolbox';
import { useCallback, FC } from 'react';
import styled, { css } from 'styled-components';

/**
 * @typedef {import('../apps/core/src/models/video-media.types').VideoMedia} VideoMedia
 */

const TOGGLE_360 = gql`
  mutation toggle360($Input: UpdateVideoMediaInput!) {
    updateVideoMedia(input: $Input) {
      ID
      Content360
    }
  }
`;

const Wrapper = styled.label`
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 8px;
  border: 0;
  background: none;
  border-radius: 8px;
  font-size: 1rem;
  padding: 0.8rem 0.5rem;
  cursor: pointer;

  &:hover,
  &:focus,
  &:active {
    background: #ebf8ff;
  }
`;

const TypeIcon = styled(Icon)`
  ${({ theme, active }) => css`
    transition: color 0.3s;
    color: ${active ? theme.color.primary : theme.color.lightGrey};
  `}
`;

const Spinner = styled(Loading)`
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
`;

/**
 * @type {FC<{ file?: VideoMedia}>}
 */
export const FilesListTypeButton = ({ file }) => {
  const { ID, Content360 } = file || {};

  const [toggle360, { loading }] = useMutation(TOGGLE_360, {
    notifyOnNetworkStatusChange: true,
  });

  const updateContent360 = useCallback(
    (event) => {
      if (!ID || ID === '0') {
        return;
      }
      toggle360({
        variables: {
          Input: {
            ID,
            Content360: !event.target.checked,
          },
        },
        optimisticResponse: {
          updateVideoMedia: {
            ID,
            Content360: !event.target.checked,
            __typename: 'VideoMedia',
          },
        },
      });
    },
    [ID, toggle360]
  );

  return (
    <Wrapper>
      <TypeIcon icon="video-3d" active={Content360} size="1.5rem" />
      <ToggleSwitch checked={!Content360} onChange={updateContent360} />
      <TypeIcon icon="video-2d" active={!Content360} size="1.5rem" />
      {loading && <Spinner />}
    </Wrapper>
  );
};

export default FilesListTypeButton;
