import { Icon } from '@virtidev/toolbox';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useFlashMessage } from '../../../FlashMessage';
import Label from '../../../form/Label';
import ExpandIcon from './ExpandIcon';
import {
  ExpandWrapper,
  FailResult,
  ResultsBox,
  SuccessResult,
} from './Results.styled';

export default function Results({ results }) {
  const [expand, setExpand] = useState(false);

  const { addFlashMessage } = useFlashMessage();

  useEffect(() => {
    if (!results) {
      return;
    }
    for (let i = 0; i < results.length; i++) {
      if (!results[i].Created) {
        addFlashMessage('One or more members could not be added.', 'error');
        break;
      }
      if (i === results.length - 1) {
        addFlashMessage('All users added successfully!');
      }
    }
    // eslint-disable-next-line
  }, [results]);

  const handleExpand = useCallback(() => {
    setExpand(!expand);
  }, [expand]);

  const summaryMsg = useMemo(() => {
    if (!results) {
      return;
    }

    let addedCount = 0;
    let notAddedCount = 0;

    results.forEach((res) => {
      if (res.Created === true) {
        addedCount++;
      } else {
        notAddedCount++;
      }
    });

    return (
      <div>
        {notAddedCount > 0 && (
          <FailResult>
            <Icon icon="alert" color="var(--negative-color)" /> {notAddedCount}{' '}
            member{notAddedCount > 1 && 's'} could not be added.{' '}
          </FailResult>
        )}
        {addedCount > 0 && (
          <SuccessResult>
            <Icon icon="success" color="var(--positive-color)" /> {addedCount}{' '}
            member{addedCount > 1 && 's'} added successfully.
          </SuccessResult>
        )}
        <ExpandWrapper>
          {expand ? 'Hide' : 'Expand'} results{' '}
          <ExpandIcon expand={expand} handleExpand={handleExpand} />
        </ExpandWrapper>
      </div>
    );
  }, [results, expand, handleExpand]);

  return (
    <>
      <Label horizontal>Results</Label>
      {summaryMsg}
      {expand && (
        <ResultsBox>
          {results &&
            results.map((res) => {
              if (!res.Created) {
                return (
                  <FailResult>
                    <Icon icon="alert" /> {res.Member.Email} could not be added:{' '}
                    {res.Message}.
                  </FailResult>
                );
              } else {
                return (
                  <SuccessResult>
                    <Icon icon="success" /> {res.Member.Email} was added
                    successfully!
                  </SuccessResult>
                );
              }
            })}
        </ResultsBox>
      )}
    </>
  );
}
