import { TextInput, Card } from '@virtidev/toolbox';
import styled from 'styled-components';

export const TableWrapper = styled(Card)`
  padding: 20px 30px;
`;

export const PaginationControlWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-top: 15px;
`;

export const FiltersWrapper = styled.div`
  display: flex;
  gap: 20px;
  padding: 10px 0 20px 0;
  justify-content: flex-end;
`;

export const StyledTextInput = styled(TextInput)`
  max-width: 300px;
`;
