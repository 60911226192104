import { gql } from '@apollo/client';

export const CREATE_VH = gql`
  mutation copyVirtualHuman(
    $VirtualHumanID: ID!
    $OrganisationID: ID!
    $Title: String!
    $AdminTitle: String
    $Locale: String
    $VHVoiceID: ID
    $CreationMethod: String
  ) {
    copyVirtualHuman(
      VirtualHumanID: $VirtualHumanID
      OrganisationID: $OrganisationID
      Title: $Title
      AdminTitle: $AdminTitle
      Locale: $Locale
      VHVoiceID: $VHVoiceID
      CreationMethod: $CreationMethod
    ) {
      ID
      Title
      ExternalID
    }
  }
`;

export const READ_TEMPLATES = gql`
  query readVirtualHumanTemplates {
    readVirtualHumanTemplates {
      edges {
        node {
          ID
          Title
          VirtualHuman {
            ID
            Created
            LastEdited
            Title
            Description
            OrganisationID
            Status
            ThumbnailURL
            Avatar {
              ID
              Name
            }
            Voice
            Featured
            CoursesOnly
            ExternalID
            Type
          }
        }
      }
    }
  }
`;
