import React from 'react';
import styled from 'styled-components';
import { Mutation } from '@apollo/client/react/components';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { UPDATE_USER_TYPE } from '../../queries/GroupQueries';
import StyledLabel from '../../styled-components/StyledLabel';
import {
  READ_MEMBER_WITH_BASIC_GROUP_DATA,
  REMOVE_MEMBER_FROM_GROUP,
} from '../../queries/UserQueries';
import withFlashMessaging from '../../HOCs/WithFlashMessaging';
import ScrollableGroupCheckboxList from '../../components/ScrollableGroupCheckboxList';
import StyledSelect from '../../styled-components/StyledSelect';
import {
  CanSeeUserTypeInput,
  CanEditAdminStatus,
  CanEditSuperAdminStatus,
  CanEditContentCreatorStatus,
} from '../../utility/Permissions';
import withUser from '../../HOCs/WithUser';
import { produce } from 'immer';
import { ADD_MEMBER_TO_GROUP } from './GroupCheckboxesForUser.query';

const StyledGroupsInputWrapper = styled.div`
  padding: 1rem;
  border-radius: var(--card-border-radius);
  background-color: var(--card-bg-color);
  box-shadow: var(--card-box-shadow);
`;

const StyledAdminSetSection = styled.div`
  margin-top: 2rem;
`;

class GroupCheckboxesForUser extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mutating: false,
      userType: props.user ? props.user.UserType : null,
      isUser: true,
    };
  }
  componentDidMount = () => {
    if (typeof this.props.userID === 'undefined' || !this.props.userID) {
      this.setState({
        isUser: false,
      });
    }
  };
  _startMutating = () => {
    this.setState({ mutating: true });
  };
  _finishMutating = () => {
    this.setState({ mutating: false });
  };

  _startMutating = () => this.setState({ mutating: true });
  _finishMutating = () => this.setState({ mutating: false });

  render() {
    return (
      <div>
        <StyledGroupsInputWrapper>
          <ScrollableGroupCheckboxList
            mainObjectID={this.props.userID}
            addMutation={ADD_MEMBER_TO_GROUP}
            removeMutation={REMOVE_MEMBER_FROM_GROUP}
            type="member"
            mutationVariables={{
              OrganisationID: this.props.userOrganisationID,
            }}
            checkedGroupIDs={this.props.checkedGroupIDs}
            objectNotYetExists={
              typeof this.props.userID === 'undefined' || !this.props.userID
            }
            apolloUpdateAfterRemove={(cache, removeMutationData, args) => {
              const deletedID = args.variables.GroupID;
              const userID = removeMutationData.data.removeMemberFromGroup.ID;

              const variables = {
                ID: userID,
                organisationID: this.props.userOrganisationID,
              };

              const data = cache.readQuery({
                query: READ_MEMBER_WITH_BASIC_GROUP_DATA,
                variables,
              });

              const newData = produce(data, (state) => {
                state.readOneMember.OrganisationGroups.edges =
                  state.readOneMember.OrganisationGroups.edges.filter(
                    (edge) => edge.node.ID !== deletedID
                  );
              });
              cache.writeQuery({
                query: READ_MEMBER_WITH_BASIC_GROUP_DATA,
                variables,
                data: newData,
              });
            }}
            apolloUpdateAfterAdd={(cache, addMutationData) => {
              const userID = addMutationData.data.addMemberToGroup.ID;

              const variables = {
                ID: userID,
                organisationID: this.props.userOrganisationID,
              };

              const data = cache.readQuery({
                query: READ_MEMBER_WITH_BASIC_GROUP_DATA,
                variables,
              });

              const newData = produce(data, (state) => {
                state.readOneMember.OrganisationGroups.edges =
                  addMutationData.data.addMemberToGroup.OrganisationGroups.edges;
              });
              cache.writeQuery({
                query: READ_MEMBER_WITH_BASIC_GROUP_DATA,
                variables,
                data: newData,
              });
            }}
          />
          <Mutation
            onCompleted={(data) => {
              // temporary until back-end return value is fixed to correctly update
              this.props.addFlashMessage(
                "Successfully updated member's user type",
                'success'
              );
              this._finishMutating();
            }}
            onError={() => {
              this.props.addFlashMessage(
                "Failed to update member's user type",
                'error'
              );
              this._finishMutating();
            }}
            mutation={UPDATE_USER_TYPE}
          >
            {(updateUserType) => {
              return !this.state.isUser ? null : (
                <>
                  {this.props.user &&
                    CanSeeUserTypeInput(this.props.user.UserType) && (
                      <StyledAdminSetSection>
                        <StyledLabel
                          for="set-user-admin"
                          loading={this.state.mutating}
                        >
                          User Type
                        </StyledLabel>
                        <StyledSelect
                          value={this.state.userType}
                          loading={this.state.mutating}
                          htmlID="set-user-admin"
                          name="set-user-admin"
                          onChange={(e) => {
                            this._startMutating();
                            this.setState({
                              mutating: true,
                              userType: e.target.value,
                            });
                            const vars = {
                              variables: {
                                MemberID: this.props.userID,
                                UserType: e.target.value,
                              },
                            };
                            updateUserType(vars);
                          }}
                          disabled={
                            this.state.mutating ||
                            this.props.user.ID === this.props.loggedUserID ||
                            (!CanEditContentCreatorStatus() &&
                              this.state.userType === 'contentcreator') ||
                            (!CanEditAdminStatus() &&
                              this.state.userType === 'admin') ||
                            (!CanEditSuperAdminStatus() &&
                              this.state.userType === 'super')
                          }
                        >
                          <option value="user">User</option>
                          <option
                            disabled={!CanEditContentCreatorStatus()}
                            value="contentcreator"
                          >
                            Content Creator
                          </option>
                          <option
                            disabled={!CanEditAdminStatus()}
                            value="admin"
                          >
                            Admin
                          </option>
                          {/* <option
                              // disabled={!CanEditSuperAdminStatus()}
                              value="super"
                            >
                              Super Admin
                            </option> */}
                        </StyledSelect>
                      </StyledAdminSetSection>
                    )}
                </>
              );
            }}
          </Mutation>
        </StyledGroupsInputWrapper>
      </div>
    );
  }
}

GroupCheckboxesForUser.defaultProps = {
  userID: null,
  isAdmin: null,
};

GroupCheckboxesForUser.propTypes = {
  checkedGroupIDs: PropTypes.array.isRequired,
  userID: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  user: PropTypes.object,
  loggedUserType: PropTypes.string.isRequired,
  loggedUserID: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  userOrganisationID: PropTypes.number.isRequired,
};

export default withUser(withFlashMessaging(GroupCheckboxesForUser));
