import React, { FC } from 'react';
import PropTypes from 'prop-types';
import styled, { DefaultTheme, css } from "styled-components";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Card } from '@virtidev/toolbox';

export const StyledPageList = styled.div`
  width: 100%;
`;

export const StyledPageListHeaders = styled.div`
  display: grid;
  grid-template-columns: 40% 12% 33% 15%;
  padding: 1rem;
`;
export const StyledPageListHeader = styled.div`
  ${
  /**
   * @param {{ theme: DefaultTheme, align?: string }} p
   */
  ({ theme, align }) => css`
    text-align: center;
    color: ${theme.color.darkGrey};
    font-size: 0.8rem;
    text-transform: uppercase;
    ${align === 'left' && css`
      text-align: left;
    `}
  `}
`;

export const StyledPageListItem = styled(Card)`
  display: grid;
  margin-bottom: .3rem;
  background: #FFF;
  min-height: 0;
  min-width: 0;
  word-wrap: break-word;
  word-break: break-word;
  padding: 0.5rem 1rem;
`;

export const StyledPageListData = styled.div`
  text-align: center;
  font-size: 0.9rem;
  font-weight: ${
    /**
     * @param {{ bold?: boolean }} p
     */
    ({ bold }) => bold ? '500' : 'initial'
  };
`;

export const StyledData = styled.div`
  ${
    /**
     * @param {{ bold?: boolean, $center?: boolean }} p
     */
    ({ $center }) => $center && css`text-align:center;`
  }
  position: relative;
  margin: 0.1rem;
  font-weight: ${({ bold }) => bold ? '500' : 'initial'};
  display: flex;
  justify-content: center;
  font-size: 0.85rem;
  align-items: center;
`;
export const StyledDetailsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 0.8rem;
  justify-content: center;
  ${props => props.vAlign === 'top' && css`
    justify-content: flex-start;
  `}
  ${props => props.align === 'left' && css`
    margin-right: auto;
    align-items: flex-start;
  `}
`;



export const StyledPageListHeaderNoRow = styled(StyledPageListHeader)`
  padding: 1rem;
`;
export const StyledPageListDataNoRow = styled(StyledPageListData)`
  ${props =>
    props.isLast &&
    css`
      border-bottom: var(--primary-border);
    `}
  min-height: 0;
  min-width: 0;
  word-wrap: break-word;
  word-break: break-word;
  padding: 0.5rem 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledDataLabelledThumb = styled(StyledPageListDataNoRow)`
  justify-content: flex-start;
  padding: 1rem;
  ${props => props.isVideo && css`
    &:hover {
      opacity: 0.8;
      .thumbnailPlayIcon {
        display: block;
      }
    }
  `}
`

const StyledThumbWrapper = styled.div`
  position: relative;
  display: grid;
  justify-content: center;
  align-items: center;
  margin-right: 1.5rem;
  height: 4rem;
  width: calc(4rem / 9 * 16);
  grid-template-columns: 8rem 1fr;

  border-radius: 8px;
  background: ${({ $fallback }) => $fallback ? '#fff' : '#000'};

`

const StyledThumb = styled.img`
  margin-left: auto;
  margin-right: auto;
  border-radius: 8px;
  height: 100%;
  max-width: 100%;
  overflow: hidden;
`

const StyledThumbLabel = styled.div`
${({ theme }) => css`
  display: -webkit-box;
  position: relative;
  flex: 1;
  text-align: left;
  font-weight: 500;
  max-height: calc(2.5 * ${theme.fontSize.md} * ${theme.lineHeight} + 3px);
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  `}
`

const StyledPlayIconWrapper = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto;
  background-color: #444;
  border-radius: 50px;
  height: 2rem;
  width: 2rem;
  display: none;
`
const StyledPlayIcon = styled(FontAwesomeIcon)`
  width: 50%;
  height: 50%;
  color: #FFF;
  margin: 25% auto 0;
`

/**
 * @type {FC<{
 *    isVideo?: boolean,
 *    src: string,
 *    fallback?: boolean,
 * }>}
 */
export const DataLabelledThumb = ({ isVideo, src, fallback, children }) => (
  <StyledDataLabelledThumb isVideo={isVideo}>
    <StyledThumbWrapper $fallback={fallback}>
      <StyledThumb src={src} />
      {isVideo && (
        <StyledPlayIconWrapper className="thumbnailPlayIcon">
          <StyledPlayIcon icon="play" />
        </StyledPlayIconWrapper>
      )}
    </StyledThumbWrapper>
    <StyledThumbLabel title={children}>{children}</StyledThumbLabel>
  </StyledDataLabelledThumb>
)

DataLabelledThumb.propTypes = {
  src: PropTypes.string,
  isVideo: PropTypes.bool,
};
