import PropTypes from 'prop-types';

import { ADD_SIMULATION } from '../../queries/SimulationQueries';
import withFlashMessaging from '../../HOCs/WithFlashMessaging';
import { EllipsisMenuButton } from '../EllipsisMenu';
import Icon from '../icons/Icon';
import tracker from '../../apps/core/src/helpers/tracker';
import { useMutation } from '@apollo/client';
import { useHistory } from 'react-router-dom';

const CreateSimulationFromVideoButtonInMenu = (props) => {
  const history = useHistory();
  const [createSimulation, { loading }] = useMutation(ADD_SIMULATION, {
    variables: {
      VideoID: props.videoID,
    },
    update: (cache) => {
      // evict instead of refetchQueries since we're redirecting away from page
      cache.evict({ fieldName: 'readSimulations' });
      cache.evict({ fieldName: 'readVideoMedias' });
    },
    onError: (err) => {
      console.error(err);
      props.addFlashMessage(
        'There was an error creating the simulation',
        'error'
      );
    },
    onCompleted: (data) => {
      const simId = data?.createSimulationFromVideoMedia?.ID;
      if (simId) {
        props.addFlashMessage('Simulation created', 'success');
        tracker.track('simulation_created', {
          simulation_id: simId,
        });
        history.push(`/simulations/${simId}`);
      }
    },
  });

  return (
    <EllipsisMenuButton loading={loading} onClick={createSimulation}>
      <Icon
        customWidth="1.3rem"
        customHeight="1.3rem"
        name="simulations"
        type="redesign"
      />{' '}
      Create New Simulation
    </EllipsisMenuButton>
  );
};

CreateSimulationFromVideoButtonInMenu.propTypes = {
  videoID: PropTypes.string,
  addFlashMessage: PropTypes.func.isRequired,
};

export default withFlashMessaging(CreateSimulationFromVideoButtonInMenu);
