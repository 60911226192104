import styled from 'styled-components';

export const SimulationContainer = styled.div`
  margin: 1rem 0rem;
  border-radius: 12px;
  border: 1px solid #c3c3c3;
  padding: 0.8rem;
  display: flex;
  gap: 0.7rem;
  cursor: ${(props) => (props.dragging ? 'grabbing' : 'grab')};
  &:hover {
    background: ${(props) =>
      props.dragging
        ? 'var(--neutral-color-lighter)'
        : 'var(--primary-color-lighter)'};
  }
`;

export const SimulationTitle = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: flex-start;
  word-break: break-word;
  font-size: 0.8rem;
  width: 100%;
  max-height: 70px;
  overflow: hidden;
`;

export const SimulationAdminTitle = styled.span`
  color: var(--neutral-color);
`;

export const ImageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 70px;
  max-height: 70px;
  min-width: 120px;
  width: 120px;
  max-width: 120px;
  border-radius: 8px;
  overflow: hidden;
`;
