import { gql } from '@apollo/client';

export const READ_VIDEOS = gql`
  query readAssetSelectModal(
    $limit: Int
    $offset: Int
    $sort: VideoMediaSortFields
    $filter: VideoMediaFilterFields
  ) {
    readVideoMedias(
      offset: $offset
      limit: $limit
      sort: $sort
      filter: $filter
    ) {
      pageInfo {
        totalCount
        hasNextPage
        hasPreviousPage
      }
      edges {
        node {
          ID
          PosterURL
          TranscodingStatus
          EditorURL
          SourceURL
          Title
          Length
          Content360
        }
      }
    }
  }
`;
