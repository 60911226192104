import { useMutation } from '@apollo/client';
import { Button, TextInput } from '@virtidev/toolbox';
import { produce } from 'immer';
import { useEffect, useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import useUser from '../../../../../helpers/useUser';
import { READ_TAGS } from '../../../../form/Select/components/SelectTag/SelectTag.query';
import { CREATE_TAG } from '../../CardTooltipTags/CardTooltipTags.query';
import { CreateTagForm } from '../../CardTooltipTags/CardTooltipTags.styled';
import { zodResolver } from '@hookform/resolvers/zod';
import { createTagSchema } from '@core/components/DataListCard/components/CardTooltipTags/helpers/create-tag.schema';
import FieldError from '@core/components/form/FieldError';

export const CreateTag = ({ refetch, updateTags, setLoading }) => {
  const { OrganisationID } = useUser();
  const formMethods = useForm({
    resolver: zodResolver(createTagSchema),
  });

  const { register, handleSubmit: submit, reset } = formMethods;

  const [createTag, { loading: createTagLoading }] = useMutation(CREATE_TAG, {
    notifyOnNetworkStatusChange: true,
    update: (cache, { data }) => {
      const result = cache.readQuery({
        query: READ_TAGS,
        variables: {
          name: '',
          orgId: OrganisationID,
        },
      });

      if (!result?.readContentTags?.nodes) {
        return;
      }

      const newData = produce(result, (draft) => {
        draft.readContentTags.nodes = [
          ...draft.readContentTags.nodes,
          data.createContentTag,
        ];
      });

      cache.writeQuery({
        query: READ_TAGS,
        variables: {
          name: '',
          orgId: OrganisationID,
        },
        data: newData,
      });
    },
  });

  const createNewTag = useMemo(
    () =>
      submit(async (input, e) => {
        e && e.preventDefault();
        if (!OrganisationID) {
          return;
        }

        console.log(input);

        reset();

        const result = await createTag({
          variables: {
            input: {
              ...input,
              OrganisationID,
            },
          },
          optimisticResponse: {
            createContentTag: {
              ID: 'new-tag',
              ...input,
              OrganisationID,
              __typename: 'ContentTag',
            },
          },
        });
        const tag = result?.data?.createContentTag;

        refetch();

        if (tag?.ID) {
          updateTags((selected) => {
            const newSelected = [...selected, tag.ID];

            return newSelected;
          });
        }
      }),
    [createTag, submit, OrganisationID, refetch, updateTags, reset]
  );

  useEffect(() => {
    setLoading(createTagLoading);
  }, [createTagLoading, setLoading]);

  return (
    <FormProvider {...formMethods}>
      <CreateTagForm onSubmit={createNewTag}>
        <TextInput
          {...register('Name')}
          placeholder="Create new tag..."
          disabled={createTagLoading}
          suffix={
            <Button
              type="submit"
              color="turquoise"
              disabled={createTagLoading}
              loading={createTagLoading}
              size="small"
              icon="plus"
            />
          }
        />
        <FieldError name="Name" />
      </CreateTagForm>
    </FormProvider>
  );
};

export default CreateTag;
