import { Icon, Modal } from '@virtidev/toolbox';
import styled, { css } from 'styled-components';

export const CreateWithAiModal = styled(Modal)`
  ${({ $isWelcomeStep }) =>
    $isWelcomeStep &&
    css`
      .modal-header {
        height: 0;
      }
      .modal-content {
        padding-top: 0;
      }
    `}
`;

export const FormWrapper = styled.div`
  &,
  form {
    display: flex;
    flex-direction: column;
    gap: ${({ theme }) => theme.spacing.md};
  }
`;

export const BetaIcon = styled(Icon).attrs({
  size: '40px',
  icon: 'beta',
})`
  color: ${({ theme }) => theme.color.secondary};
  height: 20px;
  overflow: hidden;
  position: relative;
  top: 3px;
  margin-right: 2px;
`;

export const FieldWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

export const FieldError = styled.label`
  color: var(--negative-color);
  font-size: ${({ theme }) => theme.fontSize.xs};
  padding-top: 2px;
  margin-left: auto;
`;

export const LabelWrapper = styled.div`
  display: flex;
  // justify-content: space-between;
  align-items: center;
  gap: ${({ theme }) => theme.spacing.sm};
`;

export const Label = styled.label`
  font-size: 1rem;
  font-weight: 500;
`;

export const FieldGroup = styled.fieldset`
  margin: ${({ theme }) => theme.spacing.sm} 0;
  padding: 15px;
  border-radius: ${({ theme }) => theme.borderRadius.lg};
  border-top-left-radius: ${({ theme }) => theme.borderRadius.sm};
  border: 1px solid ${({ theme }) => theme.color.lightGrey};
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.md};
  position: relative;
`;

export const FieldGroupLegend = styled.legend`
  all: unset;
  padding-inline: ${({ theme }) => theme.spacing.sm};
  margin-inline: ${({ theme }) => `-${theme.spacing.sm}`};
  font-size: ${({ theme }) => theme.fontSize.xs};
  font-weight: 500;
  color: ${({ theme }) => theme.color.darkGrey};
  text-transform: uppercase;
`;

export const PersonalitySliders = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: ${({ theme }) => theme.spacing.md};
`;

export const CreatedVHCard = styled.div`
  border-radius: ${({ theme }) => theme.borderRadius.md};
  max-width: 420px;
  background-color: var(--card-bg-color);
  box-shadow: var(--card-box-shadow);
  overflow: hidden;
  margin: ${({ theme }) => `${theme.spacing.md} auto`};
`;

export const CreatedVHDetails = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${({ theme }) => theme.spacing.sm};
`;

export const CreatedVHTitle = styled.h3`
  padding: ${({ theme }) => theme.spacing.sm};
  font-family: ${({ theme }) => theme.fontFamily};
  font-size: ${({ theme }) => theme.fontSize.xs};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
`;

export const ImageLink = styled.a`
  display: block;
  aspect-ratio: 16 / 9;
  &:hover {
    opacity: var(--image-link-opacity-hover);
  }
  &:active {
    opacity: var(--image-link-opacity-active);
  }
`;

export const Text = styled.p`
  font-size: ${({ theme }) => theme.fontSize.md};
  color: ${({ theme }) => theme.color.midnight10};
`;

export const LoadingWrapper = styled.div`
  display: grid;
  gap: 1rem;
  grid-template-columns: 1fr;
  text-align: center;
`;

export const ErrorText = styled(Text)`
  color: ${({ theme }) => theme.color.error};
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 1rem;
`;

export const Link = styled.a`
  color: ${(props) => props.theme.color.primary};
  font-weight: ${(props) => props.theme.fontWeight.bold};
  &:visited {
    color: ${(props) => props.theme.color.primaryLight};
  }
  &:hover {
    text-decoration: underline;
  }
  &:active {
    color: ${(props) => props.theme.color.primaryDark};
  }
`;

export const WelcomeModalHeading = styled.h2`
  color: ${({ theme }) => theme.color.midnight};
  font-size: ${({ theme }) => theme.fontSize.lg};
  font-weight: ${(props) => props.theme.fontWeight.bold};
  ${(props) => props.theme.font.h2};
`;
export const WelcomeModalSubHeading = styled.h3`
  color: ${({ theme }) => theme.color.midnight};
  ${(props) => props.theme.font.label};
`;
export const WelcomeModal = styled.div`
  display: flex;
`;
export const WelcomeModalImages = styled.div`
  margin: auto;
  width: 85%;
  position: relative;
  padding-left: 5rem;
  padding-right: 2rem;
  padding-top: 2rem;
`;
export const WelcomeModalFooter = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 1rem 0.4rem 1rem;
  color: ${({ theme }) => theme.color.darkGrey};
  gap: 1rem;
`;
export const WelcomeModalContentSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;
