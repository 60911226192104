import { useMutation, useQuery } from '@apollo/client';
import {
  Button,
  CreateSelect,
  LoadingPlaceholder,
  Modal,
  Select,
  ToggleSwitch,
} from '@virtidev/toolbox';
import React, { FC, useCallback, useRef, useState } from 'react';
import { ButtonWrapper } from '@base/components/modals/ConfirmationModal.styled';
import { READ_ORGANISATION_LICENSES } from '@base/queries/OrganisationQueries';
import useUser from '@core/helpers/useUser';
import { useFlashMessage } from '@core/components/FlashMessage';
import Label from '@core/components/form/Label';
import { SelectGroup } from '@core/components/form/Select';
import { StyledPlaceholderFill } from '@core/components/Signup/legacy/GenerateInviteCodes/GenerateInviteCodes.styled';
import OrganisationInviteCodes from '@core/components/Signup/legacy/OrganisationInviteCodes';
import {
  BULK_CREATE_USERS,
  READ_ORGANISATION_INVITE_CODES,
} from './BulkInviteModal.query';
import {
  FieldsWrapper,
  InviteCodeSection,
  LoadingWrapper,
  StyledError,
  WelcomeEmailSection,
} from './BulkInviteModal.styled';
import Results from './Results/Results';
import _ from 'lodash';

/**
 * @type {FC<{show: boolean; onHide: () => void;}>}
 */
const BulkInviteModal = ({ show, onHide }) => {
  const [values, setValues] = useState([]);
  const [emailInput, setEmailInput] = useState('');
  const [type, setType] = useState({ value: 'user', label: 'User' });
  const [group, setGroup] = useState(null);
  const [sendEmail, setSendEmail] = useState(true);
  const [error, setError] = useState('');
  const [results, setResults] = useState(null);

  const valuesRef = useRef(values);
  valuesRef.current = values;

  const { OrganisationID } = useUser();

  const handleHide = useCallback(() => {
    setError('');
    setResults(null);
    setGroup(null);
    setType({ value: 'user', label: 'User' });
    onHide();
  }, [onHide]);

  const { data, loading } = useQuery(READ_ORGANISATION_INVITE_CODES, {
    variables: {
      ID: OrganisationID,
    },
    skip: !show,
  });

  const { addFlashMessage } = useFlashMessage();

  const [bulkCreateUsers, { loading: loadingCreate }] = useMutation(
    BULK_CREATE_USERS,
    {
      onCompleted: (data) => {
        setResults(data.bulkCreateUsers);
      },
      onError: (err) => {
        addFlashMessage(
          'Something went wrong, users could not be added.',
          'error'
        );
        console.error(err);
      },
      update: (cache) => {
        cache.evict({ fieldName: 'readMembers' });
      },
      refetchQueries: [READ_ORGANISATION_LICENSES],
    }
  );

  // create a handle click
  const handleSubmit = useCallback(async () => {
    await new Promise((resolve) => setTimeout(resolve, 10));

    const variables = {
      OrganisationID,
      Emails: valuesRef.current,
      SendWelcomeEmail: sendEmail,
      UserType: type.value,
    };

    if (group) {
      variables.GroupID = group.value;
    }

    setValues([]);

    await bulkCreateUsers({
      variables,
    });
  }, [OrganisationID, bulkCreateUsers, type, group, sendEmail]);

  return (
    <>
      <Modal
        title="Invite teammates to join your organisation."
        show={show}
        onHide={handleHide}
        render={() => (
          <>
            <FieldsWrapper>
              <div>
                <Label>User Emails</Label>
                <CreateSelect
                  placeholder="Enter email addresses to add..."
                  value={values}
                  onChange={setValues}
                  onInputChange={setEmailInput}
                  setError={setError}
                  isEmailList
                />
              </div>
              {error && <StyledError>{error}</StyledError>}
              <div>
                <Label>User Type</Label>
                <Select
                  value={type}
                  onChange={(e) => setType(e)}
                  options={[
                    { value: 'admin', label: 'Admin' },
                    { value: 'user', label: 'User' },
                    { value: 'contentcreator', label: 'Content Creator' },
                  ]}
                />
              </div>

              <div>
                <Label>Add to Group (optional)</Label>
                <SelectGroup
                  value={group}
                  creatable
                  onChange={(group) => setGroup(group)}
                />
              </div>
              <WelcomeEmailSection>
                Send Welcome Email
                <ToggleSwitch
                  checked={sendEmail}
                  onChange={() => setSendEmail(!sendEmail)}
                />
              </WelcomeEmailSection>
            </FieldsWrapper>
            <ButtonWrapper>
              <Button
                disabled={
                  loadingCreate ||
                  error ||
                  (values?.length === 0 && emailInput.length === 0)
                }
                onClick={handleSubmit}
              >
                Send Invites
              </Button>
            </ButtonWrapper>
            {results && (
              <div>
                <Results results={results} />
              </div>
            )}
          </>
        )}
        footerRender={() => (
          <InviteCodeSection>
            {loading && (
              <LoadingWrapper>
                <LoadingPlaceholder>
                  <StyledPlaceholderFill />
                </LoadingPlaceholder>
                <LoadingPlaceholder>
                  <StyledPlaceholderFill />
                </LoadingPlaceholder>
              </LoadingWrapper>
            )}
            {!loading && (
              <OrganisationInviteCodes
                inviteCodeAdmin={data?.readOneOrganisation?.InviteCodeAdmin}
                inviteCodeUser={data?.readOneOrganisation?.InviteCodeUser}
                loading={loading}
              />
            )}
          </InviteCodeSection>
        )}
      />
    </>
  );
};

export default BulkInviteModal;
