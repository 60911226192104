import React, { useCallback, useMemo, useState, FC } from 'react';
import styled from 'styled-components';
import { UPDATE_VIDEO } from '../../queries/AssetQueries';
import withFlashMessaging from '../../HOCs/WithFlashMessaging';
import { GetFormattedTime } from '../../utility/TimeFormatting';
import WithConfirmationBox from '../../HOCs/WithConfirmationBox';
import { EllipsisMenuButton } from '../EllipsisMenu';
import Icon from '../icons/Icon';
import Modal from '../Modal';
import StyledLink from '../../styled-components/StyledLink';
import { useMutation } from '@apollo/client';
import useFlashMessage from '../../apps/core/src/components/FlashMessage';
import _ from 'lodash';

/**
 * @typedef {import('../../apps/core/src/models/simulation.types').Simulation} Simulation
 * @typedef {import('../../apps/core/src/models/simulation.types').Video} Video
 * @typedef {import('../../apps/core/src/models/simulation.types').Interaction} Interaction
 * @typedef {import('./DeleteVideoButtonInMenu.types').DeleteVideoButtonInMenuProps} DeleteVideoButtonInMenuProps
 * @typedef {import('./DeleteVideoButtonInMenu.types').HOCProps} HOCProps
 * 
 * @typedef {{
 *    ID: string;
 *    VideoID: string;
 *    Title: string;
 *    Timestamp?: number;
 * }} LinkedItem
 */

const StyledListWrapper = styled.div`
  margin-bottom: 1rem;
`;
const StyledCannotDeleteInfo = styled.div`
  margin-bottom: 1rem;
  line-height: 1.5rem;
`;
const StyledList = styled.ul``;
const StyledItem = styled.li`
  margin-bottom: 0.5rem;
`;
const CannotDeleteWarningIcon = styled(Icon)`
  margin-left: 0.6rem;
`;

/**
 * @type {FC<DeleteVideoButtonInMenuProps & HOCProps>}
 */
const DeleteVideoButtonInMenu = ({
  setConfirmAsLoading,
  onDelete,
  closeConfirm,
  video,
  confirm,
}) => {
  const [simsListModalOpen, setSimsListModalOpen] = useState(false);
  const { addFlashMessage } = useFlashMessage();

  const [mutateDeleteVideo, { loading }] = useMutation(UPDATE_VIDEO, {
    onError: (err) => {
      console.log(err);
      setConfirmAsLoading(false);
      addFlashMessage('There was an error deleting the video', 'error');
    },
    onCompleted: (data) => {
      addFlashMessage('Video deleted', 'success');
      onDelete?.(video?.ID);
      closeConfirm();
    },
    refetchQueries: ['readMediaPage'],
    variables: {
      Input: {
        ID: video.ID,
        Archived: true,
      },
    },
  });

  /** @type {LinkedItem[]} */
  const simulations = useMemo(
    () =>
      _.flatMap(
        video.Videos.nodes.map(
          /** @param {Video} video */
          (video) =>
            video.Simulations.nodes.map(
              /** @param {Simulation} simulation */
              (simulation) => ({
                ID: simulation.ID,
                VideoID: video.ID,
                Title: simulation.Title,
              })
            )
        )
      ),
    [video]
  );

  /** @type {LinkedItem[]} */
  const events = useMemo(() => 
  _.flatMap(
    video.Videos.nodes.map(
      /** @param {Video} video */
      (video) =>
        video.EmbeddedInEvents.nodes.map(
          /** @param {Interaction} interaction */
          (interaction) => ({
            ID: interaction.ID,
            VideoID: video.ID,
            Title: interaction.Label,
            Timestamp: interaction.Timestamp,
          })
        )
    )
  ), [video]);

  const canDelete = simulations.length === 0 && events.length === 0;

  const openDelete = useCallback(
    (e) => {
      e?.stopPropagation?.();

      if (!canDelete) {
        setSimsListModalOpen(true);
        return;
      }
      confirm(
        () => {
          setConfirmAsLoading(true);
          mutateDeleteVideo();
        },
        '',
        'Delete video?'
      );
    },
    [canDelete, mutateDeleteVideo, setConfirmAsLoading, confirm]
  );

  return (
    <>
      <EllipsisMenuButton loading={loading} onClick={openDelete}>
        <Icon
          customWidth="1.3rem"
          customHeight="1.3rem"
          name="trash-solid"
          type="lineawesome"
        />
        Delete Video
        {!canDelete && (
          <CannotDeleteWarningIcon
            customWidth="1.3rem"
            customHeight="1.3rem"
            type="redesign"
            name={'alert-error'}
          />
        )}
      </EllipsisMenuButton>
      {simsListModalOpen && (
        <Modal
          visible
          hideModal={() => setSimsListModalOpen(false)}
          title="Video Cannot Be Deleted"
        >
          {simulations.length > 0 && (
            <StyledListWrapper>
              <StyledCannotDeleteInfo>
                This video must be removed from the following{' '}
                <strong>simulations</strong> before the file can be deleted:
              </StyledCannotDeleteInfo>
              <StyledList>
                {simulations.map((simulation) => (
                  <StyledItem key={simulation.ID}>
                    <StyledLink
                      to={`/simulations/${simulation.ID}/videos/${simulation.VideoID}/interactions`}
                    >
                      {simulation.Title}
                    </StyledLink>
                  </StyledItem>
                ))}
              </StyledList>
            </StyledListWrapper>
          )}
          {events.length > 0 && (
            <StyledListWrapper>
              <StyledCannotDeleteInfo>
                This video must be removed from the following simulation{' '}
                <strong>video hotspots</strong> (or hotspots should be deleted)
                before the file can be deleted:
              </StyledCannotDeleteInfo>
              <StyledList>
                {events.map((event) => (
                  <StyledItem key={event.ID}>
                    <StyledLink
                      to={`/simulations/${event.ID}/videos/${event.VideoID}/interactions`}
                    >
                      Hotspot {GetFormattedTime(event.Timestamp)}{' '}
                      {event.Title ? '(' + event.Title + ')' : ''}
                    </StyledLink>
                  </StyledItem>
                ))}
              </StyledList>
            </StyledListWrapper>
          )}
        </Modal>
      )}
    </>
  );
};

DeleteVideoButtonInMenu.propTypes = {};

export default /** @type {FC<DeleteVideoButtonInMenuProps>} */ (
  WithConfirmationBox(withFlashMessaging(DeleteVideoButtonInMenu))
);
