import React from 'react';
import PropTypes from 'prop-types';
import * as StyledModal from '../VHWizardModalStyles.styled';
import * as StyledStepContent from './VHCreationStepsStyles.styled';
import { Table, Loading } from '@virtidev/toolbox';

const VHDetailsSummaryStep = ({
  loading,
  creationDetails,
  cancelAction,
  confirmAction,
}) => {
  return (
    <StyledModal.ContentWrapper>
      <StyledModal.TitleWrapper>
        <StyledModal.Title>Create a Virtual Human</StyledModal.Title>
      </StyledModal.TitleWrapper>
      <StyledModal.TextPassage>
        Virtual Human details are as follows:
      </StyledModal.TextPassage>
      <Table>
        <tbody>
          <tr>
            <StyledStepContent.TableHeader>Name</StyledStepContent.TableHeader>
            <StyledStepContent.TableRowData>
              {creationDetails.Title}
            </StyledStepContent.TableRowData>
          </tr>
          <tr>
            <StyledStepContent.TableHeader>Type</StyledStepContent.TableHeader>
            <StyledStepContent.TableRowData>
              {creationDetails.Type}
            </StyledStepContent.TableRowData>
          </tr>
          <tr>
            <StyledStepContent.TableHeader>
              Template
            </StyledStepContent.TableHeader>
            <StyledStepContent.TableRowData>
              {creationDetails.Template.Title}
            </StyledStepContent.TableRowData>
          </tr>
          {creationDetails.Locale && (
            <tr>
              <StyledStepContent.TableHeader>
                Locale
              </StyledStepContent.TableHeader>
              <StyledStepContent.TableRowData>
                {creationDetails.Locale.name}
              </StyledStepContent.TableRowData>
            </tr>
          )}
          {creationDetails.Voice && (
            <tr>
              <StyledStepContent.TableHeader>
                Voice
              </StyledStepContent.TableHeader>
              <StyledStepContent.TableRowData>
                {creationDetails.Voice.Name}
              </StyledStepContent.TableRowData>
            </tr>
          )}
        </tbody>
      </Table>
      <StyledModal.TextPassage>
        Please select "Confirm" to create your Virtual Human
      </StyledModal.TextPassage>
      <StyledModal.ActionButtonsWrapper>
        <StyledModal.StyledLinkButton
          type="button"
          color="secondary"
          onClick={cancelAction}
        >
          Back
        </StyledModal.StyledLinkButton>
        <StyledModal.StyledButton
          type="button"
          color="primary"
          onClick={confirmAction}
          disabled={loading}
        >
          {loading && <Loading />}
          {!loading && 'Confirm'}
        </StyledModal.StyledButton>
      </StyledModal.ActionButtonsWrapper>
    </StyledModal.ContentWrapper>
  );
};

VHDetailsSummaryStep.propTypes = {
  confirmAction: PropTypes.func,
  cancelAction: PropTypes.func,
  creationDetails: PropTypes.object,
  loading: PropTypes.bool.isRequired,
};

export default VHDetailsSummaryStep;
