import styled, { css } from 'styled-components';

export const StyledQuestionWrapper = styled.div`
  ${({ theme, editing, dragging, width }) => css`
    position: relative;
    border-radius: ${theme.borderRadius.lg};
    background-color: var(--card-bg-color);
    box-shadow: var(--card-box-shadow);
    padding: 2rem;
    border-left: ${editing && `5px solid ${theme.color.primary}`};
    background: ${dragging && theme.color.primary};
    width: ${width ? width + 'px' : ''};
    height: ${dragging && '100px'};
  `}
`;

export const StyledDragLine = styled.div`
    height: 3px;
    width: 100%;
  background: var(--primary-color);
    margin-bottom: -100px;
    margin-top: 50px;
    border-radius: 5px;
`;

export const StyledDragHandleWrapper = styled.div`
  margin-top: -20px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
`;
export const StyledDragHandle = styled.div`
  cursor: move;
`;

export const StyledDragPreview = styled(StyledQuestionWrapper)`
  display: flex;
  gap: 1rem;
  align-items: center;
  border: 1px solid var(--secondary-color);
`;
