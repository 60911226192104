import styled from 'styled-components';
import { Button, Icon, TextInput } from '@virtidev/toolbox';

export const ModalLayout = styled.div`
  display: grid;
  grid-template-areas:
    '. top'
    'content content';
  grid-template-columns: 1fr auto;
  grid-template-rows: auto 1fr;
  width: 100%;
  height: 100%;
`;

export const ModalTopArea = styled.div`
  grid-area: top;
`;

export const ModalContentArea = styled.div`
  grid-area: content;
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 1rem;
  padding: 1rem;
`;

export const ContentSectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  padding: 1rem;
`;

export const Title = styled.h2`
  font-size: 1.6rem;
  font-weight: 500;
  font-family: var(--text-font, 'Ubuntu');
  @media screen and (min-width: 1199px) {
    font-size: 2.2rem;
  }
`;

export const TitleWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

export const TextPassage = styled.p`
  font-weight: 400;
  font-size: 1.2rem;
  @media screen and (min-width: 1199px) {
    font-size: 1.5rem;
  }
`;

export const StyledButton = styled(Button)`
  border-radius: 25px;
  height: 40px;
  width: 109px;
`;

export const StyledLinkButton = styled(Button)`
  background-color: transparent;
  color: #757575;
  font-weight: 400;
`;

export const ActionButtonsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
  width: 100%;

  @media screen and (min-width: 768px) {
    flex-direction: row;
    justify-content: center;
  }
`;

export const LoadingWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

export const DuplicateNameWrapper = styled.div`
  width: 100%;
`;

export const ButtonsWrapper = styled.div`
  width: 100%;
  display: flex;
  gap: 0.5rem;
  justify-content: center;
`;
