import { Icon } from '@virtidev/toolbox';
import styled, { css } from 'styled-components';
import PreviewSwitch from '../../../LaunchDarkly/components/PreviewSwitch';

export const Pill = styled.div`
  ${({ theme }) => css`
    display: flex;
    position: relative;
    align-items: center;
    gap: 1rem;
    font-size: ${theme.fontSize.xs};
    color: ${theme.color.darkGrey};
    background: #fff;
    padding: 0.8rem 1rem;
    border-radius: var(--card-border-radius);
    box-shadow: var(--card-box-shadow);
  `}
`;

export const Alert = styled(Icon).attrs({
  icon: 'alert',
})`
  ${({ theme }) => css`
    position: absolute;
    top: -0.3rem;
    right: -0.3rem;
    color: ${theme.color.error};
  `}
`;

export const PreviewPill = styled(PreviewSwitch)`
  ${({ theme }) => css`
    margin-top: ${theme.spacing.md};
  `}
`;

export const Chevron = styled(Icon).attrs({
  icon: 'chevron',
})`
  transform: rotate(90deg) scale(0.8, 0.8);
`;
