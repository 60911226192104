import styled from 'styled-components';

export const StyledFormButtonWrapper = styled.div`
  color: #ccc;
  :hover {
    color: var(--primary-color);
  }
  transition: 0.1s;
  font-size: 0.9rem;
  cursor: pointer;
`;

export const SelectAndLinkWrapper = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
`;
