import styled from 'styled-components';

export const StyledSelectWrapper = styled.div`
  box-shadow: var(--card-box-shadow);
  background-color: var(--card-bg-color);
  border-radius: var(--card-border-radius);
  padding: 2rem;
  display: grid;
  place-items: center;
`;

export const StyledIconsWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  place-items: center;
  margin: 1rem auto;
`;

export const StyledIconButton = styled.button`
  background: rgba(0, 0, 0, 0);
  border: 2px solid var(--primary-border-color);
  border-radius: 8px;
  padding: 1rem;
  width: 5rem;
  height: 5rem;
  cursor: pointer;
  border: 2px solid var(--neutral-color-lighter);

  :hover {
    border: 2px solid var(--primary-color);
  }
`;

export const StyledIconAndLabel = styled.div`
  color: var(--neutral-color);
  display: grid;
  place-items: center;
  :hover {
    color: var(--primary-color);
    button {
      border: 2px solid var(--primary-color);
    }
  }
`;

export const StyledTypeLabel = styled.div`
  margin-top: 1rem;
  text-align: center;
  cursor: pointer;
`;
