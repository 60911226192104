import NavigationPromptManual from '@base/components/NavigationPromptManual';
import { withTusEndpoint } from '@core/components/Cx/OrgTUSEndpointForm/withTusEndpoint';
import {
  BaseSyntheticEvent,
  useCallback,
  useRef,
  useState,
  useEffect,
  useMemo,
} from 'react';
import { QueueItem } from '@core/components/MediaUploaders/MixedMediaUploader.types';
import getFileType from '@core/components/MediaUploaders/helpers/getFileType';
import { useFlashMessage } from '@core/components/FlashMessage';
import { Upload } from 'tus-js-client';
import MediaUploadBox from '@core/components/MediaUploaders/components/MediaUploadBox';
import {
  UploadCard,
  UploadContent,
  UploadingOverlay,
  UploadTitle,
} from '@core/components/MediaUploaders/MixedMediaUploader.styled';
import UploadingProgress from '@core/components/MediaUploaders/components/UploadingProgress';

/**
 * @param {BaseSyntheticEvent<HTMLDivElement>} e
 */
const noDefault = (e) => {
  if (e) {
    e.preventDefault();
  }
};

export const MixedMediaUploader = ({
  onComplete,
  onSuccess,
  onSending,
  onCancel,
  onError,
  chunkSize = 1024 * 1024 * 50,
  multiple,
  name,
  warnPrompt,
  getTusEndpoint,
  acceptedTypes = ['video', 'image'],
  maxFileSize,
  title,
  loading,
  ...props
}) => {
  const [uploading, setUploading] = useState(-1);
  const [completed, setCompleted] = useState(false);
  const [paused, setPaused] = useState(false);
  const [progress, setProgress] = useState({ total: 0, sent: 0 });
  const [queue, setQueue] = useState(/** @type {QueueItem[]} */ ([]));
  const [queueIndex, setQueueIndex] = useState(0);
  const [error, setError] = useState(null);
  const [dragging, setDragging] = useState(false);
  const { addFlashMessage } = useFlashMessage();

  const contentRef = useRef(/** @type {HTMLDivElement | null} */ (null));
  const upload = useRef(/** @type {Upload | null} */ (null));
  const callbacks = useRef({
    onComplete,
    onSuccess,
    onSending,
    onError,
    queue,
  });
  callbacks.current = {
    onComplete,
    onSuccess,
    onSending,
    onError,
    queue,
  };

  const startUpload = useCallback(
    async (item, index) => {
      const endpoint = await getTusEndpoint(item.type);

      if (!upload.current) {
        const uploadObj = new Upload(item.file, {
          endpoint,
          removeFingerprintOnSuccess: true, // (github note) a boolean indicating if the fingerprint in the storage will be removed when the upload is successfully completed. This value is false for not breaking the previous API contract, but we strongly suggest to set it to true to avoid cluttering the storage space. The effect is that if the same file is uploaded again, it will create an entirely new upload. Furthermore, this option will only change behaviour if resume is set to true.
          retryDelays: [0, 3000, 5000, 10000, 20000],
          metadata: {
            filename: item.file.name,
            filetype: item.file.type,
          },

          onError: (e) => {
            setUploading(-1);
            setError({ item, error: e });
            upload.current = null;
            callbacks.current?.onError?.(e);
          },
          chunkSize,
          onProgress: (sent, total) => {
            setProgress({ sent, total });
          },
          onSuccess: async () => {
            const url = uploadObj?.url;

            if (!url) {
              const error = new Error('No url found with upload');
              setError({ item, error });
              console.error(item, error, uploadObj);
              upload.current = null;
              callbacks.current?.onError?.(error);
              setUploading(-1);
              return;
            }
            await callbacks.current?.onSuccess?.(uploadObj, item);

            setQueueIndex(index + 1);
            setUploading(-1);
            upload.current = null;

            if (callbacks.current.queue.length - 1 === index) {
              await callbacks.current?.onComplete?.();
              setCompleted(true);
              return;
            }
          },
        });
        upload.current = uploadObj;
      }
      // reimplement old functionality from tus v1 to resume from localstorage
      const previousUploads = await upload.current.findPreviousUploads();
      if (previousUploads.length > 0) {
        upload.current.resumeFromPreviousUpload(previousUploads[0]);
      }
      // Start the upload
      upload.current.start();
      callbacks.current?.onSending?.();

      setProgress({ sent: 0, total: item.file.size });
      setUploading(index);
      setCompleted(false);
      setPaused(false);
      setError(null);
    },
    [getTusEndpoint, chunkSize]
  );

  const cancelUpload = useCallback(
    async (e) => {
      noDefault(e);

      if (upload.current) {
        await upload.current.abort();
        upload.current = null;
      }

      setQueue([]);
      setCompleted(false);
      setQueueIndex(0);
      setUploading(-1);
      setError(null);
      setPaused(false);
      setProgress({ total: 0, sent: 0 });

      onCancel?.();
    },
    [onCancel]
  );

  const pauseUpload = useCallback((e) => {
    noDefault(e);
    if (!upload.current) {
      setError(null);
      return;
    }
    setPaused((prev) => {
      if (!upload.current) {
        return prev;
      }
      if (prev) {
        upload.current.start();
      } else {
        upload.current.abort();
      }
      return !prev;
    });
  }, []);

  const addFiles = useCallback(
    /**
     * @param {FileList} files
     * @param {'3d' | '2d'} dimension
     */
    (files, dimension) => {
      setDragging(false);
      if (dimension !== '3d' && dimension !== '2d') {
        addFlashMessage(
          'Invalid dimensions, please contact a system administrator.',
          'error'
        );
        return;
      }

      if (files.length > 1 && !multiple) {
        addFlashMessage('Cannot upload multiple files.', 'warning');
        return;
      }

      /**
       * @type {QueueItem[]}
       */
      const adding = [];

      for (const file of files) {
        const type = getFileType(file.type);

        adding.push({
          content360: dimension === '3d',
          type,
          file,
        });
      }

      setQueue((curr) => [...curr, ...adding]);
    },
    [addFlashMessage, multiple]
  );

  // const triggerQueue = useMemo(() => debounce(startUpload, 100), [startUpload]);

  useEffect(() => {
    if (queue.length === 0 || error) {
      return;
    }
    if (uploading === -1 && queue[queueIndex]) {
      startUpload(queue[queueIndex], queueIndex);
    }
  }, [queueIndex, queue, uploading, startUpload, error]);

  useEffect(() => {
    /** @type {NodeJS.Timeout | null} */
    let timer = null;

    const checkDragging = (e) => {
      if (!contentRef.current || !e.target) {
        return;
      }
      if (timer) {
        clearTimeout(timer);
      }
      if (contentRef.current.contains(e.target)) {
        setDragging(true);
        return;
      }
      timer = setTimeout(() => {
        setDragging(false);
      }, 100);
    };

    const stopDragging = (e) => {
      if (!contentRef.current || !e.target) {
        return;
      }
      if (
        document.body.contains(e.target) &&
        !contentRef.current.contains(e.target)
      ) {
        timer = setTimeout(() => {
          setDragging(false);
        }, 100);
      }
    };

    window.addEventListener('dragover', checkDragging);
    window.addEventListener('dragleave', stopDragging);

    return () => {
      window.removeEventListener('dragover', checkDragging);
      window.removeEventListener('dragleave', stopDragging);
    };
  }, []);

  return (
    <UploadCard noPadding {...props}>
      {warnPrompt && (
        <NavigationPromptManual
          when={uploading >= 0}
          message="You are still uploading files, are you sure you want to leave?"
        />
      )}
      {title && <UploadTitle>{title}</UploadTitle>}
      <UploadContent ref={contentRef} $padding={title}>
        <MediaUploadBox
          dimension="3d"
          name={`${name}-3d`}
          addFiles={addFiles}
          acceptedTypes={acceptedTypes}
          maxFileSize={maxFileSize}
          multiple={multiple}
        >
          3D upload box
        </MediaUploadBox>
        <MediaUploadBox
          dimension="2d"
          name={`${name}-2d`}
          addFiles={addFiles}
          acceptedTypes={acceptedTypes}
          maxFileSize={maxFileSize}
          multiple={multiple}
        >
          2D upload box
        </MediaUploadBox>
        {(uploading >= 0 || loading || error) && !dragging && (
          <UploadingOverlay>
            <UploadingProgress
              uploading={uploading >= 0}
              error={error?.error?.message}
              fileNum={queueIndex}
              fileCount={queue.length}
              completed={completed}
              progress={progress}
              paused={paused}
              onPause={pauseUpload}
              onCancel={cancelUpload}
            />
          </UploadingOverlay>
        )}
      </UploadContent>
    </UploadCard>
  );
};;;;;;;;;

export default withTusEndpoint(MixedMediaUploader);
