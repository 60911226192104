import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import * as Styled from './TabbedItem.styled';
import { Icon } from '@virtidev/toolbox';
import { useTheme } from 'styled-components';

const TabbedItem = ({ item, active, onClick }) => {
  const handleOnClick = useCallback(() => {
    onClick(item.value);
  }, [item.value, onClick]);

  const theme = useTheme();

  return (
    <Styled.TabbedItem onClick={handleOnClick}>
      <Styled.TabbedItemImg
        src={item.imageURL}
        alt={`${item.label}`}
        $active={active}
      />
      <Styled.TabbedItemLabel $active={active}>
        {item.label}
      </Styled.TabbedItemLabel>
      {active && (
        <Styled.CircleTick>
          <Icon size={16} icon="tick" color={theme.color.white} />
        </Styled.CircleTick>
      )}
    </Styled.TabbedItem>
  );
};

TabbedItem.propTypes = {
  active: PropTypes.bool.isRequired,
  item: PropTypes.shape({
    value: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    imageURL: PropTypes.string.isRequired,
  }).isRequired,
  onClick: PropTypes.func,
};

export default TabbedItem;
