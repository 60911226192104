import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Icon, Tooltip } from '@virtidev/toolbox';
import * as StyledModal from '../VHWizardModalStyles.styled';
import * as StyledStepContent from './VHCreationStepsStyles.styled';
import { useEffectOnlyOnce } from '../../../utility/CustomHooks';
import { VHTemplateEdgePropType } from '../VHCreationWizard.propTypes';
import useUser from '../../../apps/core/src/helpers/useUser';
import { useTheme } from 'styled-components';
import { useFeature } from '@core/components/LaunchDarkly';

const VH_TYPES = {
  DEFAULT: 'default',
  BRANCHING: 'branching',
  MEDICAL: 'medical',
  FREEFORM_V3: 'freeform_v3',
};

const VHNameTypeStep = ({
  confirmAction,
  cancelAction,
  creationDetails,
  canMakeFreeform,
  canMakeBranching,
  templateEdges,
}) => {
  const { Organisation } = useUser();
  const [nameValue, setNameValue] = useState('');
  const [adminTitleValue, setAdminTitleValue] = useState('');
  const [selectedType, setSelectedType] = useState(null);
  const theme = useTheme();

  const saveDetails = () => {
    confirmAction({
      Title: nameValue,
      AdminTitle: adminTitleValue,
      Type: selectedType,
    });
  };

  // ensure values are set to the stored values when going back to step 1
  useEffectOnlyOnce(() => {
    if (creationDetails?.Title) {
      setNameValue(creationDetails.Title);
    }
    if (creationDetails?.AdminTitle) {
      setAdminTitleValue(creationDetails.AdminTitle);
    }
    if (creationDetails?.Type) {
      setSelectedType(creationDetails.Type);
    }
  });

  const handleSelectType = (typeString) => {
    setSelectedType(typeString);
  };

  const freeformV2Feature = useFeature('freeform-v-2');

  return (
    <StyledModal.ContentWrapper>
      <StyledModal.TitleWrapper>
        <StyledModal.Title>Create a Virtual Human</StyledModal.Title>
      </StyledModal.TitleWrapper>

      <StyledModal.ContentSectionWrapper>
        <StyledModal.TextPassage>
          Enter a name for your Virtual Human
        </StyledModal.TextPassage>
        <StyledStepContent.NameFieldWrapper>
          <StyledStepContent.FieldWrapper>
            <StyledStepContent.TextInputField
              type="text"
              onChange={(e) => setNameValue(e.target.value)}
              placeholder="Name"
              value={nameValue}
            />
          </StyledStepContent.FieldWrapper>
          <StyledStepContent.FieldWrapper>
            <StyledStepContent.TextInputField
              type="text"
              onChange={(e) => setAdminTitleValue(e.target.value)}
              placeholder="Admin title (optional)"
              value={adminTitleValue}
            />
            <Tooltip icon="help">
              This title is shown only to admins for identification purposes in
              the admin area. It is not displayed to learners.
            </Tooltip>
          </StyledStepContent.FieldWrapper>
        </StyledStepContent.NameFieldWrapper>
      </StyledModal.ContentSectionWrapper>

      <StyledModal.ContentSectionWrapper>
        <StyledModal.TextPassage>Select a base type</StyledModal.TextPassage>
        <StyledStepContent.TypeIconsWrapper>
          <StyledStepContent.TypeIconButton
            disabled={!canMakeBranching}
            onClick={() => handleSelectType(VH_TYPES.BRANCHING)}
          >
            <Icon
              icon="branch"
              type="solid"
              color={
                selectedType === VH_TYPES.BRANCHING
                  ? theme.color.secondary
                  : '#757575'
              }
              size="8rem"
            />
            <StyledStepContent.TypeIconButtonLabel>
              Branching
              {!canMakeBranching && <div>(Unavailable)</div>}
            </StyledStepContent.TypeIconButtonLabel>
          </StyledStepContent.TypeIconButton>
          {freeformV2Feature && (
            <StyledStepContent.TypeIconButton
              disabled={!canMakeBranching}
              onClick={() => handleSelectType(VH_TYPES.FREEFORM_V3)}
            >
              <Icon
                icon="speech-bubble-circle"
                type="solid"
                color={
                  selectedType === VH_TYPES.FREEFORM_V3
                    ? theme.color.secondary
                    : '#757575'
                }
                size="8rem"
              />
              <StyledStepContent.TypeIconButtonLabel>
                <div>Freeform V3</div>
                <StyledStepContent.BetaIconWrapper>
                  <Icon
                    color={theme.color.turquoise}
                    icon="beta"
                    size="3.5rem"
                  />
                </StyledStepContent.BetaIconWrapper>
              </StyledStepContent.TypeIconButtonLabel>
            </StyledStepContent.TypeIconButton>
          )}
          <StyledStepContent.TypeIconButton
            onClick={() => handleSelectType(VH_TYPES.DEFAULT)}
            disabled={!canMakeFreeform}
          >
            <Icon
              icon="speech-bubble-circle"
              type="solid"
              color={
                selectedType === VH_TYPES.DEFAULT
                  ? theme.color.secondary
                  : '#757575'
              }
              size="8rem"
            />
            <StyledStepContent.TypeIconButtonLabel>
              Freeform
              {!canMakeFreeform && <div>(Unavailable)</div>}
            </StyledStepContent.TypeIconButtonLabel>
          </StyledStepContent.TypeIconButton>
          {/* use direct comparison to "false" to ease transition from logged in users not having the Medical data available in localstorage */}
          {Organisation?.Medical !== false && (
            <StyledStepContent.TypeIconButton
              onClick={() => handleSelectType(VH_TYPES.MEDICAL)}
              disabled={!canMakeFreeform}
            >
              <Icon
                icon="medical-circle"
                type="solid"
                color={
                  selectedType === VH_TYPES.MEDICAL
                    ? theme.color.secondary
                    : '#757575'
                }
                size="8rem"
              />
              <StyledStepContent.TypeIconButtonLabel>
                Medical Freeform
                {!canMakeFreeform && <div>(Unavailable)</div>}
              </StyledStepContent.TypeIconButtonLabel>
            </StyledStepContent.TypeIconButton>
          )}
        </StyledStepContent.TypeIconsWrapper>
      </StyledModal.ContentSectionWrapper>
      <StyledModal.ActionButtonsWrapper>
        <StyledModal.StyledLinkButton
          type="button"
          color="secondary"
          onClick={cancelAction}
        >
          Cancel
        </StyledModal.StyledLinkButton>
        <StyledModal.StyledButton
          type="button"
          color="primary"
          onClick={saveDetails}
          disabled={selectedType === null || nameValue === ''}
        >
          Next
        </StyledModal.StyledButton>
      </StyledModal.ActionButtonsWrapper>
    </StyledModal.ContentWrapper>
  );
};

VHNameTypeStep.propTypes = {
  creationDetails: PropTypes.object.isRequired,
  confirmAction: PropTypes.func.isRequired,
  cancelAction: PropTypes.func.isRequired,
  canMakeFreeform: PropTypes.bool.isRequired,
  canMakeBranching: PropTypes.bool.isRequired,
  templateEdges: PropTypes.arrayOf(VHTemplateEdgePropType),
};

export default VHNameTypeStep;
