import { useMutation } from '@apollo/client';
import { Button, Icon } from '@virtidev/toolbox';
import { produce } from 'immer';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Upload } from 'tus-js-client';
import useUser from '../../../../helpers/useUser';
import useFlashMessage from '../../../FlashMessage';
import { CREATE_MEDIA } from '../../../MediaUploaders/MediaUploader.query';
import { TargetBox } from './ResourceDropArea';
import { CREATE_RESOURCE, READ_COURSE_RESOURCES } from './UploadResource.query';
import {
  StyledFileInput,
  StyledInputWrapper,
  UploaderListBox,
} from './UploadResource.styled';
import getConfig from '../../../../../../../lib/config';

export default function UploadResource({ courseId, setUploading }) {
  const [loading, setLoading] = useState(false);
  const [selectedFile, setSelectedFile] = useState();
  const { Organisation } = useUser();
  const { addFlashMessage } = useFlashMessage();

  const refetchQueries = [
    {
      query: READ_COURSE_RESOURCES,
      variables: {
        ID: courseId,
      },
    },
  ];

  const [createResource, { loading: resourceLoading }] = useMutation(
    CREATE_RESOURCE,
    {
      refetchQueries,
      onCompleted: () => {
        addFlashMessage('Successfully added resource!');
        setSelectedFile(null);
      },
      onError: () => console.error('resource upload error'),
    }
  );
  const ID = `new-unit-${(Math.random() * 100).toFixed(0)}`;
  const mediaID = `new-media-${(Math.random() * 100).toFixed(0)}`;

  const [createMedia, { loading: mediaLoading }] = useMutation(CREATE_MEDIA, {
    onCompleted: (data) => {
      createResource({
        variables: {
          courseId,
          Input: {
            MediaID: data.createMedia.ID,
            CourseID: courseId,
            Title: data.createMedia.Title,
            Type: selectedFile.type,
          },
        },
        update: (cache) => {
          cache.evict({ fieldName: 'readVideoMedias' });
          cache.evict({ fieldName: 'readMedias' });

          setUploading(false);
        },
      });

      // update cache
    },
    onError: () => console.error('media upload error'),
  });

  const handleChange = useCallback((e) => {
    setSelectedFile(e.target.files[0]);
  }, []);

  const handleDropChange = useCallback((target) => {
    setSelectedFile(target.files[0]);
  }, []);

  const getFileID = (upload) => {
    try {
      return upload.url.split('/').slice(-1)[0].split('+')[0];
    } catch (e) {
      return null;
    }
  };

  const handleSuccess = useCallback(
    async (upload, TusID) => {
      await createMedia({
        variables: {
          Input: {
            URL: `${getConfig("REACT_APP_CLOUDFRONT_URL")}${TusID}`,
            TusID,
            Title: upload.file.name,
            OrganisationID: Organisation.ID,
            Filename: TusID,
              Bucket: getConfig("REACT_APP_S3_BUCKET"),
          },
        },
        optimisticResponse: {
          createMedia: {
            ID: mediaID,
            URL: `${getConfig("REACT_APP_CLOUDFRONT_URL")}${TusID}`,
            TusID,
            Title: upload.file.name,
            Width: '',
            Height: '',
            Length: '',
            Filename: TusID,
          },
        },
      });
    },
    [createMedia, Organisation, mediaID]
  );

  const uploadHandler = useCallback(async () => {
    if (selectedFile.size > 100000000) {
      addFlashMessage(
        'File is too large - must be smaller than 100mb.',
        'error'
      );
      return;
    } else {
      setLoading(true);
      setUploading(true);

      const upload = new Upload(selectedFile, {
        endpoint: getConfig("REACT_APP_TUS_UPLOAD_ENDPOINT_OTHER_MEDIA"),
        removeFingerprintOnSuccess: true,
        retryDelays: [0, 3000, 5000, 10000, 20000],
        metadata: {
          filename: selectedFile.name,
          filetype: selectedFile.type,
        },

        onError: (error) => {
          console.error(error);
          addFlashMessage(
            'Something went wrong, resource could not be uploaded.',
            'error'
          );
        },
        chunkSize: 1024 * 1024 * 50,
        onProgress: (bytesAccepted, totalBytes) => {
          // can add a progress wheel or bar in next steps
        },
        onSuccess: async (res) => {
          const fileId = getFileID(upload);
          if (fileId) {
            return handleSuccess(upload, fileId);
          }
          console.error('TusID not found', upload);
        },
      });

      upload.start();
    }
  }, [selectedFile, handleSuccess, setUploading, addFlashMessage]);

  useEffect(() => {
    if (resourceLoading) {
      return setLoading(true);
    }
    if (mediaLoading) {
      return setLoading(true);
    }

    return setLoading(false);
  }, [resourceLoading, mediaLoading]);

  return (
    <UploaderListBox>
      <StyledInputWrapper
        fileSelected={selectedFile}
        title={selectedFile?.name}
      >
        <TargetBox onDrop={handleDropChange}>
          {selectedFile ? selectedFile.name : 'Drag or Select File'}
          <StyledFileInput type="file" onChange={handleChange} />
        </TargetBox>
      </StyledInputWrapper>
      <Button disabled={!selectedFile || loading} onClick={uploadHandler}>
        Upload
      </Button>
    </UploaderListBox>
  );
}
