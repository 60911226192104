import React from 'react'
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const StyledPagination = styled.div`
  text-align: center;
  margin-bottom: 1rem;
`;
export const StyledPageBtn = styled.button`
  color: var(--pagination-btn-text-color);
  border-radius: var(--pagination-btn-border-radius);
  border: none;
  cursor: pointer;
  background-color: var(--pagination-btn-bg-color);
  margin: 0 2px;
  font-family: var(--text-font);
  font-size: 0.8rem;
  border: var(--primary-border);
  border-right: none;
  width: 36px;
  height: 36px;
  font-weight: 500;
  &:last-child {
    border-right: var(--primary-border);
  }
  ${(props) =>
    props.active &&
    css`
      background-color: var(--pagination-btn-bg-color-active);
      color: var(--pagination-btn-text-color-active);
    `}
  ${(props) =>
    props.disabled &&
    css`
      color: var(--disabled-pagination-btn-text-color);
      background-color: var(--disabled-pagination-btn-bg-color);
      pointer-events: none;
    `}
  &:hover {
    background-color: var(--pagination-btn-bg-color-hover);
    color: var(--pagination-btn-text-color-hover);
  }
  ${(props) =>
    props.alignLeft &&
    css`
      margin-top: 1.5rem;
      text-align: left;
    `}
`;

const getRunChangePageCallback = (newPageNum, numPages, changePageCallback) => {
  return () => {
    newPageNum = Math.max(1, Math.min(numPages, newPageNum));
    changePageCallback(newPageNum);
  }
}

function getPagingRange(current, { pagesToShow, totalPages, start = 1 }) {
  const paging = [];
  var i = Math.min(totalPages + start - pagesToShow, Math.max(start, current - (pagesToShow / 2 | 0)));
  const end = i + pagesToShow;
  while (i < end) { paging.push(i++) }
  // while (i < end) { paging.push(i === current ? `[${i++}]` : `${i++}`) }
  return paging;
}


const Pagination = props => {
  const numPages = Math.ceil(props.totalCount / props.perPage);
  const pagesToShow = Math.min(props.pageNumsToShow, numPages);
  if (numPages < 2) return <React.Fragment />
  return (
    <StyledPagination className={props.className}>
      {props.showPrevNext && (
        <React.Fragment>
          <StyledPageBtn disabled={props.page === 1} onClick={() => props.changePageCallback(1)}>
            <div>
              <FontAwesomeIcon icon="chevron-left" />
              <FontAwesomeIcon icon="chevron-left" />
            </div>
          </StyledPageBtn>
          <StyledPageBtn disabled={!props.pageInfo.hasPreviousPage} onClick={getRunChangePageCallback(props.page - 1, numPages, props.changePageCallback)}>
            <div>
              <FontAwesomeIcon icon="chevron-left" />
            </div>
          </StyledPageBtn>
        </React.Fragment>
      )}
      {
        getPagingRange(props.page, { pagesToShow, totalPages: numPages }).map((pageNum, index) => {
          return (
            <StyledPageBtn key={index} active={pageNum === props.page} onClick={getRunChangePageCallback(pageNum, numPages, props.changePageCallback)}>
              <div>
                {pageNum}
              </div>
            </StyledPageBtn>
          )
        })
      }
      {props.showPrevNext && (
        <React.Fragment>
          <StyledPageBtn disabled={!props.pageInfo.hasNextPage} onClick={getRunChangePageCallback(props.page + 1, numPages, props.changePageCallback)}>
            <div>
              <FontAwesomeIcon icon="chevron-right" />
            </div>
          </StyledPageBtn>
          <StyledPageBtn disabled={props.page === numPages} onClick={() => props.changePageCallback(numPages)}>
            <div>
              <FontAwesomeIcon icon="chevron-right" />
              <FontAwesomeIcon icon="chevron-right" />
            </div>
          </StyledPageBtn>
        </React.Fragment>
      )}
    </StyledPagination>
  )
}

Pagination.defaultProps = {
  pageNumsToShow: 10,
  showPrevNext: true,
};

Pagination.propTypes = {
  page: PropTypes.number.isRequired,
  perPage: PropTypes.number.isRequired,
  totalCount: PropTypes.number.isRequired,
  pageInfo: PropTypes.shape({
    hasNextPage: PropTypes.bool.isRequired,
    hasPreviousPage: PropTypes.bool.isRequired,
  }),
  changePageCallback: PropTypes.func.isRequired,
  pageNumsToShow: PropTypes.number,
  showPrevNext: PropTypes.bool,
}

export default Pagination

export const PaginationBottom = styled(Pagination)`
  margin-top: 1.5rem;
  text-align: left;
`