import { useMutation } from '@apollo/client';
import { TextInput, Tooltip } from '@virtidev/toolbox';
import _ from 'lodash';
import React, { useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Card, CardTitle } from '../../../CourseForm/components/Card';
import FieldContainer from '../../../form/FieldContainer';

import { UPDATE_FEEDBACK_DETAILS } from './EditDetails.query';
import { useAutosave } from '../../../../helpers/useAutosave';
import { Label } from '../../../form/Label';
import { LabelTooltipWrapper } from './EditDetails.styled';

export default function EditDetails({ feedbackForm, setLoading, loading }) {
  const formMethods = useForm({
    defaultValues: {
      Title: feedbackForm?.Title ?? null,
      AdminTitle: feedbackForm?.AdminTitle ?? null,
      Description: feedbackForm?.Description ?? null,
    },
  });

  const { register, reset, handleSubmit: submit, formState } = formMethods;

  const [updateFeedbackDetails] = useMutation(UPDATE_FEEDBACK_DETAILS);

  const handleSubmit = useMemo(() => {
    return async (values) => {
      const input = {
        ID: feedbackForm.ID,
        ...values,
      };
      await updateFeedbackDetails({
        variables: {
          input,
        },
      });
    };
  }, [updateFeedbackDetails, feedbackForm?.ID]);

  useAutosave(formMethods, handleSubmit, setLoading);

  return (
    <FormProvider {...formMethods}>
      <Card>
        <CardTitle>Form Details</CardTitle>
        <FieldContainer>
          <Label htmlFor="form-details-title">Title</Label>
          <TextInput
            id="form-details-title"
            maxLength="255"
            {...register('Title')}
          />
        </FieldContainer>
        <FieldContainer>
          <Label htmlFor="form-details-admin-title">
            <LabelTooltipWrapper>
              Admin Title{' '}
              <Tooltip icon="help">
                This title is shown only to admins for identification purposes
                in the admin area. It is not displayed to learners.
              </Tooltip>
            </LabelTooltipWrapper>
          </Label>
          <TextInput
            id="form-details-admin-title"
            maxLength="255"
            {...register('AdminTitle')}
          />
        </FieldContainer>
        <FieldContainer>
          <Label htmlFor="form-details-description">Description</Label>
          <TextInput
            id="form-details-description"
            multiline
            placeholder="Description"
            {...register('Description')}
          />
        </FieldContainer>
      </Card>
    </FormProvider>
  );
}
