// differs from StyledTabs in that it's route-based not page-based
import styled, { css } from 'styled-components';
import LinkWithPrevState from '../components/LinkWithPrevState';

const StyledTabNav = styled.nav`
  display: flex;
  /* padding: 0 var(--content-side-padding); */
`;

const StyledTab = styled(LinkWithPrevState)`
  ${({ theme, $isActive, disabled }) => css`
    padding: 0.5rem 1rem;
    cursor: pointer;
    text-align: center;
    padding: 1.2rem 0.5rem;
    margin-left: 2rem;
    display: block;
    font-size: 1rem;
    font-weight: 500;
    color: var(--tab-text-color);
    align-content: flex-end;
    position: relative;

    &:active {
      color: var(--tab-active-click-text-color);
    }
    ${$isActive &&
    css`
      color: ${theme.color.primary};
      &::after {
        content: ' ';
        background: ${theme.color.primary};
        width: 6px;
        height: 6px;
        position: absolute;
        bottom: 0rem;
        left: 0;
        right: 0;
        margin: auto;
        border-radius: 60px;
    `}
    ${disabled &&
    css`
      color: var(--tab-disabled-text-color);
      pointer-events: none;
    `}
  `}
`;

export { StyledTab as Tab, StyledTabNav as TabNav };
