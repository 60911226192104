import React, { useState, useEffect, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import * as StyledModal from '../VHWizardModalStyles.styled';
import { VHTemplateNodePropType } from '../VHCreationWizard.propTypes';
import VHLocaleVoiceSelection from './VHLocaleVoiceSelection/VHLocaleVoiceSelection';

const VHLocaleVoiceStep = (props) => {
  const [selectedVoiceData, setSelectedVoiceData] = useState(null);
  const [selectedLocaleData, setSelectedLocaleData] = useState(null);

  useEffect(() => {
    // if going 'back', select previously selected
    if (props.creationDetails?.Locale) {
      setSelectedLocaleData(props.creationDetails.Locale);
    } else {
      setSelectedLocaleData('en-US'); // set to default
    }
    if (props.creationDetails?.Voice) {
      setSelectedVoiceData(props.creationDetails.Voice);
    }
  }, [props.creationDetails?.Locale, props.creationDetails?.Voice]);

  const saveDetails = () => {
    if (!selectedVoiceData || !selectedLocaleData) return;
    props.confirmAction({
      Locale: selectedLocaleData,
      Voice: selectedVoiceData,
    });
  };

  const handleVoiceChange = useCallback(
    (newItemData) => {
      setSelectedVoiceData(newItemData);
    },
    [setSelectedVoiceData]
  );

  const handleLocaleChange = useCallback(
    (newLocaleData) => {
      setSelectedLocaleData(newLocaleData);
    },
    [setSelectedLocaleData]
  );

  return (
    <StyledModal.ContentWrapper>
      <StyledModal.TitleWrapper>
        <StyledModal.Title>Create a Virtual Human</StyledModal.Title>
      </StyledModal.TitleWrapper>
      <StyledModal.ContentSectionWrapper>
        <StyledModal.TextPassage>
          Choose your Virtual Human's Locale and Voice
        </StyledModal.TextPassage>
        <VHLocaleVoiceSelection
          onLocaleChange={handleLocaleChange}
          onVoiceChange={handleVoiceChange}
          initialVoiceData={props.creationDetails?.Voice}
          initialLocaleCode={props.creationDetails?.Locale?.BCP47Code}
          vhType={props.creationDetails?.Type}
        />
      </StyledModal.ContentSectionWrapper>
      <StyledModal.ActionButtonsWrapper>
        <StyledModal.StyledLinkButton
          type="button"
          color="secondary"
          onClick={props.cancelAction}
        >
          Back
        </StyledModal.StyledLinkButton>
        <StyledModal.StyledButton
          type="button"
          color="primary"
          onClick={saveDetails}
          disabled={selectedVoiceData === null || selectedLocaleData === null}
        >
          Next
        </StyledModal.StyledButton>
      </StyledModal.ActionButtonsWrapper>
    </StyledModal.ContentWrapper>
  );
};

export default VHLocaleVoiceStep;

VHLocaleVoiceStep.propTypes = {
  confirmAction: PropTypes.func,
  cancelAction: PropTypes.func,
  selectedVHTemplate: VHTemplateNodePropType,
};
