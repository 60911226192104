import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Mutation } from '@apollo/client/react/components';
import { withApollo } from '@apollo/client/react/hoc';
import _ from 'lodash';
import styled from 'styled-components';

import StyledTextInput from '../styled-components/StyledTextInput';
import TextEditorArea from '../components/TextEditorArea';
import { MinLength } from '../utility/FormValidations';
import ValidationMessages from '../components/ValidationMessages';
import WithFlashMessaging from '../HOCs/WithFlashMessaging';
import withUser from '../HOCs/WithUser';
import { EDIT_ORGANISATION } from '../queries/OrganisationQueries';
import StyledLabel from '../styled-components/StyledLabel';
import Button from '../components/buttons/Button';
import SaveBar from '../components/SaveBar';
import {
  StyledInputWrapper,
  StyledSections,
  StyledSection,
  StyledSectionInner,
} from '../styled-components/StyledFormStructure';
import WithFormHandler from '../HOCs/WithFormHandler';
import MediaUploader from '../apps/core/src/components/MediaUploaders/MediaUploader';

const StyledForm = styled.div`
  padding-bottom: 1rem;
`;

const StyledSectionOneInner = styled(StyledSectionInner)`
  display: grid;
  grid-template-columns: 1fr 2fr;
  grid-column-gap: 2rem;
`;

const StyledLogoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
const StyledLogoSideInputsWrapper = styled.div``;

const formConfig = {
  inputs: [
    {
      dbName: 'Name',
    },
    {
      dbName: 'Description',
    },
    // {
    //   dbName: 'LogoURL',
    // },
    {
      dbName: 'LogoID',
    },
    {
      dbName: 'LogoMediaID',
    },
  ],
  validations: [MinLength('Name', 'Name', true, 1)],
};

class OrganisationEditForm extends Component {
  componentDidMount() {
    this.props.setInitialData(this.props.organisation, () => {
      this.props.setInputImgState(
        'LogoID',
        'LogoURL',
        this.props.organisation,
        this.props.organisation.LogoID,
        null
      );
    });
  }

  _submitForm = (e, mutate) => {
    e.preventDefault();
    this.props.triggerRevalidation((valid) => {
      if (!valid) return;
      this.props.setSubmittingForm(true);
      let input = this.props.currentFormData;
      input.ID = this.props.organisation.ID;
      let variables = { Input: input };
      mutate({
        variables,
        // refetchQueries: refetches,
      });
    });
  };

  render() {
    return (
      <Mutation
        errorPolicy="all"
        onCompleted={(data) => {
          this.props.setSubmittingForm(false);
          this.props.resetPrevFormData();
          if (this.props.submitCallback) {
            this.props.submitCallback(data);
          }
          this.props.addFlashMessage('Organisation updated');
        }}
        onError={(error) => {
          this.props.setSubmittingForm(false);
          if (error.toString().indexOf('identical identifier') > -1) {
            this.props.addFlashMessage(
              'An organisation already exists with that name',
              'error'
            );
          } else {
            this.props.addFlashMessage(
              'There was an error processing the form',
              'error'
            );
          }
        }}
        mutation={EDIT_ORGANISATION}
      >
        {(mutateEditOrganisation, { mutationData }) => (
          <StyledForm>
            <StyledSections>
              <StyledSection>
                <StyledSectionOneInner>
                  <StyledLogoWrapper>
                    <StyledInputWrapper>
                      <StyledLabel centered={true}>
                        Organisation Logo
                      </StyledLabel>

                      <MediaUploader
                        mediaType="image"
                        parentState={this.props.imgInputsData}
                        mediaTusID={this.props.organisation.LogoMedia.TusID}
                        src={this.props.organisation.LogoMedia.URL}
                        onSuccess={({ ID }) => {
                          this.props.setInputState('LogoMediaID', ID);
                        }}
                        onRemove={() => {
                          this.props.setInputState('LogoMediaID', '0');
                        }}
                        round
                      />
                    </StyledInputWrapper>
                  </StyledLogoWrapper>
                  <StyledLogoSideInputsWrapper>
                    <StyledInputWrapper>
                      <StyledTextInput
                        id="organisation-name"
                        name="organisation-name"
                        label=" Organisation Name"
                        onChange={(e) => {
                          e.preventDefault();
                          this.props.setInputState(
                            'Name',
                            e.target.value,
                            false,
                            true
                          );
                        }}
                        value={this.props.currentFormData.Name}
                        placeholder="Enter Name..."
                        invalid={this.props.invalidFields.includes('Name')}
                      />
                    </StyledInputWrapper>
                    <StyledInputWrapper>
                      <TextEditorArea
                        id="organisation-description"
                        name="organisation-description"
                        label="Organisation Description"
                        onChange={(newValue) => {
                          this.props.setInputState(
                            'Description',
                            newValue,
                            false,
                            true
                          );
                        }}
                        value={this.props.currentFormData.Description}
                        placeholder="Enter Description..."
                        invalid={this.props.invalidFields.includes(
                          'Description'
                        )}
                      />
                    </StyledInputWrapper>
                  </StyledLogoSideInputsWrapper>
                </StyledSectionOneInner>
              </StyledSection>
            </StyledSections>
            <SaveBar>
              <Button
                dataTestId="save-bar-btn"
                onClick={(event) =>
                  this._submitForm(event, mutateEditOrganisation)
                }
                loading={this.props.submittingForm}
                disabled={!this.props.isDirtyForm}
              >
                Save
              </Button>
            </SaveBar>
          </StyledForm>
        )}
      </Mutation>
    );
  }
}

OrganisationEditForm.propTypes = {
  organisation: PropTypes.object,
  client: PropTypes.object.isRequired,
  setInitialData: PropTypes.func.isRequired,
  currentFormData: PropTypes.object.isRequired,
  setSubmittingForm: PropTypes.func.isRequired,
  submittingForm: PropTypes.bool.isRequired,
  setInputState: PropTypes.func.isRequired,
  invalidFields: PropTypes.array.isRequired,
  validationErrors: PropTypes.array.isRequired,
  triggerRevalidation: PropTypes.func.isRequired,
  setInputImgState: PropTypes.func.isRequired,
  imgInputsData: PropTypes.object.isRequired,
  isDirtyForm: PropTypes.bool.isRequired,
};

export default withApollo(
  WithFormHandler(
    WithFlashMessaging(withUser(OrganisationEditForm)),
    formConfig
  )
);
