import styled from 'styled-components';

export const UploaderListBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  padding: 16px;
  border: 1px solid #ccc;
  border-radius: var(--accordion-border-radius);

  margin-top: 0.5rem;
  gap: 0.5rem;
`;

export const StyledFileInput = styled.input`
  display: none;
  margin-bottom: 1rem;
`;

export const StyledInputWrapper = styled.label`
  overflow: hidden;
  text-overflow: ellipsis;

  height: 50px;
  width: 100%;
  border: 2px dashed #e1e4ea;

  border-radius: var(--accordion-border-radius);
  display: grid;
  place-items: center;

  cursor: pointer;
  margin-bottom: 1rem;
`;
