import {
  createSelector,
  createSlice,
  createAsyncThunk,
} from '@reduxjs/toolkit';
import _ from 'lodash';
import { checkDiagnosis } from '../DiagnosisSlice';
import { GetFormattedTime } from '../../../../../utility/TimeFormatting';
import {
  sendChat,
  fetchGreeting,
  fetchBranchingGreeting,
  sendBranchingOption,
  // iterateBranchingNodes,
  completeSession,
  endSession,
  addResult,
  promptDiagnosis,
  setPendingInvestigation,
  sendBranchingFreeText,
  sendBranchingAIText,
  skipGreeting,
} from './ChatThunks';
import { getPatient } from '../PatientSlice';
  
/**
 * @typedef {import('@core/models/vh-server/vh-server-coaching-session-report.types').CoachingSessionReport} CoachingSessionReport
 * @typedef {import('@core/models/vh-server/vh-server-coaching-session-report.types').CoachingSessionReportObjective} CoachingSessionReportObjective
 */

export const InvestigationStates = {
  PENDING: 1,
  COMPLETE: 2,
};

export const ChatStates = {
  LOADING: 0,
  AWAITING_FIRST_CHAT: 1,
  TALK: 2,
  DIAGNOSIS: 3,
  COMPLETE: 4,
};

export const ChatSidebarTabs = {
  VITALS: {
    ID: 1,
    text: 'Vitals',
    shouldShow: ({ SSVH }) => SSVH.Type === 'medical',
  },
  CHAT: {
    ID: 2,
    text: 'Chat',
    getNotificationNumber: (unseenCounts) => unseenCounts.chats,
    shouldShow: ({ SSVH }) => true,
  },
  ACTIONS: {
    ID: 3,
    text: 'Actions',
    shouldShow: ({ SSVH, actions }) =>
      SSVH.Type !== 'branching' && actions.length > 0,
  },
  RESULTS: {
    ID: 4,
    text: 'Results',
    getNotificationNumber: (unseenCounts) => unseenCounts.results,
    shouldShow: ({ SSVH }) => SSVH.Type !== 'branching',
  },
};

const initialState = {
  status: 'idle',
  chats: [],
  chatSessionId: '',
  audioToPlay: null,
  chatState: ChatStates.LOADING, // talk | diagnosis | feedback
  startTime: null,
  endTime: null,
  results: [],
  suggestedQuestionsRoot: [],
  suggestedQuestions: [],
  completedObjectives: [],
  finalScores: {},
  diagnosisGuess: null,
  assessmentId: null,
  selectableBranchingOptions: [],
  sidebarTab: ChatSidebarTabs.CHAT.ID,
  pendingAction: null,
  visibleAction: null,
  pendingInvestigations: {},
  highlightedResult: null,
  virtualHumanTalking: false,
  scoreOverviewVisible: false,
  /** @type {CoachingSessionReport & { objectiveReports: CoachingSessionReportObjective[]; } | null} */
  sessionReport: null,
  completingSessionStatus: 'idle',
  remainingNodeChain: [],
  prevNode: null,
  unmatchedResponseCount: 0,
  unseenCounts: {
    chats: 0,
    results: 0,
  },
  triggeredVHStates: [],
  untriggeredVHStates: [],
  currentVHState: null,
  isPreviewPlayer: false,
  waitingOnUserContinue: false,
  scrollToEndChatLog: false,
  markedToCompleteWhenFinishedTalking: false,
  markedToShowEndConversationPromptWhenFinishedTalking: false,
  isIteratingBranchingNodes: false,
  resendFormData: null,
  resendDiagnosisFormData: null,
  resendGreeting: false,
  resendFreeTextInputData: null,
  resendBranchingAITextData: null,
  error: null,
  postObjectivesCompletePromptActive: false,
  conversationCompletePromptActive: false,
  userEndPromptActive: false,
  sendingChatText: null, // currently only implemented for branching option (added due to ActiveChat needing access)
};

function promisifiedTimeout(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

// needs to be here rather than ChatThunks.js to avoid cyclical dependency error
// used for when there's no audio buffer
export const waitThenIterateBranchingNodes = createAsyncThunk(
  'chat/waitThenIterateBranchingNodes',
  async (payload, { getState, dispatch }) => {
    dispatch(isIteratingBranchingNodes());
    await promisifiedTimeout(
      1000 + getState().chat.remainingNodeChain[0].text.length * 20
    );
    dispatch(iterateBranchingNodes());
  }
);
export const waitGivenAmountThenIterateBranchingNodes = createAsyncThunk(
  'chat/waitThenIterateBranchingNodes',
  async (payload, { getState, dispatch }) => {
    dispatch(isIteratingBranchingNodes());
    await promisifiedTimeout(payload);
    dispatch(iterateBranchingNodes());
  }
);

const ChatSlice = createSlice({
  name: 'chat',
  initialState: { ...initialState },
  reducers: {
    setPendingAction: (state, action) => {
      state.pendingAction = action.payload;
    },
    setVirtualHumanTalking: (state, action) => {
      state.virtualHumanTalking = action.payload;
      state.audioToPlay = null;
    },
    setVisibleAction: (state, action) => {
      state.visibleAction = action.payload;
    },
    setIsPreviewPlayer: (state, action) => {
      state.isPreviewPlayer = action.payload;
    },
    addUserChat: (state, action) => {
      state.chats.push({
        text: action.payload,
        chatType: 'user',
        conversationItemId: null,
        hitObjective: false,
      });
    },
    resetChat: (state, action) => {
      // state = { ...initialState }; doesn't work
      Object.keys(initialState).forEach((key) => {
        state[key] = initialState[key];
      });
    },
    setChatSidebarTab: (state, action) => {
      state.sidebarTab = action.payload;
      if (action.payload === ChatSidebarTabs.CHAT.ID) {
        state.unseenCounts.chats = 0;
      } else if (action.payload === ChatSidebarTabs.RESULTS.ID) {
        state.unseenCounts.results = 0;
      }
    },
    stopExamination: (state, action) => {
      state.pendingAction = null;
    },
    setResultHighlighted: (state, action) => {
      state.highlightedResult = action.payload;
    },
    setScoreOverviewVisible: (state, action) => {
      state.scoreOverviewVisible = action.payload;
    },
    setUnitID: (state, action) => {
      state.unitID = action.payload;
    },
    setSessionReport: (state, action) => {
      state.sessionReport = action.payload;
    },
    isIteratingBranchingNodes: (state, action) => {
      state.isIteratingBranchingNodes = true;
    },
    iterateBranchingNodes: (state, action) => {
      state.waitingOnUserContinue = false;
      if (state.sidebarTab !== ChatSidebarTabs.CHAT.ID) {
        state.unseenCounts.chats = state.unseenCounts.chats + 1;
      }
      const currentConvNode = state.remainingNodeChain[0];
      if (
        ['end', 'choice', 'freeText', 'ai'].includes(currentConvNode.action)
      ) {
        state.status = 'idle';
      }
      if (currentConvNode?.audioBuffer || currentConvNode?.audioUrl) {
        // create the structure that Unity expects
        state.audioToPlay = JSON.stringify({
          response: {
            audio: {
              base64Buffer: currentConvNode?.audioBuffer,
              url: currentConvNode?.audioUrl,
            },
            // hacky way to match branching structure to freeform structure
            conversationItem: {
              emotion: currentConvNode?.emotion,
            },
          },
        });
      }
      if (
        currentConvNode.action !== 'freeText' &&
        currentConvNode.action !== 'ai'
      ) {
        state.chats.push({
          text: currentConvNode.text,
          chatType: 'bot',
          hitObjective: false,
        });
      }
      if (currentConvNode && currentConvNode.action === 'choice') {
        state.selectableBranchingOptions =
          currentConvNode.branchingOptions ?? [];
      } else {
        state.selectableBranchingOptions = [];
      }
      state.prevNode = state.remainingNodeChain[0];
      state.remainingNodeChain = state.remainingNodeChain.slice(1);
      if (state.remainingNodeChain.length === 0) {
        state.isIteratingBranchingNodes = false;
      }
    },
    triggerVHState: (state, action) => {
      state.untriggeredVHStates = state.untriggeredVHStates.filter(
        (vhState) => vhState.id !== action.payload.id
      );
      state.triggeredVHStates.push(action.payload);
      state.currentVHState = action.payload;
      state.chats.push({
        text: "Your patient's state has changed",
        chatType: 'info',
        conversationItemId: null,
        hitObjective: false,
      });
    },
    sendUnresponsiveMessage: (state, action) => {
      state.chats.push({
        text: 'The patient is unresponsive',
        chatType: 'info',
        conversationItemId: null,
        hitObjective: false,
      });
    },
    setScrollToEndChatLog: (state, action) => {
      state.scrollToEndChatLog = action.payload;
    },
    markToCompleteWhenFinishedTalking: (state) => {
      state.markedToCompleteWhenFinishedTalking = true;
    },
    markToShowEndConversationPromptWhenFinishedTalking: (state) => {
      state.markedToShowEndConversationPromptWhenFinishedTalking = true;
    },
    setPostObjectivesCompletePromptActive: (state, action) => {
      state.postObjectivesCompletePromptActive = action.payload;
    },
    setConversationCompletePromptActive: (state, action) => {
      if (!action.payload) {
        state.markedToShowEndConversationPromptWhenFinishedTalking = false;
      }
      state.conversationCompletePromptActive = action.payload;
    },
    setUserEndPromptActive: (state, action) => {
      state.userEndPromptActive = action.payload;
    },
  },
  extraReducers: {
    [promptDiagnosis.fulfilled]: (state, action) => {
      if (state.chatState === ChatStates.TALK) {
        state.chatState = ChatStates.DIAGNOSIS;
        state.chats.push({
          text: action.payload,
          chatType: 'diagnosis',
          conversationItemId: null,
        });
      }
    },
    [skipGreeting.fulfilled]: (state, action) => {
      state.status = 'idle';
      state.error = null;
      state.chatState = ChatStates.AWAITING_FIRST_CHAT;
    },
    [fetchGreeting.pending]: (state, action) => {
      state.status = 'pending';
    },
    [fetchGreeting.fulfilled]: (state, action) => {
      state.status = 'idle';
      if (action.payload?.error || !action.payload.response.chatSessionId) {
        state.error = 'Error fetching initial response.';
        state.resendGreeting = true;
        return;
      }

      state.error = null;
      state.resendGreeting = false;
      state.chatSessionId = action.payload.response.chatSessionId;
      state.startTime = new Date().getTime();
      state.chatState = ChatStates.TALK;
      if (action.payload.response?.audio) {
        state.audioToPlay = JSON.stringify(action.payload);
      }
      if (
        action.payload.response.suggestedQuestions &&
        action.payload.response.suggestedQuestions.length > 0
      ) {
        state.suggestedQuestionsRoot =
          action.payload.response.suggestedQuestions;
        state.suggestedQuestions = action.payload.response.suggestedQuestions;
      }
      state.chats.push({
        text: action.payload.response.text,
        chatType: 'bot',
        conversationItemId: action.payload.response.conversationItemId,
        hitObjective: false,
        feedbackText: action.payload.response?.conversationItem?.feedbackText,
      });
      if (state.sidebarTab !== ChatSidebarTabs.CHAT.ID) {
        state.unseenCounts.chats = state.unseenCounts.chats + 1;
      }
      // ensure assessmentId is set at the very start
      // IF DELETING THIS THEN MAKE SURE IT HAPPENS ELSEWHERE
      if (action.payload?.assessmentId) {
        state.assessmentId = action.payload?.assessmentId;
      }
    },
    [fetchBranchingGreeting.pending]: (state, action) => {
      state.status = 'pending';
    },
    [fetchBranchingGreeting.rejected]: (state, action) => {
      state.resendGreeting = true;
      state.error = 'Error sending greeting.';
      state.status = 'idle';
    },
    [fetchBranchingGreeting.fulfilled]: (state, action) => {
      state.status = 'idle';
      state.error = null;
      state.resendGreeting = false;
      state.chatSessionId = action.payload.data.chatSessionId;
      state.startTime = new Date().getTime();
      state.chatState = ChatStates.TALK;
      // ensure assessmentId is set at the very start
      // IF DELETING THIS THEN MAKE SURE IT HAPPENS ELSEWHERE
      if (action.payload?.data.assessmentId) {
        state.assessmentId = action.payload?.data.assessmentId;
      }
      state.remainingNodeChain =
        action.payload.data?.branchingConversationNodes ?? [];
    },
    [sendBranchingOption.pending]: (state, action) => {
      state.selectableBranchingOptions = [];
      state.sendingChatText = action.meta.arg.branchingOption.text;
      state.status = 'pending';
    },
    [sendBranchingOption.rejected]: (state, action) => {
      state.status = 'idle';
      state.sendingChatText = null;
      state.error = 'Error fulfilling option request.';
      state.resendFormData = action.payload?.resendFormData;
    },
    [sendBranchingOption.fulfilled]: (state, action) => {
      state.status = 'idle';
      state.sendingChatText = null;
      state.error = null;
      state.resendFormData = null;
      state.chats.push({
        text:
          action.payload.data?.data?.selectedBranchingOptionWithCalculated
            ?.text ?? action.payload.branchingOptionText,
        chatType: 'user',
        hitObjective:
          !!action.payload?.data?.data?.selectedBranchingOptionWithCalculated
            ?.scoreMetadataWithCalculated,
        scoreMetadataWithCalculated:
          action.payload?.data?.data?.selectedBranchingOptionWithCalculated
            ?.scoreMetadataWithCalculated,
        feedbackText:
          action.payload.data?.data?.selectedBranchingOptionWithCalculated
            ?.feedbackText,
      });
      // if (
      //   action.payload.data?.data?.selectedBranchingOptionWithCalculated
      //     ?.feedbackText
      // ) {
      //   state.chats.push({
      //     text: action.payload.data?.data?.selectedBranchingOptionWithCalculated
      //       ?.feedbackText,
      //     chatType: 'feedbackText',
      //     conversationItemId: null,
      //     hitObjective: false,
      //   });
      // }
      if (
        action.payload.data?.data?.selectedBranchingOptionWithCalculated
          ?.feedbackText
      ) {
        state.waitingOnUserContinue = true;
      }
      state.remainingNodeChain =
        action.payload.data?.data?.branchingConversationNodes ?? [];
    },
    [sendBranchingAIText.pending]: (state, action) => {
      state.selectableBranchingOptions = [];
      state.status = 'pending';
      state.aiResponse = undefined;
    },
    [sendBranchingAIText.rejected]: (state, action) => {
      state.resendBranchingAITextData =
        action.payload.resendBranchingAITextData;
      state.error = 'Error sending text.';
      state.status = 'idle';
    },
    [sendBranchingAIText.fulfilled]: (state, action) => {
      state.error = null;
      state.status = 'idle';
      state.resendBranchingAITextData = null;
      if (action.payload.data?.data?.branchingConversationNodes) {
        state.remainingNodeChain =
          action.payload.data?.data?.branchingConversationNodes ?? [];
      } else {
        state.aiResponse =
          action.payload.data?.data?.aiResponse.text ?? undefined;
        state.audioToPlay = JSON.stringify({
          response: {
            audio: {
              url: action.payload.data?.data?.aiResponse.url,
            },
          },
        });
        state.chats.push({
          text: action.payload.data?.data?.aiResponse.text,
          chatType: 'bot',
          conversationItemId: null,
          hitObjective: false,
        });
      }
    },
    [sendBranchingFreeText.pending]: (state, action) => {
      state.selectableBranchingOptions = [];
      state.status = 'pending';
    },
    [sendBranchingFreeText.rejected]: (state, action) => {
      state.resendFreeTextInputData = action.payload.resendFreeTextInputData;
      state.error = 'Error sending text.';
      state.status = 'idle';
    },
    [sendBranchingFreeText.fulfilled]: (state, action) => {
      state.error = null;
      state.status = 'idle';
      state.resendFreeTextInputData = null;
      state.remainingNodeChain =
        action.payload.data?.data?.branchingConversationNodes ?? [];
    },
    [sendChat.pending]: (state, action) => {
      state.status = 'pending';
      state.selectableOptions = [];
    },
    [sendChat.fulfilled]: (state, action) => {
      state.status = 'idle';
      if (action.payload?.error) {
        if (typeof action.payload?.error === 'string') {
          state.error = action.payload.error;
        } else if (
          action.payload?.error?.response?.data?.code === 'content_filter'
        ) {
          state.error =
            'Response could not be processed due to content policy settings. Please rephrase.';
        } else {
          state.error = 'Error fulfilling chat request.';
        }
        state.resendFormData = action.payload?.resendFormData;
        return;
      }
      state.resendFormData = null;
      state.error = null;

      if (action.payload.data?.response?.audio) {
        state.audioToPlay = JSON.stringify(action.payload.data);
      }
      if (
        action.payload.data?.response?.suggestedQuestions &&
        action.payload.data?.response?.suggestedQuestions?.length > 0
      ) {
        state.suggestedQuestions =
          action.payload.data.response.suggestedQuestions;
      } else {
        state.suggestedQuestions = [];
      }
      const responseItemId = _.get(
        action,
        'payload.response.conversationItem.id'
      );
      state.chats.push({
        text: action.payload.data.response.text,
        chatType: 'bot',
        conversationItemId: responseItemId,
        isAIFallback: action.payload?.data?.isAIFallback,
        hitObjective: !!action.payload?.objectiveId,
        scoreMetadataWithCalculated:
          action.payload?.scoreMetadataWithCalculated,
        feedbackText:
          action.payload?.data?.response?.conversationItem?.feedbackText,
        endsConversation:
          action.payload?.data?.response?.conversationItem?.endsConversation,
      });
      if (state.sidebarTab !== ChatSidebarTabs.CHAT.ID) {
        state.unseenCounts.chats = state.unseenCounts.chats + 1;
      }

      if (action.payload?.objectiveId) {
        state.completedObjectives.push(action.payload.objectiveId);
      }

      if (action.payload?.data?.isFallback) {
        state.unmatchedResponseCount = state.unmatchedResponseCount + 1;
      }

      // if started off with skipGreeting we need to set the time and signal we're no longer waiting for user input
      if (state.chatState === ChatStates.AWAITING_FIRST_CHAT) {
        state.startTime = new Date().getTime();
        state.chatState = ChatStates.TALK;
      }
      // ensure assessmentId is set if this is a learner led conversation
      if (action.payload.data?.assessmentId) {
        state.assessmentId = action.payload.data.assessmentId;
      }
      if (action.payload.data?.response.chatSessionId) {
        state.chatSessionId = action.payload.data.response.chatSessionId;
      }
    },
    [completeSession.rejected]: (state, action) => {
      state.completingSessionStatus = 'idle';
    },
    [completeSession.pending]: (state, action) => {
      state.completingSessionStatus = 'pending';
    },
    [completeSession.fulfilled]: (state, action) => {
      state.completingSessionStatus = 'idle';
      state.finalScores.conversationScore = action.payload.conversationScore;
      state.finalScores.timeSpent = action.payload.timeSpent;
      state.finalScores.totalPointsPossible =
        action.payload.totalPointsPossible;
      state.finalScores.objectives = action.payload.objectives;
      state.finalScores.hitObjectivesData = action.payload.hitObjectivesData;
      state.finalScores.actionObjectivesGroupedByHeadings =
        action.payload.actionObjectivesGroupedByHeadings;
      state.chatState = ChatStates.COMPLETE;
      state.chats.push({
        chatType: 'reflection-question',
        text: `Completed in ${GetFormattedTime(state.finalScores.timeSpent)}`,
        conversationItemId: null,
        hitObjective: false,
      });
    },
    [checkDiagnosis.pending]: (state, action) => {
      state.completingSessionStatus = 'pending';
      state.error = null;
    },
    [checkDiagnosis.fulfilled]: (state, action) => {
      state.resendDiagnosisFormData = null;
      state.completingSessionStatus = 'idle';
      state.chats.push({
        text: action.payload.message,
        chatType: 'diagnosis',
        conversationItemId: null,
        hitObjective: false,
        scoreMetadataWithCalculated:
          action.payload?.scoreMetadataWithCalculated,
      });
      state.diagnosisGuess = action.payload.diagnosisGuess;
      state.finalScores.diagnosisScore = action.payload.diagnosisScore;
      state.finalScores.conversationScore = action.payload.conversationScore;
      state.finalScores.timeSpent = action.payload.timeSpent;
      state.finalScores.totalPointsPossible =
        action.payload.totalPointsPossible;
      state.finalScores.objectives = action.payload.objectives;
      state.finalScores.hitObjectivesData = action.payload.hitObjectivesData;
      state.finalScores.actionObjectivesGroupedByHeadings =
        action.payload.actionObjectivesGroupedByHeadings;
      state.chatState = ChatStates.COMPLETE;
      state.chats.push({
        chatType: 'reflection-question',
        text: `Completed in ${GetFormattedTime(state.finalScores.timeSpent)}`,
        conversationItemId: null,
        hitObjective: false,
      });
    },
    [checkDiagnosis.rejected]: (state, action) => {
      state.completingSessionStatus = 'idle';
      state.error = 'Error fulfilling option request.';
      state.resendDiagnosisFormData = action.payload?.resendDiagnosisFormData;
    },
    [endSession.fulfilled]: (state, action) => {
      state.endTime = action.payload.response.endTimeRaw;
    },
    [addResult.fulfilled]: (state, action) => {
      state.pendingAction = null;
      state.results.push({
        ...action.payload.result,
        scoreMetadataWithCalculated:
          action.payload.scoreMetadataWithCalculated ?? null,
        // for key mapping and differentiating if there are multiple exams/investigations for the same type
        idLocal: state.results.length + 1,
      });
      if (state.sidebarTab !== ChatSidebarTabs.RESULTS.ID) {
        state.unseenCounts.results = state.unseenCounts.results + 1;
      }
    },
    // when pending investigation thunk is called, set the id of the object to true to indicate pending
    [setPendingInvestigation.pending]: (state, action) => {
      state.pendingInvestigations[action.meta.arg.id] =
        InvestigationStates.PENDING;
    },
    [setPendingInvestigation.fulfilled]: (state, action) => {
      state.pendingInvestigations[action.meta.arg.id] =
        InvestigationStates.COMPLETE;
    },
    [getPatient.fulfilled]: (state, action) => {
      state.triggeredVHStates = action.payload?.states?.[0]
        ? [action.payload?.states[0]]
        : [];
      state.currentVHState = action.payload?.states?.[0] ?? null;
      state.untriggeredVHStates = action.payload?.states?.slice?.(1) ?? null;
    },
  },
});

// Destructure and export the plain action creators
export const {
  addUserChat,
  resetChat,
  setChatSidebarTab,
  setPendingAction,
  setVisibleAction,
  stopExamination,
  setResultHighlighted,
  setVirtualHumanTalking,
  setScoreOverviewVisible,
  setSessionReport,
  isIteratingBranchingNodes,
  iterateBranchingNodes,
  setUnseenChatCount,
  setUnseenResultsCount,
  triggerVHState,
  sendUnresponsiveMessage,
  setIsPreviewPlayer,
  setScrollToEndChatLog,
  markToCompleteWhenFinishedTalking,
  setPostObjectivesCompletePromptActive,
  setConversationCompletePromptActive,
  setUserEndPromptActive,
  markToShowEndConversationPromptWhenFinishedTalking,
} = ChatSlice.actions;

export const selectAllChats = (state) => state.chat.chats;
export const selectLatestTextChat = createSelector(selectAllChats, (chats) =>
  [...chats].reverse().find((item) => ['user', 'bot'].includes(item?.chatType))
);
export const selectLatestBotTextChat = createSelector(selectAllChats, (chats) =>
  [...chats].reverse().find((item) => ['bot'].includes(item?.chatType))
);
export const selectLatestUserTextChat = createSelector(
  selectAllChats,
  (chats) =>
    [...chats].reverse().find((item) => ['user'].includes(item?.chatType))
);
export const selectChatsCount = (state) => state.chat.chats.length;
export const selectAllResults = (state) => state.chat.results;
export const selectChatState = (state) => state.chat.chatState;
export const selectChatStatus = (state) => state.chat.status;
export const selectScrollToEndChatLog = (state) =>
  state.chat.scrollToEndChatLog;
export const selectChatSidebarTab = (state) => {
  const tabKey = Object.keys(ChatSidebarTabs).find(
    (t) => ChatSidebarTabs[t].ID === state.chat.sidebarTab
  );
  return ChatSidebarTabs[tabKey];
};
export const selectAudioToPlay = (state) => state.chat.audioToPlay;
export const selectTimeSpent = (state) =>
  state.chat.finalScores?.timeSpent ?? 0;
export const selectSuggestedQuestions = (state) =>
  state.chat.suggestedQuestions;
export const selectSuggestedQuestionsRoot = (state) =>
  state.chat.suggestedQuestionsRoot;
export const selectDiagnosisSuccess = (state) =>
  state.chat.finalScores?.diagnosisScore === 100;
export const conversationScoreSelector = (state) =>
  state.chat.finalScores?.conversationScore;

export const selectTotalObjectivePointsAchieved = (state) => {
  return (
    state.chat.finalScores?.hitObjectivesData?.objectives?.reduce(
      (carry, hitObjective) => {
        return carry + hitObjective.calculatedPoints;
      },
      0
    ) ?? 0
  );
};
export const selectTotalPointsPossible = (state) =>
  state.chat.finalScores?.totalPointsPossible ?? 0;
export const remainingNodeChainSelector = (state) => {
  return state?.chat.remainingNodeChain;
};
export const virtualHumanTalkingSelector = (state) => {
  return state?.chat.virtualHumanTalking;
};
export const scoreOverviewVisibleSelector = (state) => {
  return state?.chat.scoreOverviewVisible;
};
/** @type {(state: any) => CoachingSessionReport & { objectiveReports: CoachingSessionReportObjective[]; } | null} */
export const sessionReportSelector = (state) => {
  return state?.chat.sessionReport;
};
export const isPreviewPlayerSelector = (state) => {
  return state?.chat?.isPreviewPlayer ?? false;
};
export const assessmentIdSelector = (state) => {
  return state?.chat?.assessmentId;
};
export const completingSessionStatusSelector = (state) =>
  state.chat.completingSessionStatus;
export const prevNodeSelector = (state) => state?.chat?.prevNode;

export const untriggeredVHStatesSelector = (state) =>
  state.chat.untriggeredVHStates;
export const currentVHStateSelector = (state) => state.chat.currentVHState;
export const isResponsiveSelector = createSelector(
  currentVHStateSelector,
  (currentState) => !currentState?.unresponsiveVH
);
export const waitingOnUserContinueSelector = (state) =>
  state.chat.waitingOnUserContinue;
export const unmatchedResponseCountSelector = (state) =>
  state.chat.unmatchedResponseCount;
export const markedToCompleteWhenFinishedTalkingSelector = (state) =>
  state.chat.markedToCompleteWhenFinishedTalking;
export const markedToShowEndConversationPromptWhenFinishedTalkingSelector = (
  state
) => state.chat.markedToShowEndConversationPromptWhenFinishedTalking;

export const isIteratingBranchingNodesSelector = (state) =>
  state.chat.isIteratingBranchingNodes;
export const resendGreetingSelector = (state) => state.chat.resendGreeting;
export const resendFormDataSelector = (state) => state.chat.resendFormData;
export const resendDiagnosisFormDataSelector = (state) =>
  state.chat.resendDiagnosisFormData;
export const chatErrorSelector = (state) => state.chat.error;
export const postObjectivesCompletePromptActiveSelector = (state) =>
  state.chat.postObjectivesCompletePromptActive;
export const conversationCompletePromptActiveSelector = (state) =>
  state.chat.conversationCompletePromptActive;
export const userEndPromptActiveSelector = (state) =>
  state.chat.userEndPromptActive;
export const resendFreeTextInputDataSelector = (state) =>
  state.chat.resendFreeTextInputData;
export const resendBranchingAITextDataSelector = (state) =>
  state.chat.resendBranchingAITextData;
export const diagnosisGuessSelector = (state) => state.chat.diagnosisGuess;
export const sendingChatTextSelector = (state) => state.chat.sendingChatText;
export const showEndSessionModalSelector = (state) =>
  state.chat.userEndPromptActive ||
  state.chat.postObjectivesCompletePromptActive ||
  state.chat.conversationCompletePromptActive;

export default ChatSlice;
export * from './ChatThunks';
