import { useMutation } from '@apollo/client';
import { Icon } from '@virtidev/toolbox';
import { produce } from 'immer';
import React, { useCallback } from 'react';
import { READ_FEEDBACK_FORM } from '../../../../../../../queries/FeedbackFormQueries';
import { CircularButton } from '../QuestionActions.styled';
import { COPY_QUESTION } from './DuplicateQuestion.query';

export default function DuplicateQuestion({
  question,
  setEditing,
  formID,
  activePage,
  setLoading,
}) {
  const [copyQuestion] = useMutation(COPY_QUESTION, {
    variables: {
      QuestionID: question.ID,
    },
    onCompleted: () => setLoading(false),
    update: (cache, { data }) => {
      const cacheData = cache.readQuery({
        query: READ_FEEDBACK_FORM,
        variables: {
          ID: formID,
        },
      });

      const newItem = data.copyQuestion;
      const newData = produce(cacheData, (state) => {
        if (state?.readOneFeedbackForm) {
          return;
        }
        if (
          state.readOneFeedbackForm.Pages.nodes[activePage.SortOrder].Questions
            .nodes
        ) {
          state.readOneFeedbackForm.Pages.nodes[
            activePage.SortOrder
          ].Questions.nodes = [
            ...state.readOneFeedbackForm.Pages.nodes[activePage.SortOrder]
              .Questions.nodes,
            newItem,
          ];
        } else {
          state.readOneFeedbackForm.Pages.nodes[
            activePage.SortOrder
          ].Questions = { nodes: [newItem] };
        }
      });

      cache.writeQuery({
        query: READ_FEEDBACK_FORM,
        variables: {
          ID: formID,
        },
        data: newData,
      });
    },
  });

  const handleClick = useCallback(
    (e) => {
      e.preventDefault();
      e.stopPropagation();
      copyQuestion();
      setLoading(true);
      setTimeout(() => {
        setEditing(false);
      }, 1);
    },
    [setEditing, copyQuestion, setLoading]
  );

  return (
    <CircularButton onClick={handleClick} title="Duplicate Question">
      <Icon icon="duplicate" />
    </CircularButton>
  );
}
