import React, { FC, useCallback } from 'react';
import { EllipsisMenuButton } from '../EllipsisMenu';
import Icon from '../icons/Icon';

/**
 * @typedef {import('../../apps/core/src/models/video-media.types').VideoMedia} VideoMedia
 */

/**
 * @type {FC<{
 *    video: VideoMedia,
 *    onEdit: (videoId: string) => void,
 * }>}
 */
const EditVideoTitleButtonInMenu = ({ video, onEdit }) => {
  const editVideo = useCallback(() => onEdit(video.ID), [onEdit, video]);

  return (
    <EllipsisMenuButton onClick={editVideo}>
      <Icon type="lineawesome" name="pencil-alt-solid" /> Edit Video Name
    </EllipsisMenuButton>
  );
};

EditVideoTitleButtonInMenu.propTypes = {};

export default EditVideoTitleButtonInMenu;
