import styled, { css } from 'styled-components';

export const Container = styled.div`
  ${({ theme: { font, spacing } }) => css`
    ${font.smallnote}
    display: flex;
    flex-direction: column;
    position: relative;
    align-items: flex-start;
    gap: ${spacing.sm};
  `}
`;
