import { useMutation, useQuery } from '@apollo/client';
import { Loading } from '@virtidev/toolbox';
import { produce } from 'immer';
import _ from 'lodash';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import useMounted from '../../../../helpers/useMounted';
import { READ_TAGS } from '../../../form/Select/components/SelectTag/SelectTag.query';
import { CardTooltip, IconContent, IconText } from '../../DataListCard.styled';
import CreateTag from './components/CreateTag';
import { TagList } from './components/TagList';
import {
  COURSE_TAG_FRAGMENT,
  SET_TAGS,
  SIM_TAG_FRAGMENT,
  VH_TAG_FRAGMENT,
  VIDEO_TAG_FRAGMENT,
} from './CardTooltipTags.query';
import { LoadingChange } from './CardTooltipTags.styled';
import useUser from '@core/helpers/useUser';

export const CardTooltipTags = ({
  className,
  targetId,
  cardType,
  tags,
  editTags,
  showCount,
}) => {
  const { OrganisationID } = useUser();
  const [selectedTags, setSelectedTags] = useState([]);
  const [loading, setLoading] = useState(false);
  const mounted = useMounted();

  const {
    data: tagData,
    loading: tagLoading,
    refetch,
  } = useQuery(READ_TAGS, {
    skip: !editTags,
    variables: {
      name: '',
      orgId: OrganisationID,
    },
    notifyOnNetworkStatusChange: true,
  });
  const tagsList = useMemo(
    () => tagData?.readContentTags?.nodes || [],
    [tagData]
  );
  const listRef = useRef(tagsList);
  listRef.current = tagsList;

  useEffect(() => {
    setSelectedTags(tags.map(({ ID }) => ID));
  }, [tags]);

  const [setTags, { loading: setTagLoading }] = useMutation(SET_TAGS, {
    update: (cache, { data }) => {
      const prefix =
        (cardType === 'simulation' && 'Simulation') ||
        (cardType === 'course' && 'Course') ||
        (cardType === 'virtual human' && 'VirtualHuman') ||
        (cardType === 'video' && 'VideoMedia');
      const fragment =
        (cardType === 'simulation' && SIM_TAG_FRAGMENT) ||
        (cardType === 'course' && COURSE_TAG_FRAGMENT) ||
        (cardType === 'virtual human' && VH_TAG_FRAGMENT) ||
        (cardType === 'video' && VIDEO_TAG_FRAGMENT);

      if (!prefix || !fragment) {
        return;
      }
      const part = cache.readFragment({
        id: `${prefix}:${targetId}`,
        fragment,
      });

      if (!Array.isArray(part?.Tags?.nodes)) {
        return;
      }
      const newData = produce(part, (draft) => {
        draft.Tags.nodes = data.setTags;
      });
      cache.writeFragment({
        id: `${prefix}:${targetId}`,
        fragment,
        data: newData,
      });
    },
  });
  const loadingRef = useRef(setTagLoading);
  loadingRef.current = setTagLoading || loading;

  const queueSave = useMemo(
    () =>
      _.debounce((newTags) => {
        if (loadingRef.current && mounted.current) {
          queueSave(newTags);
          return;
        }
        setTags({
          variables: {
            tagIds: newTags,
            simId: cardType === 'simulation' ? targetId : null,
            courseId: cardType === 'course' ? targetId : null,
            vhId: cardType === 'virtual human' ? targetId : null,
            videoId: cardType === 'video' ? targetId : null,
          },
          optimisticResponse: {
            setTags: listRef.current.filter(({ ID }) => newTags.includes(ID)),
          },
        });
      }, 1500),
    [setTags, targetId, cardType, mounted]
  );

  const updateTags = useCallback(
    (callback) => {
      setSelectedTags((selected) => {
        const newSelected = callback(selected);

        queueSave(newSelected);
        return newSelected;
      });
    },
    [queueSave]
  );

  // make sure pending changes are saved if user tries to leave the page
  useEffect(() => {
    return () => queueSave.flush();
  }, [queueSave]);

  const iconContent = useMemo(() => {
    if (tagLoading) {
      return <Loading />;
    }
    if (!tags.length) {
      return null;
    }
    const showing = tags.slice(0, showCount);
    return (
      <>
        <IconContent>
          {!!showCount && (
            <IconText>{showing.map(({ Name }) => Name).join(', ')}</IconText>
          )}
          {tags.length > showCount && (
            <span>
              ({!!showCount && '+'}
              {tags.length - showCount})
            </span>
          )}
        </IconContent>
      </>
    );
  }, [tagLoading, tags, showCount]);

  if (!editTags && !tags?.length) {
    return null;
  }
  return (
    <CardTooltip
      icon={tagLoading ? false : 'tag'}
      iconContent={iconContent}
      autoClose={false}
      className={className}
      contentProps={{
        style: {
          display: 'flex',
          flexDirection: 'column',
          paddingRight: 0,
          paddingLeft: 0,
        },
      }}
    >
      <LoadingChange $show={setTagLoading || tagLoading}>
        <Loading />
      </LoadingChange>
      <TagList
        selectedTags={selectedTags}
        tagLoading={tagLoading}
        updateTags={editTags && updateTags}
        tagsList={editTags ? tagsList : tags}
      />
      {editTags && (
        <CreateTag
          refetch={refetch}
          updateTags={updateTags}
          setLoading={setLoading}
        />
      )}
    </CardTooltip>
  );
};

CardTooltipTags.defaultProps = {
  showCount: 1,
  tags: [],
};

export default CardTooltipTags;
