import { useSelector } from 'react-redux';
import { virtualHumanTypeSelector } from '../../slices/VirtualHumanSlice';
import VHMedicalConfig from './configs/VHMedicalConfig';
import VHDefaultConfig from './configs/VHDefaultConfig';
import VHFreeformV2Config from './configs/VHFreeformV2Config';
import VHBranchingConfig from './configs/VHBranchingConfig';

const vhTypeConfig = {
  medical: VHMedicalConfig,
  branching: VHBranchingConfig,
  default: VHDefaultConfig,
  freeform_v3: VHFreeformV2Config,
};

export const useVHTypeConfig = () => {
  const vhType = useSelector(virtualHumanTypeSelector);
  return vhTypeConfig[vhType];
};
export const useShouldShowHints = () => {
  const vhTypeConfig = useVHTypeConfig();
  return vhTypeConfig.showHints;
};
// injectedVhType for when no redux store e.g. in outer app
export const useInjectedVHTypeConfig = (vhType) => {
  return vhTypeConfig[vhType];
};
