import { useMutation } from '@apollo/client';
import { produce } from 'immer';
import { useCallback, useEffect, useState } from 'react';
import { READ_FEEDBACK_FORM } from '../../../../../../queries/FeedbackFormQueries';
import useFlashMessage from '../../FlashMessage';
import QuestionV2 from '../Question/QuestionV2';
import { SORT_QUESTIONS } from './Container.query';

export const Container = ({ questions, updatePageValue, formID, ...props }) => {
  const [cards, setCards] = useState(questions);
  const [reordered, setReordered] = useState(false);

  const { addFlashMessage } = useFlashMessage();

  const moveCard = useCallback((dragIndex, hoverIndex) => {
    setCards((prevCards) =>
      produce(prevCards, (state) => {
        state.splice(dragIndex, 1);
        state.splice(hoverIndex, 0, prevCards[dragIndex]);
      })
    );
    setReordered(true);
  }, []);

  const [sortQuestions] = useMutation(SORT_QUESTIONS);

  const handleOnDrop = useCallback(() => {
    props.setLoading(true);
    if (reordered) {
      const sorted = produce(cards, (state) => {
        return state.forEach((question, index) => {
          question.SortOrder = index + 1;
          return question;
        });
      });

      const sortedIDs = sorted.map((question) => {
        return question.ID;
      });

      sortQuestions({
        variables: {
          FeedbackPageID: props.activePage.ID,
          QuestionIDs: sortedIDs,
        },
        onCompleted: () => {
          props.setLoading(false);
        },
        onError: () => {
          props.setLoading(false);
          setCards(questions);
          addFlashMessage(
            'Something went wrong - could not be reordered.',
            'error'
          );
        },
        update: (cache, { data }) => {
          const cacheData = cache.readQuery({
            query: READ_FEEDBACK_FORM,
            variables: {
              ID: formID,
            },
          });
          const newData = produce(cacheData, (state) => {
            if (state?.readOneFeedbackForm) {
              state.readOneFeedbackForm.Pages.nodes[
                props?.activePage.SortOrder
              ].Questions.nodes = [
                ...data.sortOrderFeedbackQuestions.Questions.nodes,
              ];
            }
          });

          cache.writeQuery({
            query: READ_FEEDBACK_FORM,
            variables: {
              ID: formID,
            },
            data: newData,
          });
        },
      });

      setReordered(false);
    }
  }, [
    reordered,
    cards,
    sortQuestions,
    props,
    formID,
    questions,
    addFlashMessage,
  ]);

  useEffect(() => {
    setCards(questions);
  }, [questions]);

  return (
    <>
      {cards.map((question, index) => {
        const formInput = question;
        const placeholder = question.ID.includes('placeholder');
        return (
          <div key={formInput.ID}>
            <QuestionV2
              type={formInput.Type}
              question={question}
              placeholder={placeholder}
              otherQuestions={questions}
              moveCard={moveCard}
              handleOnDrop={handleOnDrop}
              index={index}
              formID={formID}
              {...props}
            />
            {/* <Question
              type={formInput.Type}
              question={question}
              placeholder={placeholder}
              otherQuestions={questions}
              moveCard={moveCard}
              index={index}
              {...props}
            /> */}
          </div>
        );
      })}
    </>
  );
};
