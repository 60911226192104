import VirtiCircularProgressbar from '@base/components/CircularProgressbar';
import {
  Actions,
  Cancel,
  ErrorMessage,
  FileCount,
  Pause,
  ProgressbarWrapper,
  ProgressWrapper,
  UploadDragBoxProgress,
} from '@core/components/MediaUploaders/components/UploadingProgress.styled';
import { useMemo } from 'react';

export const UploadingProgress = ({
  error,
  uploading,
  fileNum,
  fileCount,
  completed,
  progress,
  paused,
  onPause,
  onCancel,
}) => {
  const percent = useMemo(
    () => Math.round((progress.sent / progress.total) * 100) / 100,
    [progress]
  );

  const pauseLabel = useMemo(() => {
    if (error) {
      return 'Retry';
    }

    if (paused) {
      return 'Resume';
    }
    return 'Pause';
  }, [error, paused]);

  return (
    <ProgressWrapper>
      {uploading && fileCount > 1 && (
        <FileCount data-testid="uploader-file-count-progress">
          Uploading file {fileNum + 1} of {fileCount}
        </FileCount>
      )}
      {completed && (
        <FileCount data-testid="uploader-file-count-complete">
          {fileCount} file
          {fileCount > 1 ? 's' : ''} uploaded – click or drag to upload more.
        </FileCount>
      )}
      {(uploading || completed) && (
        <ProgressbarWrapper>
          <VirtiCircularProgressbar
            percentage={percent * 100}
            uploadBar={true}
          />
        </ProgressbarWrapper>
      )}
      {uploading && (
        <UploadDragBoxProgress
          progress={percent}
          paused={paused}
          totalBytes={progress.total}
        />
      )}
      {error && <ErrorMessage>{error}</ErrorMessage>}
      <Actions>
        {!completed && (
          <Pause data-testid="uploader-pause-button" onClick={onPause}>
            {pauseLabel}
          </Pause>
        )}
        <Cancel data-testid="uploader-cancel-button" onClick={onCancel}>
          {completed ? 'Close' : 'Cancel'}
        </Cancel>
      </Actions>
    </ProgressWrapper>
  );
};

export default UploadingProgress;
