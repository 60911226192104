import { TextInput, ToggleSwitch, Tooltip, Card } from '@virtidev/toolbox';
import styled, { css } from 'styled-components';
import StyledLink from '../../../../../styled-components/StyledLink';
import newTheme from '../../../../../themes/new-theme';
import OrigControlledToggleSwitch from '../form/ToggleSwitch/ControlledToggleSwitch';

export const VirtualHumanForm = styled.form`
  height: 100%;
  padding-bottom: 6.25rem;
  ${newTheme}
`;

export const EditFormWrapper = styled.div`
  display: grid;
  gap: 1.5rem;
`;

export const DescriptionArea = styled.div`
  display: flex;
  height: 100%;
`;

export const SimMetaInfoWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  font-size: 0.8rem;
  color: #757575;
  gap: 0.5rem;
`;

export const SimMetaInfo = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const InputsWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1.5rem;
`;

export const DescriptionAreaContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  height: 100%;
`;

export const InputsWrapperTitle = styled.p`
  font-size: 1.5rem;
  font-weight: 500;
`;

export const InputLabel = styled.label`
  font-size: 1.1rem;
  font-weight: 500;
`;

export const InputLabelMarginned = styled(InputLabel)`
  margin-bottom: 0.8rem;
`;

export const InputsColumnWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const CoverImageWrapper = styled.div`
  max-width: 400px;
`;

export const InputFieldWithLabel = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
`;

export const InputLabelTooltipWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  gap: 1rem;
`;

export const HelpTooltip = styled(Tooltip).attrs({
  icon: 'help',
  iconSize: '1.5rem',
})``;

export const ToggleTooltip = styled(ToggleSwitch)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin: 0px;
  div {
    margin: 0px;
  }
`;

export const ToggleWrapper = styled.div`
  display: flex;
  gap: 0.5rem;
`;

export const DiagnosisLink = styled(StyledLink)`
  text-align: right;
`;

export const AccessCheckboxes = styled.div``;

export const SaveBarContent = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding-left: 1rem;
`;
export const PublishedStatus = styled.div`
  display: flex;
  gap: 1rem;
  align-items: center;
`;

export const CardTitle = styled.h2`
  font-size: 1.4rem;
  display: block;
  margin-bottom: 0.8rem;
`;

const switchStyle = css`
  flex: 1;
  div {
    white-space: initial;
  }
`;

export const ControlledToggleSwitch = styled(OrigControlledToggleSwitch)`
  ${switchStyle}
`;

export const RestyledVHEmbeddableSwitch = styled(ToggleSwitch)`
  ${switchStyle}
`;

export const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const AutosaveAndProvenance = styled.div`
  gap: 2rem;
  display: flex;
`;
export const ProvenanceWrapper = styled.div`
  display: flex;
  justify-content: center;
  gap: 0.2rem;
  font-size: 0.9rem;
  color: #818181;
  flex-direction: column;
`;

export const DescriptionInput = styled(TextInput)`
  height: 15rem;
  resize: vertical;
`;

export const CardSection = styled(Card)`
  padding: 0.7rem 2rem 2rem 2rem;
`;

export const CardHeader = styled.h2`
  ${({ theme }) => theme.font.h2};
`;

export const CardHeaderMarginned = styled.h2`
  ${({ theme }) => theme.font.h2};
  margin-bottom: 1.2rem;
`;